<header class="bg-white shadow-md md:bg-transparent md:shadow-none">

	<nav [ngClass]="isNotHomePage()? '' : 'bg-custom'" class=" border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
		<div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-2xl">
			<a href="/" class="flex items-center gap-10">
        <img src="../../../assets/img/savvycom-logo-1.png" class="mr-3 h-6 sm:h-9" alt="savvy Logo"/>
        <img src="/assets/img/logo.svg" class="mr-3 h-6 sm:h-9" alt="Jober Logo" />
				<!-- <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Flowbite</span> -->
			</a>

			<div class="flex items-center lg:order-2">
				<a *ngIf="!authenticated" href="/app-login"
					class="md:block hidden text-white bg-blueprimary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-semi-bold rounded-lg text-14 leading-20 px-4 lg:px-5 py-2 lg:py-2.5 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">
					{{ "login_out" | translate }}
				</a>
				<!-- notification -->

				<div *ngIf="authenticated">
					<img src="/assets/icons/bell.svg" class="mr-3 h-6 sm:h-9" alt="Noti" />
				</div>

				<!-- profile -->
				<div *ngIf="authenticated" class="relative md:block hidden" clickOutside
					(clickOutside)="handleClickOutsideProfile()">
					<button
						class="flex justify-center items-center text-blueprimary dark:text-white lg:mx-4 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none gap-1"
						(click)="onDropdownUser()">
						<img src="/assets/icons/user.svg" class="mr-3 h-6 sm:h-9" alt="Profile" />
					</button>
					<!-- dropdown profile -->
					<div class="z-50 absolute right-0 hidden w-40 mt-2 origin-top-right bg-white rounded-md shadow-lg"
						[class.block]="isDropDownUser" [class.hidden]="!isDropDownUser">
						<ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdown-button-2">
							<li>
								<button type="button" (click)="redirectToProfile()"
									class="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
									role="menuitem">
									<div class="inline-flex items-center">
										{{ "profileLbl" | translate }}
									</div>
								</button>
							</li>
							<li>
								<button type="button" (click)="onSignOut()"
									class="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
									role="menuitem">
									<div class="inline-flex items-center">
										{{ "logoutLbl" | translate }}
									</div>
								</button>
							</li>
						</ul>
					</div>
					<!-- dropdown profile -->

				</div>
				<!-- profile -->
				<!-- notification -->
				<!-- candidate -->
				<div class="relative md:block hidden" *ngIf="!authenticated" clickOutside
					(clickOutside)="handleClickOutsideCandidate()">
					<button href="#" (click)="onDropdown()"
						class="flex justify-center items-center text-blueprimary dark:text-white lg:mx-4 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none gap-1">
						<span *ngIf="role === candidateRole" class="text-14 leading-20">
							{{ "candidateLbl" | translate }}
						</span>
						<span *ngIf="role === recruiterRole" class="text-14 leading-20">
							{{ "recruiterLbl" | translate }}
						</span>
						<img src="/assets/icons/down.svg" class="w-4 h-4 text-blueprimary" />
					</button>
					<!-- dropdown candidate -->
					<div class="z-50 absolute right-0 hidden w-40 mt-2 origin-top-right bg-white rounded-md shadow-lg"
						[class.block]="isDropDown" [class.hidden]="!isDropDown">
						<ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdown-button-2">
							<li>
								<button type="button" (click)="onChangeRole(recruiterRole)"
									class="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
									role="menuitem">
									<div class="inline-flex items-center">
										{{ "recruiterLbl" | translate }}
									</div>
								</button>
							</li>
							<li>
								<button type="button" (click)="onChangeRole(candidateRole)"
									class="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
									role="menuitem">
									<div class="inline-flex items-center">
										{{ "candidateLbl" | translate }}
									</div>
								</button>
							</li>
						</ul>
					</div>
					<!-- dropdown candidate -->
				</div>
				<!-- candidate -->

				<!-- language-->
				<div class="relative md:block hidden" clickOutside (clickOutside)="handleClickOutsideLanguage()">
					<button href="#" (click)="onDropdownlangue()"
						class="flex justify-center items-center text-blueprimary dark:text-white lg:mx-4 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none gap-1">
						<img *ngIf="this.currentLanguage ==='vi'" src="/assets/icons/vn.svg" class="w-5 h-5 rounded-full" />
						<img *ngIf="this.currentLanguage ==='en'" src="/assets/icons/usa.svg" class="w-5 h-5 rounded-full" />
						<!-- <img src="/assets/icons/vn.svg" class="w-5 h-5 rounded-full" /> -->
						<img src="/assets/icons/down.svg" class="w-4 h-4 text-blueprimary" />
					</button>
					<!-- dropdown language -->
					<div class="z-50 absolute right-0 hidden w-40 mt-2 origin-top-right bg-white rounded-md shadow-lg"
						[class.block]="isDropDownLanguage" [class.hidden]="!isDropDownLanguage">
						<ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdown-button-2">
							<li [ngClass]="{'bg-gray-200': currentLanguage === 'vi'}">
								<button type="button" (click)="changeSiteLanguage(VI)"
									class="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
									role="menuitem">
									<div class="inline-flex items-center gap-3">
										<img src="/assets/icons/vn.svg" class="w-5 h-5 rounded-full" />
										{{ "vietLbl" | translate }}
									</div>
								</button>
							</li>
							<li [ngClass]="{'bg-gray-200': currentLanguage === 'en'}">
								<button type="button" (click)="changeSiteLanguage(EN)"
									class="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
									role="menuitem">
									<div class="inline-flex items-center gap-3">
										<img src="/assets/icons/usa.svg" class="w-5 h-5 rounded-full" />
										{{ "unitedStatesLbl" | translate }}
									</div>
								</button>
							</li>
						</ul>
					</div>
					<!-- dropdown language -->
				</div>
				<!-- language-->

				<!-- menu moblie -->
				<button data-collapse-toggle="mobile-menu-2" type="button" (click)="onOpenNavbar()"
					class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
					aria-controls="mobile-menu-2" aria-expanded="false">
					<span class="sr-only">Open main menu</span>
					<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd"
							d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
							clip-rule="evenodd"></path>
					</svg>
					<svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd"
							d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
							clip-rule="evenodd"></path>
					</svg>
				</button>
				<!-- menu moblie -->
			</div>

			<div [class.block]="isNavbar" [class.hidden]="!isNavbar"
				class="justify-between items-center w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu-2">
        <!-- navbar default -->
        <ul *ngIf="!authenticated"
            class="flex flex-col text-14 leading-20 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
					<li>
						<a  (click)="scroll($event)" routerLink="" routerLinkActive="active-link" [routerLinkActiveOptions]={exact:false}
							class="target block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">
							{{ "jobLbl" | translate }}</a>
					</li>
          <li>
            <a routerLink="/job-map" routerLinkActive="active-link"
               class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">
              {{ "jobMapLbl" | translate }}</a>
          </li>
					<li>
						<a routerLink="/candidate/referralprogram" routerLinkActive="active-link"
							class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">
							{{ "introProgramingLbl" | translate }}</a>
					</li>
					<li>
						<a href="#"
							class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">
							{{ "aboutUsLbl" | translate }}</a>
					</li>
				</ul>
        <!-- navbar default -->

        <!-- navbar candidate -->
        <ul *ngIf="authenticated && role === candidateRole"
            class="flex flex-col text-14 leading-20 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
          <li>
            <a routerLink="/candidate-home" routerLinkActive="active-link" [routerLinkActiveOptions]={exact:true}
               class="target block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">
              {{ "jobHeaderLbl" | translate }}</a>
          </li>
          <li>
            <a routerLink="/job-map" routerLinkActive="active-link"
               class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">
              {{ "jobMapLbl" | translate }}</a>
          </li>
          <li>
            <a routerLink="/candidate/referralprogram" routerLinkActive="active-link"
               class="target block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">
              {{ "programHeaderLbl" | translate }}</a>
          </li>
          <li>
            <a routerLink="/candidate/joberwallet" routerLinkActive="active-link"
               class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">
              {{ "walletHeaderLbl" | translate }}
            </a>
          </li>
          <li>
            <a routerLink="/candidate/profile" routerLinkActive="active-link"
               class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">
              {{ "profileHeaderLbl" | translate }}
            </a>
          </li>
          <li>
            <a routerLink="/candidate/calendar" routerLinkActive="active-link"
               class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">
              {{ "scheduleHeaderLbl" | translate }}
            </a>
          </li>
          <li>
            <a routerLink="/candidate/joblist" routerLinkActive="active-link"
               class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">
              {{ "ListJobHeaderLbl" | translate }}</a>
          </li>
        </ul>
        <!-- navbar candidate -->

        <!-- navbar recruiter -->
        <ul *ngIf="authenticated && role === recruiterRole "
            class="flex flex-col text-14 leading-20 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
          <li>
            <a routerLink="/recruiter/candidate-management" routerLinkActive="active-link"
               class="target block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">
              {{ "managerCandidateHeaderLbl" | translate }}</a>
          </li>
          <li>
            <a routerLink="/job-map" routerLinkActive="active-link"
               class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">
              {{ "jobMapLbl" | translate }}</a>
          </li>
          <li>
            <a routerLink="/recruiter/recruit-management" routerLinkActive="active-link"
               class="target block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">
              {{ "managerRecruiterHeaderLbl" | translate }}</a>
          </li>
          <li>
            <a routerLink="/recruiter/jober-wallet" routerLinkActive="active-link"
               class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">
              {{ "walletHeaderLbl" | translate }}
            </a>
          </li>
          <li>
            <a routerLink="/recruiter/profile" routerLinkActive="active-link"
               class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">
              {{ "profileHeaderLbl" | translate }}
            </a>
          </li>
          <li>
            <a routerLink="/recruiter/calendar" routerLinkActive="active-link"
               class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">
              {{ "scheduleHeaderLbl" | translate }}
            </a>
          </li>
        </ul>
        <!-- navbar recruiter -->
			</div>
		</div>
	</nav>
</header>
