import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UtilsService } from '../../../helper/service/utils.service';
import { PopupDetailItemComponent } from '../popup-detail-item/popup-detail-item.component';
import { CommonService } from '../../../service/common.service';
import { Router } from '@angular/router';
import { AuthService } from '../../../core/auth/auth.service';
import { ToastComponent } from '../../../layout/toast/toast.component';
import { ConstantsApp } from '../../../constant/ConstantsApp';
import { PopupConfirmApplyComponent } from '../popup-confirm/popup-confirm.component';
import { PopupJobFindingComponent } from '../../candidate/profile/popupJobFinding/popup-job-finding.component';

@Component({
  selector: 'app-items-list',
  templateUrl: './job-items-list.component.html',
  styleUrls: ['./job-items-list.component.css'],
})
export class JobItemsListComponent implements OnInit, OnChanges {
  @Input() dataSource: any;
  @Input() currentPage: any;
  data: any;
  totalPage: any;
  prePage: any;
  nextPage: any;
  arrPage: any;
  isRedirect: any;
  @Output() changePage = new EventEmitter();
  @Output() onViewDetailItem = new EventEmitter();
  @ViewChild('popupDetailItem') popupDetailItem: PopupDetailItemComponent =
    new PopupDetailItemComponent(
      this.commonService,
      this.router,
      this.cdr,
      this.authService
    );
  @ViewChild('appToast') appToast: ToastComponent = new ToastComponent();
  @ViewChild('popupConfirmApply')
  popupConfirmApply: PopupConfirmApplyComponent =
    new PopupConfirmApplyComponent();
  @ViewChild('jobFinding') jobFindingPopUp: PopupJobFindingComponent =
    new PopupJobFindingComponent();

  constructor(
    private utilsService: UtilsService,
    private router: Router,
    private authService: AuthService,
    private commonService: CommonService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }
  ngOnInit() {
    this.init();
  }
  init() {
    if (
      !this.utilsService.isEmpty(this.dataSource) &&
      !this.utilsService.isEmpty(this.dataSource.data)
    ) {
      this.data =
        this.dataSource && this.dataSource.data ? this.dataSource.data : [];
      this.totalPage = this.dataSource.totalPage;
      this.currentPage = this.currentPage != null ? this.currentPage : 1;
      this.arrPage = [];
      for (let i = 1; i <= this.totalPage; i++) {
        this.arrPage.push(i);
      }
      this.setPreNextPage();
      console.log(this.currentPage);
    } else {
      this.dataSource = {};
      this.data = [];
      this.arrPage = [1];
    }
  }
  handleNavigate = () => {};
  isPrePageActive() {
    if (this.totalPage <= 1 || this.currentPage == 1) return false;

    return true;
  }
  isNextPageActive() {
    if (this.totalPage <= 1 || this.currentPage == this.totalPage) return false;
    return true;
  }
  onPrePageClick() {
    if (!this.isPrePageActive()) return;
    this.currentPage = this.currentPage - 1;
    this.setPreNextPage();
    this.onChangePage();
  }
  onNextPageClick() {
    if (!this.isNextPageActive()) return;
    this.currentPage = this.currentPage + 1;
    this.setPreNextPage();
    this.onChangePage();
  }
  setPreNextPage() {
    this.prePage = this.currentPage - 1;
    this.nextPage = this.currentPage + 1;
  }

  onCurrentPageClick(pageIdx: any) {
    if (this.currentPage == pageIdx) return;
    this.currentPage = pageIdx;
    this.setPreNextPage();
    this.onChangePage();
  }
  onChangePage() {
    this.changePage.emit(this.currentPage);
  }
  isDisplayPageNumber(pageNum: any) {
    if (this.currentPage <= 2) {
      return pageNum >= this.currentPage - 1 && pageNum < 6;
    }
    if (this.currentPage > this.totalPage - 2) {
      return pageNum > this.totalPage - 5 && pageNum <= this.totalPage;
    }
    if (this.currentPage > 2) {
      return pageNum >= this.currentPage - 2 && pageNum < this.currentPage + 3;
    }
    return pageNum >= this.currentPage && pageNum < this.currentPage + 5;
  }

  openPopupDetailItem(item: any) {
    this.popupDetailItem.openPopup(item);
  }


  showInfoSuccessfullyApply(code: any) {
    this.appToast.show({messageCode: code});
  }

  /**
   * param {item: jobItem, isRedirect: true/false}
   */
  showPopupConfirmApply(param: any) {
    let jobItem = param.item;
    let isRedirect = param.isRedirect;
    this.popupConfirmApply.openPopup(jobItem, isRedirect);
  }
}
