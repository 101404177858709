<!-- google-maps-demo.component.html -->
<google-map height="400px"
            width="750px"
            [center]="center"
            [zoom]="zoom"
            (mapClick)="addMarker($event)">
  <map-marker #marker="mapMarker"
              *ngFor="let markerPosition of markerPositions"
              [position]="markerPosition"
              (mapClick)="openInfoWindow(marker)"></map-marker>
  <map-info-window>Info Window content</map-info-window>
</google-map>