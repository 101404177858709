	<div class="popup-container" id="popup-booking">
		<div class="modal">
			<div
				class="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] md:w-[50%] w-[90%] max-h-[90vh] inline-flex flex-col gap-3 rounded-lg mx-2"
				style="background: var(--white);" *ngIf="methodInterview == 1">
				<div class="flex px-3 py-2 items-center gap-5"
					style="border-radius: 8px 8px 0px 0px; background: var(--blue-text);">
					<button (click)="onInterview(-1)"><img src="../../../../../../assets/icons/arrow-left.svg" alt="icon-arrow-left">
					</button>

					<div class="flex items-center justify-center gap-28 flex-1">
						<div class="flex items-center gap-5 text-white">
							<span class="font-body font-semibold text-xl">{{ "bookInterviewLbl"
								| translate }}</span>
						</div>
					</div>
					<button (click)="closePopup()"><img src="../../../../../../assets/icons/close.svg" alt="icon-close">
					</button>
				</div>
				<div class="flex flex-col md:p-6 gap-7 overflow-auto -mt-4">
					<div class="flex flex-col items-start gap-5">
						<form action="" [formGroup]="scheduleForm" class="md:ml-3 px-2 w-full gap-5 mt-2 md:mt-0">
							<div class="flex items-start gap-7 mb-3 w-full">
								<div class="form-group">
									<div class="">
										<span class="font-body font-semibold text-sm" style="color: var(--ink);">{{
											"topicLbl" | translate }}</span>
									</div>
									<div class="form-input">
										<input formControlName="topic" [(ngModel)]="schedule.topic"
											class="flex md:px-3 py-2 items-center gap-1 w-full outline-none" type="text">
									</div>
								</div>
							</div>

							<div class="flex items-start gap-7 mb-3 w-full">
								<div class="form-group">
									<div class="">
										<span class="font-body font-semibold text-sm" style="color: var(--ink);">{{
											"desJobLbl" | translate }}</span>
									</div>
									<div class="form-input">
										<input formControlName="des" [(ngModel)]="schedule.des"
											class="flex px-3 py-2 items-center gap-1 flex-1 outline-none" type="text">
									</div>
								</div>
							</div>

							<div class="flex items-start gap-7 mb-3 w-full">
								<div class="form-group">
									<div class="">
										<span class="font-body font-semibold text-sm" style="color: var(--ink);">{{
											"interviewDate" | translate }}</span>
									</div>
									<div class="form-input">
										<input formControlName="interviewDate" [(ngModel)]="schedule.interviewDate"
											class="flex px-3 py-2 items-center gap-1 flex-1 outline-none" type="date" placeholder="Select date">
									</div>
								</div>
							</div>

							<div class="flex items-start md:gap-7 mb-3 w-full flex-wrap gap-2">
								<div class="form-group">
									<div class="">
										<span class="font-body font-semibold text-sm" style="color: var(--ink);">{{
											"fromLbl" | translate }}</span>
									</div>
									<div class="form-input">
										<input formControlName="interviewStartTime" [(ngModel)]="schedule.interviewStartTime"
											class="flex px-3 py-2 items-center gap-1 flex-1 outline-none" type="time">
									</div>
								</div>

								<div class="form-group">
									<div class="">
										<span class="font-body font-semibold text-sm" style="color: var(--ink);">{{
											"toLbl" | translate }}</span>
									</div>
									<div class="form-input">
										<input formControlName="interviewEndTime" [(ngModel)]="schedule.interviewEndTime"
											class="flex px-3 py-2 items-center gap-1 flex-1 outline-none" type="time">
									</div>
								</div>
							</div>
							<div class="md:gap-7 gap-2 mb-3 md:w-[50%] w-full">
								<app-radio-button [items]="radioItems" (radioOnChecked)="radioOnChecked($event)"></app-radio-button>
							</div>
							<div *ngIf="isChoseOnline()" class="flex items-start gap-7 mb-3 w-full flex-wrap">
								<div class="form-group">
									<div class="form-label">
										<span class="font-body font-semibold text-sm" style="color: var(--ink);">Meeting
											ID</span>
									</div>
									<div class="form-input">
										<input class="flex px-3 py-2 items-center gap-1 flex-1 outline-none" type="text"
											placeholder="123456789" formControlName="meetingId" [(ngModel)]="schedule.meetingId">
									</div>
								</div>

								<div class="form-group">
									<div class="form-label">
										<span class="font-body font-semibold text-sm" style="color: var(--ink);">Passcode</span>
									</div>
									<div class="form-input">
										<input class="flex px-3 py-2 items-center gap-1 flex-1 outline-none" type="text"
											placeholder="2345678" formControlName="passcode" [(ngModel)]="schedule.passcode">
									</div>
								</div>

								<div class="form-group">
									<div class="form-label">
										<span class="font-body font-semibold text-sm" style="color: var(--ink);">Linkzoom</span>
									</div>
									<div class="form-input">
										<input class="flex px-3 py-2 items-center gap-1 flex-1 outline-none" type="text" 
										formControlName="linkzoom" [(ngModel)]="schedule.linkzoom">
									</div>
								</div>
							</div>
							<div *ngIf="!isChoseOnline()" class="flex items-start gap-7 mb-3 w-full">
								<div class="form-group">
									<div class="form-label">
										<span class="font-body font-semibold text-sm" style="color: var(--ink);">{{
											"addressLbl" | translate }}</span>
									</div>
									<div class="form-input">
										<input formControlName="address" [(ngModel)]="schedule.address"
										class="flex px-3 py-2 items-center gap-1 flex-1 outline-none" type="text"
											placeholder="Duy Tân, Cầu Giấy, Hà Nội">
									</div>
								</div>
							</div>
							<div class="flex justify-center gap-5 mb-2">
								<div class="flex flex-row justify-center items-center gap-2 h-8">
									<button class="flex justify-center items-center px-6 py-2 gap-2 rounded-lg"
										style="background: rgba(0, 0, 0, 0.30);" (click)="closePopup()">
										<span class="font-body font-semibold text-xs items-center" style="color: var(--white);">
											{{ "cancelLbl" | translate}}
										</span>
									</button>

									<button class="flex justify-center items-center px-6 py-2 gap-2 rounded-lg"
										style="background: var(--blue-text);" (click)="onInterview(2)">
										<span class="font-body font-semibold text-xs items-center" style="color: var(--white);">
											{{ "createInterviewLbl" | translate}}
										</span>
									</button>
								</div>
							</div>
						</form>
					</div>


				</div>
			</div>
		<!-- End Book Interview -->

		<!-- Create Interview -->
		<!--<div class="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[70%] max-h-[90vh] inline-flex flex-col gap-3 rounded-lg"
         style="background: var(&#45;&#45;white);" *ngIf="methodInterview === 2">
      <div class="flex px-3 py-2 items-center gap-5"
           style="border-radius: 8px 8px 0px 0px; background: var(&#45;&#45;blue-text);">
        <button (click)="onInterview(1)"><img src="../../../../../../assets/icons/arrow-left.svg"
                                              alt="icon-arrow-left">
        </button>
        <div class="flex items-center justify-center gap-28 flex-1">
          <div class="flex items-center gap-5 text-white">
						<span class="font-body font-semibold text-xl">{{ "detailInterviewLbl"
              | translate }}</span>
          </div>
        </div>
        <button (click)="closePopup()"><img src="../../../../../../assets/icons/close.svg" alt="icon-close">
        </button>
      </div>

      <div class="flex flex-col p-6 gap-7 overflow-auto -mt-4">
        <div class="flex flex-col items-start gap-5">
          <form action="" class="ml-3 w-full gap-5">
            <div class="flex items-start gap-7 mb-3 w-full">
              <div class="form-group">
                <div class="form-label">
									<span class="font-body font-semibold text-sm" style="color: var(&#45;&#45;ink);">{{
                    "topicLbl" | translate }}</span>
                </div>
                <div class="form-input">
                  <input class="flex px-3 py-2 items-center gap-1 flex-1 outline-none" type="text"
                         placeholder="Phỏng vấn">
                </div>
              </div>

              <div class="form-group">
                <div class="form-label">
									<span class="font-body font-semibold text-sm" style="color: var(&#45;&#45;ink);">{{
                    "desJobLbl" | translate }}</span>
                </div>
                <div class="form-input">
                  <input class="flex px-3 py-2 items-center gap-1 flex-1 outline-none" type="text"
                         placeholder="Phỏng vấn công việc sửa mái nhà">
                </div>
              </div>
            </div>

            <div class="flex items-start gap-7 mb-3 w-full">
              <div class="form-group">
                <div class="form-label">
									<span class="font-body font-semibold text-sm" style="color: var(&#45;&#45;ink);">{{
                    "interviewDate" | translate }}</span>
                </div>
                <div class="form-input">
                  <input class="flex px-3 py-2 items-center gap-1 flex-1 outline-none" type="date"
                         placeholder="Select date">
                </div>
              </div>

              <div class="form-group">
                <div class="form-label">
									<span class="font-body font-semibold text-sm" style="color: var(&#45;&#45;ink);">{{
                    "fromLbl" | translate }}</span>
                </div>
                <div class="form-input">
                  <input class="flex px-3 py-2 items-center gap-1 flex-1 outline-none" type="time">
                </div>
              </div>

              <div class="form-group">
                <div class="form-label">
									<span class="font-body font-semibold text-sm" style="color: var(&#45;&#45;ink);">{{
                    "toLbl" | translate }}</span>
                </div>
                <div class="form-input">
                  <input class="flex px-3 py-2 items-center gap-1 flex-1 outline-none" type="time">
                </div>
              </div>
            </div>

            <div class="flex items-start gap-7 mb-3 w-full">
              <div class="form-group">
                <div class="form-label">
									<span class="font-body font-semibold text-sm" style="color: var(&#45;&#45;ink);">{{
                    "InterviewFormLbl" | translate }}</span>
                </div>
                <div class="form-input">
                  <input class="flex px-3 py-2 items-center gap-1 flex-1 outline-none" type="text"
                         placeholder="Online và Offline">
                </div>
              </div>
            </div>

            <div class="flex items-start gap-7 mb-3 w-full">
              <div class="form-group">
                <div class="form-label">
									<span class="font-body font-semibold text-sm" style="color: var(&#45;&#45;ink);">{{
                    "addressLbl" | translate }}</span>
                </div>
                <div class="form-input">
                  <input class="flex px-3 py-2 items-center gap-1 flex-1 outline-none" type="text"
                         placeholder="Duy Tân, Cầu Giấy, Hà Nội">
                </div>
              </div>
            </div>

            <div class="flex items-start gap-7 mb-3 w-full">
              <div class="form-group">
                <div class="form-label">
									<span class="font-body font-semibold text-sm" style="color: var(&#45;&#45;ink);">Meeting
										ID</span>
                </div>
                <div class="form-input">
                  <input class="flex px-3 py-2 items-center gap-1 flex-1 outline-none" type="text"
                         placeholder="123456789">
                </div>
              </div>

              <div class="form-group">
                <div class="form-label">
									<span class="font-body font-semibold text-sm"
                        style="color: var(&#45;&#45;ink);">Passcode</span>
                </div>
                <div class="form-input">
                  <input class="flex px-3 py-2 items-center gap-1 flex-1 outline-none" type="text"
                         placeholder="2345678">
                </div>
              </div>

              <div class="form-group">
                <div class="form-label">
									<span class="font-body font-semibold text-sm"
                        style="color: var(&#45;&#45;ink);">Linkzoom</span>
                </div>
                <div class="form-input">
                  <input class="flex px-3 py-2 items-center gap-1 flex-1 outline-none" type="text">
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="flex justify-center gap-5">
          <div class="flex flex-row justify-center items-center gap-2 h-8">
            <button class="flex justify-center items-center px-6 py-2 gap-2 rounded-lg"
                    style="background: var(&#45;&#45;blue-text);">
							<span class="font-body font-semibold text-xs items-center" style="color: var(&#45;&#45;white);">
								{{ "startInterviewLbl" | translate}}
							</span>
            </button>

            <button class="flex justify-center items-center px-6 py-2 gap-2 rounded-lg"
                    style="background: var(&#45;&#45;blue-text);" (click)="onInterview(3)">
							<span class="font-body font-semibold text-xs items-center" style="color: var(&#45;&#45;white);">
								{{ "resultInterviewLbl" | translate}}
							</span>
            </button>
          </div>
        </div>
      </div>
    </div>-->
		<!-- End Create Interview -->

		<!-- Result Interview -->
		<div
			class="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[40%] max-h-[90vh] inline-flex flex-col gap-3 rounded-lg"
			style="background: var(--white);" *ngIf="methodInterview === 3">
			<div class="flex px-3 py-2 items-center gap-5"
				style="border-radius: 8px 8px 0px 0px; background: var(--blue-text);">
				<button (click)="onInterview(1)"><img src="../../../../../../assets/icons/arrow-left.svg" alt="icon-arrow-left">
				</button>
				<div class="flex items-center justify-center gap-28 flex-1">
					<div class="flex items-center gap-5 text-white">
						<span class="font-body font-semibold text-xl">{{ "detailInterviewLbl"
							| translate }}</span>
					</div>
				</div>
				<button (click)="closePopup()"><img src="../../../../../../assets/icons/close.svg" alt="icon-close">
				</button>
			</div>

			<div class="flex flex-col p-6 gap-7 overflow-auto -mt-4">
				<div class="flex flex-col items-start gap-5 ml-3">
					<div class="flex flex-col items-start gap-2 w-full mb-2 -ml-5">
						<div class="flex items-start justify-between gap-7 mb-3 w-[50%]">
							<div class="flex items-center gap-2">
								<input id="effort" type="radio" value="effort" name="result"
									class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
								<label for="effort" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{
									"effortLbl"
									| translate }} </label>
							</div>

							<div class="flex items-center gap-2">
								<input id="noteffort" type="radio" value="noteffort" name="result"
									class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
								<label for="noteffort" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{
									"noteffortLbl"
									| translate }}</label>
							</div>
						</div>

						<div class="form-group w-full">
							<div class="form-label">
								<span class="font-body font-semibold text-sm" style="color: var(--ink);">Feedback</span>
							</div>
							<div class="form-input">
								<textarea rows="3" class="w-full"></textarea>
							</div>
						</div>
					</div>
				</div>

				<div class="flex justify-center gap-5">
					<div class="flex flex-row justify-center items-center gap-2 h-8">
						<button class="flex justify-center items-center px-6 py-2 gap-2 rounded-lg"
							style="background: var(--blue-text);">
							<span class="font-body font-semibold text-xs items-center" style="color: var(--white);">
								{{ "sendresultInterviewLbl" | translate}}
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<!-- End Interview -->

	</div>
</div>