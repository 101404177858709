<div class="popup-container" id="popup-confirm-apply">
	<div class="modal">
		<div class="modal-inner center-pos row">
			<div
				class="col-12 py-3 bg-blue rounded-top d-flex justify-content-center align-items-center position-relative">
				<span class="font-size-big font-weight-bold global-text-color-white">{{noticeLbl}}</span>
				<img type="button" (click)="closePopup()" src="../assets/icons/white-close.svg"
					class="position-absolute top-0 end-0 p-3">
			</div>
			<div class="col-12 py-4 d-flex justify-content-center">
				<div class="popup-content px-5">
					<span *ngIf="!isRedirect">{{"remindingCreateProfileLbl" | translate}}</span>
					<span *ngIf="isRedirect">{{"qaViewAppliedJob" | translate}}</span>
				</div>
			</div>
			<div class="col-12 py-4 horizontal-center-pos">
				<div class="col-md-6 left-btn-wrapper horizontal-end-pos vertical-center-pos-1">
					<button class="btn
                    border-none
                    font-size-small
                    font-weight-bold
                    color-disable-text
                    bg-disable px-5" (click)="closePopup()">{{cancelLbl}}</button>
				</div>
				<div class="col-md-6 right-btn-wrapper horizontal-start-pos vertical-center-pos-1">
					<button class="btn
                    border-none
                    font-size-small
                    font-weight-bold
                    global-text-color-white
                    bg-blue px-5" (click)="onValidate()">{{yesLbl}}</button>
				</div>
			</div>
		</div>
	</div>
</div>
