<div class="management-container">
	<div class="header-up-wrapper row">
		<app-header-up></app-header-up>
	</div>
	<div class="management-container-main" style="background-color: var(--background-admin)">
		<div class="management-container-left">
			<app-sidebar [isExpanded]="sidebarExpanded" (toggleSidebar)="sidebarExpanded = !sidebarExpanded"
				(activeLink)="onActiveLink($event)"></app-sidebar>
		</div>
		<div class="management-container-right">
			<app-table></app-table>
		</div>
	</div>
	<app-loading></app-loading>
</div>