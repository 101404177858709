import {Component, Input, OnInit, Output, EventEmitter, OnChanges, ViewChild} from '@angular/core';
import * as $ from 'jquery';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UtilsService} from "../../../../helper/service/utils.service";
import {ConstantsApp} from "../../../../constant/ConstantsApp";
import {Constants} from "../../../../constant/Constants";
import {CommonService} from "../../../../service/common.service";
import { environment } from 'src/environments/environment';
import {ApiNameConstants} from "../../../../constant/ApiNameConstants";
@Component({
  selector: 'app-popup-referral',
  templateUrl: './popup-referral.component.html',
  styleUrls: ['./popup-referral.component.scss'],
})
export class PopupReferralComponent implements OnInit {
  @Output() validate = new EventEmitter();
  @Input() confirmCode: any;
  inforForm: any;
  userItem: any;
  selectedGender: any;
  genders: any;
  selectedProvince: any;
  selectedDistrict: any;
  selectedWard: any;
  province: any;
  district: any;
  ward: any;
  provinces: any;
  districts: any;
  wards: any;
  isShowTarget: any;
  roleItem: any;
  radioItems: any;
  constructor(
    private commonService: CommonService,
    private utilsService: UtilsService){
  }
  ngOnInit(): void {
    this.closePopup();
    this.init();
  }
  get phone(): any {
    return this.inforForm.get('phone');
  }
  get salary(): any {
    return this.inforForm.get('salary');
  }
  get strength(): any {
    return this.inforForm.get('strength');
  }
  get name(): any {
    return this.inforForm.get('name');
  }
  get job(): any {
    return this.inforForm.get('job');
  }
  get des(): any {
    return this.inforForm.get('des');
  }
  get email(): any {
    return this.inforForm.get('email');
  }
  get address(): any {
    return this.inforForm.get('address');
  }
  get dateOfBirth(): any {
    return this.inforForm.get('dateOfBirth');
  }
  init() {
    this.genders = [{code: 1, name: Constants.maleLbl}, {code: 2, name: Constants.femaleLbl}, {code: 3, name: Constants.otherLbl}]
    this.userItem = {
      address: '',
      phone: '',
      name: '',
      job: '',
      dateOfBirth: '',
      des: '',
      salary: '',
      cv: '',
      img: '',
      lat: '',
      lg: '',
      gender: 1,
      role: 3,
      introPhone: '',
      jobTarget: '',
      country: 'Việt Nam',
      nationality:'Việt Nam',
      experience: '',
      province: 'Hà Nội',
      district: 'Cầu Giấy',
      ward: 'Dịch Vọng Hậu'
    };
    this.inforForm = new FormGroup({
      phone : new FormControl(this.userItem.phone, [Validators.required, Validators.pattern(this.utilsService.phonePattern)]),
      name : new FormControl(this.userItem.name, [Validators.required]),
      address : new FormControl(this.userItem.address, [Validators.required]),
      jobTarget : new FormControl(this.userItem.jobTarget),
      experience : new FormControl(this.userItem.experience),
      dateOfBirth : new FormControl(this.userItem.dateOfBirth, [Validators.required]),
      email : new FormControl(this.userItem.email, [Validators.required,  Validators.pattern(this.utilsService.emailPattern)]),
    });
    this.isShowTarget = true;
    this.radioItems = [
      {
        label: Constants.searchJobLbl,
        val: ConstantsApp.CANDIDATE,
        checked: true
      },
      {
        label: Constants.recruitLbl,
        val: ConstantsApp.RECRUITER,
        checked: false
      }
    ];
    this.roleItem = {
      label: Constants.searchJobLbl,
      val: ConstantsApp.CANDIDATE,
      checked: true
    };
    this.getData();
  }
  openPopup() {
    document.body.classList.add('no-scroll');
    $('#popup-referral .modal').removeClass('hide');
    $('#popup-referral .modal').addClass('display');
    this.init();
  }

  closePopup() {
    document.body.classList.remove('no-scroll');
    $('#popup-referral .modal').removeClass('display');
    $('#popup-referral .modal').addClass('hide');
  }

  onValidate() {
    this.closePopup();
  }

  onGenderSelected(genderCode: any) {
    this.userItem.gender = genderCode;
    this.selectedGender = this.utilsService.buildSelectedItem(ConstantsApp.gender, this.genders, this.userItem);
  }

  getData() {
    if (!this.utilsService.isEmpty(this.utilsService.getItem(ConstantsApp.provinces))) {
      this.provinces = this.utilsService.getItem(ConstantsApp.provinces);
      this.retrieveAdress();
      return;
    }
    this.commonService.getAllProvince().subscribe(res => {
      this.provinces = res.data;
      if (!this.utilsService.getItem(ConstantsApp.provinces)) {
        this.utilsService.setItem(ConstantsApp.provinces, this.provinces);
        this.retrieveAdress();
      }
    });
  }

  /**
   * build data to the address fields
   * @returns
   */
  retrieveAdress() {
    try {
      let provinceName = this.userItem.province;
      this.selectedProvince = this.utilsService.getAddressByName(this.provinces, provinceName);
      let provinceCode = this.selectedProvince.code;
      this.commonService.getDistrictsByProvince(provinceCode).subscribe(res => {
        if (res && res.data) {
          let data = res.data;
          if (data)
            this.districts = data;
          let districtName = this.userItem.district;
          this.selectedDistrict = this.utilsService.getAddressByName(this.districts, districtName);
          let districtCode = this.selectedDistrict.code;
          this.commonService.getWardsByDistrict(districtCode).subscribe(res => {
            if (res && res.data) {
              let data = res.data;
              if (data)
                this.wards = data;
                let wardName = this.userItem.ward;
                this.selectedWard = this.utilsService.getAddressByName(this.wards, wardName);
            }
          });
        }
      });
    } catch(e) {
      console.log(e);
    }
  }
  getPositionNameByCode(data: any, code: any) {
    for (let i = 0; i < data.length; i ++) {
      let item = data[i];
      if (item.code == code) {
        return item.name;
      }
    }
  }

  getDistrictsByProvince(provinceCode: any) {
    this.commonService.getDistrictsByProvince(provinceCode).subscribe(res => {
      if (res && res.data) {
        let data = res.data;
        if (data) {
          this.districts = data;
          let item = this.districts[0];
          let code = item.code;
          this.userItem.district = item.name;
          this.selectedDistrict = item.name;
          this.getWardsByDistrict(code);
        }
      }
    });
  }

  getWardsByDistrict(districtCode: any) {
    this.commonService.getWardsByDistrict(districtCode).subscribe(res => {
      if (res && res.data) {
        let data = res.data;
        if (data) {
          this.wards = data;
          let item = this.wards[0];
          this.userItem.ward = item.name;
          this.selectedWard = item.name;
        }
      }
    });
  }

  onProvinceSelected(provinceCode: any) {
    this.province = this.getPositionNameByCode(this.provinces, provinceCode);
    this.userItem.province = this.province;
    this.getDistrictsByProvince(provinceCode);
  }

  onDistrictSelected(districtCode: any) {
    this.district = this.getPositionNameByCode(this.districts, districtCode);
    this.userItem.district = this.district;
    this.getWardsByDistrict(districtCode);
  }

  onWardSelected(wardCode: any) {
    this.ward = this.getPositionNameByCode(this.wards, wardCode);
    this.userItem.ward = this.ward;
  }

  onCreateUser() {
    let functionName = 'onCreateUser';
    let messageLog = 'creating freelancer';
    let toastMessageCode = '';
    let apiUrl = environment.API_URI + ApiNameConstants.BS_USER_CREATE;
    let userLogin = this.utilsService.getItem(ConstantsApp.user);
    this.userItem.introPhone = userLogin.phone;
    this.userItem.role = this.roleItem.val;
    try {
      this.commonService.postDatas(this.userItem, apiUrl, functionName, messageLog)
        .subscribe(res => {
          if (res && res.code === ConstantsApp.SUCCESS_CODE) {
            // success close popup
            toastMessageCode = ConstantsApp.SUCCESS_CODE;
          } else if (res && res.code === ConstantsApp.EXISTED) {
            toastMessageCode = ConstantsApp.EXISTED;
          } else {
            toastMessageCode = ConstantsApp.NOT_MODIFIED_CODE;
          }
          this.validate.emit(toastMessageCode);
					this.closePopup();
        });
    } catch (error) {
      console.log('onCreateUser: ' + error);
    }
  }

  radioOnChecked(item: any) {
    this.roleItem = item;
    this.isShowTarget = !this.isShowTarget;
    console.log('isShowTarget: ', this.isShowTarget);
    console.log('roleItem: ', this.roleItem);
  }
  isValidDOB() {
    console.log('isValidDOB: ', this.userItem.dateOfBirth, this.utilsService.isValidDOB(this.userItem.dateOfBirth))
    return this.utilsService.isValidDOB(this.userItem.dateOfBirth);
  }
}
