<div class="sidebar-wrapper">
    <div class="sidebar">
        <div class="logo-content">
            <div class="logo">
                <i class="bx bxl-c-plus-plus"></i>
                <div class="logo-name">Jober</div>
            </div>
            <i class="fa-regular fa-bars" id="btn-menu" (click)="onToggle()"></i>
        </div>
        <ul class="nav-list">
            <li>
                <a href="#">
                    <i class="bx bx-grid-alt"></i>
                    <span class="links-name">Demo</span>
                </a>
                <!-- <span class="tooltip">demo</span> -->
            </li>
            <li>
                <a href="#">
                    <i class="bx bx-grid-alt"></i>
                    <span class="links-name">Demo 1</span>
                </a>
                <!-- <span class="tooltip">demo</span> -->
            </li>
        </ul>
        <div class="website-info-wrapper">
            <div class="website-info">
                Website info
            </div>
        </div>
    </div>
</div>