import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import * as $ from 'jquery';
import { ApiNameConstants } from 'src/app/constant/ApiNameConstants';
import { Constants } from 'src/app/constant/Constants';
import { ConstantsApp } from 'src/app/constant/ConstantsApp';
import { UtilsService } from 'src/app/helper/service/utils.service';
import { CommonService } from 'src/app/service/common.service';
import { ExportService } from 'src/app/service/export.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-popup-cv-management',
  templateUrl: './popup-cv-management.component.html',
  styleUrls: ['./popup-cv-management.component.scss']
})
export class PopupCVMangagementComponent implements OnInit {
  @Output() closeCVManagePage = new EventEmitter();
  displayedColumns: any = [];
  dataOrg: any;
  isShowDetail: any;
  item: any;
  checkedItems: any;
  pageNum: number = 1;

  constructor(
    private commonService: CommonService,
    private utilsService: UtilsService,
		private exportService:ExportService
  ) { }

  ngOnInit() {
    this.init();
  }
  init() {
    this.displayedColumns = [
      {
				name: Constants.CV,
				key: ConstantsApp.cv,
				isAsc: true
			},
			{
				name: Constants.jobLbl,
				key: ConstantsApp.jdName,
				isAsc: true
			},
			{
				name: Constants.creationDateLbl,
				key: ConstantsApp.creationDate,
				isAsc: true
			}
		]
    this.getData();
  }
  closePage() {
    this.closeCVManagePage.emit(false);
  }
  getData() {
    let paging = {
      page: this.pageNum,
      size: 100
    }
    const functionName = 'getData';
    const messageLog = 'get candidate posts';
    const apiUrl = environment.API_URI + ApiNameConstants.BS_USER_CANDIDATE_POSTS
    this.commonService
      .postDatas(paging, apiUrl, functionName, messageLog)
      .subscribe((res: any) => {
        this.dataOrg = res;
        if(this.dataOrg.data) {
          this.dataOrg.data = this.dataOrg.data.filter((item: any) => item.cv)
        }
        let temp: any[] = [];
        let dataTable = this.dataOrg.data;
        console.log(dataTable);
        dataTable.map((item: any) => {
          item.creationDate = this.utilsService.formatDate(item.creationDate);
          temp.push(item);
        });
        this.dataOrg.data = temp;
      });
  }
  changePage(currentPage: any) {
    this.pageNum = currentPage;
    this.getData();
  }

  showDetail(item: any) {
    this.item = item;
    this.isShowDetail = true;
  }

  getCheckedItems(items: any) {
    this.checkedItems = items;
  }

  delete() {
    if (this.checkedItems && this.checkedItems.size > 0) {
    //   call API
    }
  }

  exportFile() {
    if (this.checkedItems && this.checkedItems.length > 0) {
      //   call API
			this.exportService.exportExcel(this.checkedItems,"candidatePosts")
    }
  }
}
