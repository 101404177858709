<app-toast #appToast [messageCode]=""></app-toast>
<div class="calendar-warpper h-3/4">
	<div class="flex flex-col items-start gap-2.5 mt-2 mr-5 h-full">
		<div class="flex flex-row justify-between items-center py-4 px-3 gap-4 bg-white rounded-xl w-full"
			style="box-shadow: 0 5px 6px rgba(0, 89, 255, 0.25)">
			<p class="font-body not-italic font-semibold text-2xl text-black">
				{{ "calendarLbl" | translate }}
			</p>

			<div class="flex flex-row items-start gap-4">
				<!-- <button class="flex flex-col justify-center items-center gap-2">
					<p class="flex items-center text-center font-body not-italic font-semibold text-xs px-4 py-2"
						style="background: rgba(28, 27, 31, 0.12); border-radius: 8px">
						{{ "deleteLbl" | translate }}
					</p>
				</button>
				<button class="flex flex-row justify-center items-center gap-2 rounded-lg bg-blue">
					<p
						class="global-text-color-white flex items-center text-center font-body not-italic font-semibold text-xs px-4 py-2">
						{{ "exportExcelLbl" | translate }}
					</p>
				</button> -->
			</div>
		</div>

		<div class="flex flex-col items-start p-3 gap-10 bg-white w-full h-full"
			style="border: 1px solid #ebf2ff; border-radius: 12px">
			<div class="flex flex-col justify-between px-7 py-3 bg-white gap-5 w-full h-full">
				<div class="flex justify-between content-between items-start self-stretch">
					<form [formGroup]="dateForm" (ngSubmit)="onSearch()">
						<div class="flex items-start gap-10">
							<div class="flex flex-col gap-2">
								<div class="flex px-2 items-start text-[var(--ink)]">
									<label class="font-medium" for="startDate">{{ "startDate" | translate }}</label>
								</div>
								<input [(ngModel)]="startDate" (input)="onChoseStartDate(startDate)" formControlName="startDate"
									class="w-full py-2 px-3 rounded-lg border-2 border-black border-opacity-5" type="date"
									name="startDate" id="startDate" />
							</div>
							<!-- <div class="flex flex-col gap-2">
								<div class="flex px-2 items-start gap-2 text-[var(--ink)]">
									<label class="font-medium" for="endDate">{{ "endDate" | translate }}</label>
								</div>
								<input [(ngModel)]="endDate" (input)="onChoseEndDate(endDate)" formControlName="endDate"
									class="py-2 px-3 rounded-lg border-2 border-black border-opacity-5" type="date" name="endDate"
									id="endDate" />
							</div> -->
						</div>
					</form>
				</div>
				<div class="calendar-wrapper">
					<full-calendar #fullCalendar [options]="calendarOptions"></full-calendar>
				</div>
			</div>
		</div>
	</div>
</div>
<app-popup-calendar-item #popupCalendarItemComponent></app-popup-calendar-item>
