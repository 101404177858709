<div class="popup-container" id="popup-detail-job">
  <div class="modal display">
    <div class="center-pos row w-[80%]">
      <div
        class="flex flex-col gap-10 rounded-xl bg-white"
        style="padding: 20px 40px 40px 40px"
      >
        <button
          (click)="closePopup()"
          class="flex right-0 justify-end items-center"
        >
          <img src="../../../../../assets/icons/x-close.svg" alt="icon-close" />
        </button>

        <div
          class="flex justify-between items-start p-2 self-stretch rounded-xl"
          style="background: var(--ink-background)"
        >
          <div class="flex items-start gap-7">
            <img src="../../../../assets/img/detail-job.png" alt="" />
            <div class="flex flex-col items-start gap-3">
              <div class="flex flex-col items-start gap-4">
                <span
                  class="font-body font-semibold not-italic text-2xl text-black"
                  >{{ item.name }}</span
                >
                <div class="flex flex-col items-start gap-2">
                  <div class="flex items-center gap-3">
                    <img src="../../../../assets/icons/building.svg" alt="" />
                    <span
                      class="font-body font-normal not-italic text-base text-black first-letter:uppercase"
                      >{{ item.job }}</span
                    >
                  </div>
                  <div class="flex items-center gap-3">
                    <img src="../../../../assets/icons/time.svg" alt="" />
                    <span
                      class="font-body font-normal not-italic text-sm first-letter:uppercase"
                      style="color: rgba(0, 0, 0, 0.3)"
                      >Hạn nộp hồ sơ: {{ item.expdate }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col items-start gap-3">
            <div
              class="flex flex-col items-center justify-center gap-2 rounded-lg w-[130px]"
              style="background: var(--blue-text)"
            >
              <button class="flex justify-center items-center gap-2 px-4 py-2">
                <span
                  class="font-body font-semibold not-italic text-base text-center text-white"
                  >{{ "applyJobLbl" | translate }}</span
                >
              </button>
            </div>
            <div
              class="flex flex-col items-center justify-center gap-2 rounded-lg w-[130px]"
              style="
                background: var(--background-colapse-bar);
                border: 1px solid var(--blue-text);
              "
            >
              <button
                class="flex justify-center items-center gap-2"
                style="padding: 10px 24px 10px 16px"
              >
                <img src="../../../../assets/icons/heart.svg" alt="" />
                <span
                  class="font-body font-semibold not-italic text-base text-center"
                  style="color: var(--blue-text)"
                  >{{ "saveMessageLbl" | translate }}</span
                >
              </button>
            </div>
          </div>
        </div>

        <div class="flex flex-col items-start gap-5">
          <div
            class="flex items-start self-stretch gap-[60px]"
            style="border-bottom: 1px solid rgba(0, 0, 0, 0.3)"
          >
            <span
              class="font-body font-semibold text-base not-italic text-black"
            >
              {{ "RecruitmentLbl" | translate }}
            </span>
            <span
              class="font-body font-semibold text-base not-italic text-black"
            >
              {{ "infoCompanyLbl" | translate }}
            </span>
            <span
              class="font-body font-semibold text-base not-italic text-black"
            >
              {{ "relatedJobsLbl" | translate }}
            </span>
          </div>

          <div class="flex items-start gap-6">
            <div
              class="flex flex-col p-5 items-start gap-8 rounded-xl w-[70%]"
              style="background: var(--ink-background)"
            >
              <div class="flex flex-col items-start gap-2">
                <span
                  class="flex flex-col self-stretch font-body font-semibold not-italic uppercase text-xl"
                  style="color: var(--base-ink-sub-text)"
                >
                  {{ "desJobLbl" | translate }}
                </span>
                <span
                  class="font-body font-normal not-italic text-base"
                  style="color: var(--base-ink-border)"
                >
                  {{ item.des }}
                </span>
              </div>
              <div class="flex flex-col items-start gap-2">
                <span
                  class="flex flex-col self-stretch font-body font-semibold not-italic uppercase text-xl"
                  style="color: var(--base-ink-sub-text)"
                >
                  {{ "candidateRequirementsLbl" | translate }}
                </span>
                <span
                  class="font-body font-normal not-italic text-base"
                  style="color: var(--base-ink-border)"
                >
                  {{ item.requiredSkill }}
                </span>
              </div>
              <div class="flex flex-col items-start gap-2">
                <span
                  class="flex flex-col self-stretch font-body font-semibold not-italic uppercase text-xl"
                  style="color: var(--base-ink-sub-text)"
                >
                  {{ "benefitLbl" | translate }}
                </span>
                <span
                  class="font-body font-normal not-italic text-base"
                  style="color: var(--base-ink-border)"
                >
                  {{ item.profit }}
                </span>
              </div>
              <div class="flex flex-col items-start gap-2">
                <span
                  class="flex flex-col self-stretch font-body font-semibold not-italic uppercase text-xl"
                  style="color: var(--base-ink-sub-text)"
                >
                  {{ "contactInfoLbl" | translate }}
                </span>
                <span
                  class="font-body font-normal not-italic text-base"
                  style="color: var(--base-ink-border)"
                >
                  Phone: {{ item.phone }} <br />
                  Email: {{ item.email }}
                </span>
              </div>
            </div>
            <div
              class="flex flex-col items-start p-5 gap-6 rounded-xl"
              style="background: var(--ink-background)"
            >
              <div class="flex items-start gap-2">
                <img src="../../../../assets/icons/location.svg" alt="" />
                <div class="flex flex-col items-start gap-1">
                  <span
                    class="font-body font-normal text-base not-italic color-[var(--base-ink-main-text)]"
                    >{{ "positionLbl" | translate }}</span
                  >
                  <span
                    class="font-body font-normal text-base not-italic color-[var(--base-ink-border)]"
                    >{{ item.district }}, {{ item.province }}</span
                  >
                </div>
              </div>
              <div class="flex items-start gap-2">
                <img src="../../../../assets/icons/salary.svg" alt="" />
                <div class="flex flex-col items-start gap-1">
                  <span
                    class="font-body font-normal text-base not-italic color-[var(--base-ink-main-text)]"
                    >{{ "salaryLbl" | translate }}</span
                  >
                  <span
                    class="font-body font-normal text-base not-italic color-[var(--base-ink-border)]"
                    >{{ item.salary }}</span
                  >
                </div>
              </div>
              <div class="flex items-start gap-2">
                <img src="../../../../assets/icons/users.svg" alt="" />
                <div class="flex flex-col items-start gap-1">
                  <span
                    class="font-body font-normal text-base not-italic color-[var(--base-ink-main-text)]"
                    >{{ "recruiterNumberLbl" | translate }}</span
                  >
                  <span
                    class="font-body font-normal text-base not-italic color-[var(--base-ink-border)]"
                    >{{ item.number }} người</span
                  >
                </div>
              </div>
              <div class="flex items-start gap-2">
                <img src="../../../../assets/icons/briefcase.svg" alt="" />
                <div class="flex flex-col items-start gap-1">
                  <span
                    class="font-body font-normal text-base not-italic color-[var(--base-ink-main-text)]"
                    >{{ "experienceLbl" | translate }}</span
                  >
                  <span
                    class="font-body font-normal text-base not-italic color-[var(--base-ink-border)]"
                  >
                    {{ item.requireExperienceLevel }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
