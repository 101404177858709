import {Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import * as $ from 'jquery';
import { environment } from '../../../../environments/environment';
import { ApiNameConstants } from '../../../constant/ApiNameConstants';
import { CommonService } from '../../../service/common.service';
import { ConstantsApp } from '../../../constant/ConstantsApp';
import { UtilsService } from '../../../helper/service/utils.service';
import { MapService } from '../../../service/map.service';
import {AuthService} from "../../../core/auth/auth.service";
import {Router} from "@angular/router";
import {CandidateManagement} from "../../../model/CandidateManagement";

@Component({
  selector: 'app-popup-detail-item',
  templateUrl: './popup-detail-item.component.html',
  styleUrls: ['./popup-detail-item.component.scss'],
})
export class PopupDetailItemComponent implements OnInit {
  @Output() validate = new EventEmitter();
  @Output() openPopupConfirm = new EventEmitter();
  @Output() applyInform = new EventEmitter();
  item: any;
  authenticated: any;

  constructor(private commonService: CommonService,
              private router: Router,
              private cdr: ChangeDetectorRef,
              private authService: AuthService) {}
  ngOnInit(): void {
    if (!this.item) {
      this.item = {
        id: '',
        name: '',
        address:'',
        phone: null,
        distance: '',
        job: '',
        number: '',
        salary: '',
        des: '',
        img: null,
        lat: ConstantsApp.LAT_DEFAULT,
        lng: ConstantsApp.LNG_DEFAULT,
        expdate: '',
        workingType: '',
        profit: '',
        requiredSkill: '',
        requireExperienceLevel: '',
        type: null,
        creationdate: '',
        province: '',
        district: '',
        ward: '',
        email: null,
      };
    }
    this.authenticated = this.authService.authenticated();
    this.closePopup();
  }


  openPopup(item: any) {
    // reinit data when open popup
    this.init(item);
    let modal = $('#popup-detail-item .modal');
    modal.removeClass('hide');
    modal.addClass('display');

    $('body').addClass('no-scroll');
  }

  init(item: any) {
    this.getData(item);
  }

  onSave() {
    if (!this.authenticated) {
      this.router.navigate(["/app-login"]);
    }
    /**
     * If this job is saved, don't allow save anymore
     */
    if (this.item.status == ConstantsApp.saved) {
      this.item.active = 0;
    } else {
      this.item.status = ConstantsApp.saved;
    }
    let body = new CandidateManagement(this.item.candidateManagementId, this.item.id, this.item.status, 1, this.item.active);
    const functionName = 'onSave';
    const messageLog = 'save this job';
    let apiUrl = environment.API_URI + ApiNameConstants.BS_CANDIDATE_SAVE_JOB;
    this.commonService
      .postDatas(body, apiUrl, functionName, messageLog)
      .subscribe(
        (res: any) => {
          if (res && res.code == ConstantsApp.CREATED) {
            let data = res.data;
            this.item.candidateManagementId = data.id;
            this.item.status = data.status;
          }
        },
        (error) => {
          console.error('API error:', error);
        }
      );
  }

  getData(item: any) {
    const functionName = 'getData';
    const messageLog = 'get a job';
    const apiUrl = environment.API_URI + ApiNameConstants.BS_USER_GET_JOB_BY_ID;
    this.commonService
      .postDatas(item.id, apiUrl, functionName, messageLog)
      .subscribe(
        (res: any) => {
          if (res && res.data) {
            this.item = res.data;
          }
        },
        (error) => {
          console.error('API error:', error);
        }
      );
  }
  closePopup() {
    let modal = $('#popup-detail-item .modal');
    modal.removeClass('display');
    modal.addClass('hide');

    $('body').removeClass('no-scroll');
  }

  onValidate() {}
}
