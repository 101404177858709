<div class="center-pos row w-[100%]">
	<div class="flex flex-col gap-10 rounded-xl bg-white md:px-10 md:pt-5 md:py-10">
		<img class="cursor-pointer w-24 back-icon" *ngIf="isDisplayBackBtn" src="../assets/icons/back.svg" alt="back-icon"
			(click)="back()" />
		<div class="flex justify-between items-start flex-wrap p-2 self-stretch rounded-xl"
			style="background: var(--ink-background)">
			<div class="flex items-start gap-7">
				<img src="../../../../assets/img/detail-job.png" alt="" />
				<div class="flex flex-col items-start gap-3">
					<div class="flex flex-col items-start gap-4">
						<span class="font-body font-semibold not-italic text-2xl text-black">{{ item.name }}</span>
						<div class="flex flex-col items-start gap-2">
							<div class="flex items-center gap-3">
								<img src="../../../../assets/icons/building.svg" alt="" />
								<span class="font-body font-normal not-italic text-base text-black first-letter:uppercase">{{ item.name
									}}</span>
							</div>
							<div class="flex items-center gap-3">
								<img src="../../../../assets/icons/time.svg" alt="" />
								<span class="font-body font-normal not-italic text-sm first-letter:uppercase"
									style="color: rgba(0, 0, 0, 0.3)">{{"expirationDateApply" | translate}}: {{ item.expDate |
									date:"yyyy-MM-dd"}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="md:flex-col flex-row md:items-start justify-center w-full md:w-auto gap-3 mt-2 md:mt-0">
				<div *ngIf="!isSavedJobPage() && !isAppliedJobPage()" class="flex flex-col items-center justify-center gap-2 rounded-lg mb-2 "
					style="background: var(--blue-text)">
					<button (click)="onCheckProfileIsExisted()" class="flex justify-center items-center gap-2 px-4 py-2">
						<span class="font-body font-semibold not-italic text-base text-center text-white">{{ "applyJobLbl" |
							translate }}</span>
					</button>
				</div>
				<div *ngIf="!isSavedJobPage() && !isAppliedJobPage()" class="flex flex-col items-center justify-center gap-2 rounded-lg " style="
                background: var(--background-colapse-bar);
                border: 1px solid var(--blue-text);
              ">
					<button class="flex justify-center items-center gap-2" style="padding: 10px 24px 10px 16px"
						(click)="saveJob()">
						<img *ngIf="item.status != SAVED_JOB_STATUS" src="../../../../assets/icons/heart.svg" alt="" />
						<img *ngIf="item.status == SAVED_JOB_STATUS" src="../../../../assets/icons/full_heart.svg" alt="" />
						<span class="font-body font-semibold not-italic text-base text-center" style="color: var(--blue-text)">{{
							"saveMessageLbl" | translate }}</span>
					</button>
				</div>
			</div>
		</div>

		<div class="flex flex-col items-start gap-5">
			<div class="flex items-start self-stretch gap-[60px]" style="border-bottom: 1px solid rgba(0, 0, 0, 0.3)">
				<span (click)="onChangeTab(true, false)" class="font-body font-semibold text-base not-italic cursor-pointer"
					[ngClass]="isShowJobInfo? 'text-black' : 'color-disable-text'">
					{{ "RecruitmentLbl" | translate }}
				</span>
				<span *ngIf="!isDisplayBackBtn" [ngClass]="isShowCompanyInfo? 'text-black' : 'color-disable-text'"
					(click)="onChangeTab(false, true)" class="font-body font-semibold text-base not-italic cursor-pointer">
					{{ "infoCompanyLbl" | translate }}
				</span>
			</div>
			<div class="flex items-start gap-6 w-full flex-wrap">
				<div *ngIf="isShowJobInfo" class="w-full md:w-8/12">
					<app-job-detail-info [item]="item"></app-job-detail-info>
				</div>
				<div *ngIf="isShowCompanyInfo && !isDisplayBackBtn" class="w-full md:w-8/12">
					<app-company-info [item]="item"></app-company-info>
				</div>
				<div class="w-full md:w-3/12 flex flex-col items-start p-5 gap-6 rounded-xl"
					style="background: var(--ink-background)">
					<div class="flex items-start gap-2">
						<img src="../../../../assets/icons/location.svg" alt="" />
						<div class="flex flex-col items-start gap-1">
							<span class="font-body font-normal text-base not-italic color-[var(--base-ink-main-text)]">{{
								"positionLbl" | translate }}</span>
							<span class="font-body font-normal text-base not-italic color-[var(--base-ink-border)]">{{ item.district
								}} {{ item.province }}</span>
						</div>
					</div>
					<div class="flex items-start gap-2">
						<img src="../../../../assets/icons/salary.svg" alt="" />
						<div class="flex flex-col items-start gap-1">
							<span class="font-body font-normal text-base not-italic color-[var(--base-ink-main-text)]">{{ "salaryLbl"
								| translate }}</span>
							<span class="font-body font-normal text-base not-italic color-[var(--base-ink-border)]">{{ item.salary
								}}</span>
						</div>
					</div>
					<div class="flex items-start gap-2">
						<img src="../../../../assets/icons/users.svg" alt="" />
						<div class="flex flex-col items-start gap-1">
							<span class="font-body font-normal text-base not-italic color-[var(--base-ink-main-text)]">{{
								"recruiterNumberLbl" | translate }}</span>
							<span class="font-body font-normal text-base not-italic color-[var(--base-ink-border)]">{{ item.number
								}}</span>
						</div>
					</div>
					<div class="flex items-start gap-2">
						<img src="../../../../assets/icons/briefcase.svg" alt="" />
						<div class="flex flex-col items-start gap-1">
							<span class="font-body font-normal text-base not-italic color-[var(--base-ink-main-text)]">{{
								"experienceLbl" | translate }}</span>
							<span class="font-body font-normal text-base not-italic color-[var(--base-ink-border)]">
								{{ item.requiredExperienceLevel }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div *ngIf="isShowJobFinding">
  <app-popup-job-finding #jobFinding (onClose)="onClosePopupJobFinding()"></app-popup-job-finding>
</div>
<div *ngIf="isShowPopupConfirm">
  <app-popup-confirm-apply-common (validate)="onValidate($event)" (onClose)="onClosePopupConfirm()" #popupConfirmApply></app-popup-confirm-apply-common>
</div>

