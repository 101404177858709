<div class="popup-container" id="popup-calendar-item">
	<div class="modal">
		<div class="modal-inner center-pos row">
			<div
				class="p-3 bg-blue rounded-top d-flex justify-items-start align-items-center position-relative">
				<span class="font-size-big font-weight-bold global-text-color-white">{{noticeLbl}}</span>
				<img type="button" (click)="closePopup()" src="../assets/icons/white-close.svg"
					class="position-absolute top-0 end-0 p-3">
			</div>
			<div class="p-4 d-flex justify-content-center">
				<div class="popup-content w-full">
          <div class="grid grid-cols-3 gap-3">
            <div class="text-base	font-semibold ...">{{"companyLbl" | translate}}</div>
            <div class="text-base	col-span-2 ...">{{item.organizationName}}</div>
            <div class="text-base	font-semibold ...">{{"jobTypeLbl" | translate}}</div>
            <div class="text-base	col-span-2 ...">{{item.defaultJobName}}</div>
            <div class="text-base	font-semibold ...">{{"interviewDate" | translate}}</div>
            <div class="text-base	col-span-2 ...">{{item.startDate}} - {{item.endDate}} </div>
            <div class="text-base	font-semibold ...">{{"jobAddressLBL" | translate}}</div>
            <div class="text-base	col-span-2 ...">{{getAddress()}}</div>
            <!--<div class="text-base	font-semibold ...">{{"companyLbl" | translate}}</div>
            <div class="text-base	col-span-2 ..."></div>-->
          </div>
				</div>
			</div>
			<!--<div class="col-12 py-4 horizontal-center-pos">
				<div class="col-md-6 left-btn-wrapper horizontal-end-pos vertical-center-pos-1">
					<button class="btn
                    border-none
                    font-size-small
                    font-weight-bold
                    color-disable-text
                    bg-disable px-5" (click)="closePopup()">{{cancelLbl}}</button>
				</div>
				<div class="col-md-6 right-btn-wrapper horizontal-start-pos vertical-center-pos-1">
					<button class="btn
                    border-none
                    font-size-small
                    font-weight-bold
                    global-text-color-white
                    bg-blue px-5" (click)="onValidate()">{{yesLbl}}</button>
				</div>
			</div>-->
		</div>
	</div>
</div>
