<div class="selection-wrapper">
	<!-- <select [disabled]="isDisable" class="form-select" (change)="onSelected($event)"
		[ngClass]="isNotPadding? 'px-2': 'p-2'">
		<option *ngIf="placeholder" value="" disabled selected hidden>{{placeholder}}</option>
		<option class="" *ngFor="let item of items" [selected]="isSelected(item)" [value]="item.id">
			{{item.name}}
		</option>
	</select> -->
	<div class="dropdown">
		<input class="form-select selected-form font-body font-semibold text-base text-gray-900  dark:text-white dark:placeholder-gray-400 truncate" 
			[ngClass]="{ 'selected-form': selectedItem }" [(ngModel)]="searchInput" #searchInputField (input)="onInputChange($event)"
		 	(focus)="isShowDropDown=true" (blur)="onInputBlur()"
			placeholder="{{'searchLbl' | translate}}"
		>
		<div *ngIf="selectedItem" class="clear-icon" (click)="clearSearchInput()">
		</div>
		<ul *ngIf="isShowDropDown" class="dropdown-list p-2 font-body font-semibold text-base text-gray-900  dark:text-white dark:placeholder-gray-400" (scroll)="onScroll($event)">
		  <li class="rounded-lg" *ngFor="let item of filteredItems" [ngClass]="{ 'bg-blue-200 font-semibold hover:!bg-blue-200': item.name == selectedItem?.name }" (mousedown)="selectOption(item)">{{item.name}}</li>
		</ul>
	  </div>
</div>