<app-toast #appToast [messageCode]=""></app-toast>
<div class="listjob-wrapper p-3">
	<div *ngIf="!isShowDetail" class="flex flex-col items-start gap-5 mt-2 mr-5">
		<div class="flex-row justify-between items-center py-4 px-3 gap-4 bg-white rounded-xl w-full hidden lg:flex"
			style="box-shadow: 0 5px 6px rgba(0, 89, 255, 0.25);">
			<p class="font-body not-italic font-semibold text-2xl text-black">{{"listJobLbl" | translate }}</p>
			<div class="flex flex-row items-start gap-4">
				<button class="flex flex-col justify-center items-center gap-2" (click)="openPopupConfirm(1)"
					[ngClass]="checkedItems && checkedItems.length > 0 ? 'bg-blue global-text-color-white' : 'bg-disable color-disable-text'">
					<p class="flex items-center text-center font-body not-italic font-semibold text-xs px-4 py-2"
						style="background: rgba(28, 27, 31, 0.12); border-radius: 8px;">{{"deleteLbl" | translate }}</p>
				</button>
				<button (click)="exportFile()"
					[ngClass]="this.checkedItems && this.checkedItems.length > 0 ? 'bg-blue global-text-color-white' : 'bg-disable color-disable-text'"
					class="flex flex-row justify-center items-center gap-2 rounded-lg">
					<p class="flex items-center text-center font-body not-italic font-semibold text-xs px-4 py-2">
						{{"exportExcelLbl" | translate }}</p>
				</button>
			</div>
		</div>

		<div class="flex lg:hidden mt-3 ml-3">
			<span class="font-body not-italic font-semibold text-xl" style="color: #082639;">{{"listJobLbl" | translate
				}}</span>
		</div>

		<div class="flex flex-col items-start container-main w-full">
			<div *ngIf="!isShowDetail" class="w-full">
				<app-table-job class="w-full" #appTable [displayedColumns]="displayedColumns" [dataSource]="dataOrg"
					(onViewItem)="showDetail($event)" (onChecked)="getCheckedItems($event)" (changePage)="changePage($event)"></app-table-job>
			</div>

		</div>
		<div class="lg:hidden flex overflow-x-auto md:h-[5px] md:max-w-[900px]">
		</div>
	</div>
	<div *ngIf="isShowDetail">
		<app-job-detail [isDisplayBackBtn]="true" [itemParam]="item" (onBack)="init()"></app-job-detail>
	</div>
</div>
<app-popup-confirm #popupConfirmDelete [confirmCode]="CONFIRM_DELETE_CODE" (validate)="delete()"></app-popup-confirm>