<!-- payment-failed.component.html -->
<div class="header-up-wrapper row">
  <app-header-up></app-header-up>
</div>
<div class="flex items-center justify-center min-h-screen bg-gray-100">
  <div class="bg-white shadow-lg rounded-lg p-5 mx-auto text-center">
    <img src="../../../../assets/img/cancel.png" class="mt-3 w-full">
    <h1 class="text-3xl font-bold text-red-600 mb-4 mt-4">{{ "transactionFailedLbl" | translate }}</h1>
    <p class="text-gray-700 mb-6">{{ "notificationTransactionFailedLbl"  | translate }}</p>
    
    <div class="flex justify-center">
      <a href="/candidate-home" class="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50">
        {{ "homeLbl" | translate}}
      </a>
      <a href="/candidate/profile" class="bg-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50 ml-4">
        {{ "tryAgainLbl" | translate}}
      </a>
    </div>
    
  </div>
</div>
