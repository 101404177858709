<div class="popup-container" id="popup-confirm">
	<div class="modal-inner center-pos row">
		<div class="modal">
			<div class="py-3 bg-blue rounded-t-md flex justify-between items-center relative">
				<div class="flex pl-2">
					<span class="text-lg font-bold text-white">{{ noticeLbl }}</span>
					<img class="px-2" src="../assets/icons/white-info.svg" alt="Info Icon">
				</div>
				<img type="button" (click)="closePopup()" src="../assets/icons/x-close.svg" alt="Close Icon"
					class="absolute top-0 end-0 p-3 cursor-pointer">
			</div>
			<div class="py-4 flex justify-center">
				<div class="popup-content px-4">
					<span>{{ confirmMessage }}</span>
				</div>
			</div>
			<div class="py-3 flex justify-center px-2">
				<div class="w-1/2 pr-2">
					<button class="btn border-none text-sm font-bold hover:bg-gray-300 bg-gray-200 px-5 w-full"
						(click)="closePopup()">{{ cancelLbl }}</button>
				</div>
				<div class="w-1/2 pl-2">
					<button class="btn border-none text-sm font-bold text-white bg-primary-600 hover:bg-primary-400 px-5 w-full"
						(click)="onValidate()">
						{{ yesLbl }}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>