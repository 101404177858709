<app-toast #appToast [messageCode]=""></app-toast>
<div class="listjob-wrapper">
	<div class="flex flex-col items-start gap-5 mt-2 md:mr-5 mr-2">
		<div class="wrapper flex-row justify-between items-center py-4 px-3 gap-4 bg-white rounded-xl w-full hidden lg:flex"
			style="box-shadow: 0 5px 6px rgba(0, 89, 255, 0.25);">
			<p class="font-body not-italic font-semibold text-2xl text-black">{{"candidateManagementLbl" | translate }}
			</p>

			<div class="flex items-center gap-4">
				<div class="flex items-center justify-center gap-2 rounded-lg">
					<!-- <button class="flex justify-center items-center gap-2 px-4 py-2">
						<img src="../../../../assets/icons/icon-upload.svg" alt="upload-file">
						<span class="items-center font-body not-italic font-normal text-sm underline" style="color: var(--green);">
							{{ 'exportFileLbl' | translate }}
						</span>
					</button> -->
					<button (click)="exportFile()"
						[ngClass]="this.checkedItems && this.checkedItems.length > 0 ? 'bg-blue global-text-color-white' : 'bg-disable color-disable-text'"
						class="flex flex-row justify-center items-center gap-2 rounded-lg">
						<p class="flex items-center text-center font-body not-italic font-semibold text-xs px-4 py-2">
							{{"exportExcelLbl" | translate }}</p>
					</button>
				</div>
				<div class="flex items-start gap-5">
					<select class="flex justify-between items-center flex-1 px-2 py-1 bg-white"
						style="border-radius: 8px 8px 0px 8px; border: 1px solid var(--blue-bluee-200); box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);"
						(change)="onStatusChange($event)">
						<option value="">{{ 'allCandidateLbl' | translate }}</option>
						<option value="1">{{ 'candidateAppliedLbl' | translate }}</option>
						<option value="3">{{ 'candidateAcceptedLbl' | translate }}</option>
						<option value="2">{{ 'candidateSelectedLbl' | translate }}</option>
					</select>
				</div>
			</div>
		</div>

		<div class="flex lg:hidden items-center justify-between md:mt-3 md:mx-3 mx-1 gap-1 w-full">
			<div class="flex items-start gap-1">
				<a href="/home" class="font-body not-italic text-xs font-normal items-center"
					style="color: rgba(0, 0, 0, 0.30);">{{ 'homeLbl' | translate }}</a>
				<span class="font-body not-italic text-xs font-normal items-center">/</span>
				<a href="/recruiter" class="font-body not-italic text-xs font-normal items-center"
					style="color: rgba(0, 0, 0, 0.70);">{{ 'candidateManagementLbl' | translate }}</a>
			</div>

			<div class="flex flex-col items-start gap-2 sm:flex-row sm:gap-2">
				<select class="w-36 sm:w-auto px-2 py-1 bg-white border border-blue-200 rounded-lg shadow-sm text-sm"
				style="border-radius: 8px 8px 0px 8px; border: 1px solid var(--blue-bluee-200); box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);"
					(change)="onStatusChange($event)">
					<option value="">{{ 'allCandidateLbl' | translate }}</option>
					<option value="1">{{ 'candidateAppliedLbl' | translate }}</option>
					<option value="3">{{ 'candidateAcceptedLbl' | translate }}</option>
					<option value="2">{{ 'candidateSelectedLbl' | translate }}</option>
				</select>
			</div>
		</div>

		<div class="flex flex-col items-start rounded-xl w-full sm:-mt-10 -mt-7"
			style="border: 1px solid var(--background-colapse-bar);">
			<app-table-candidate class="w-full" #appTableCandidate [filterStatus]="filterStatus"
				(changePage)="changePage($event)" [displayedColumns]="displayedColumns" [dataSource]="dataOrg"
				(onChecked)="getCheckedItems($event)"></app-table-candidate>
		</div>
		
	</div>
</div>
<app-loading></app-loading>