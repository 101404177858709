<app-toast #appToast [messageCode]="messageCode"></app-toast>
<div class="user-detail-wrapper md:pb-5">
	<form action="" [formGroup]="detailJobForm">
		<div class="md:mt-5 px-2">
			<img *ngIf="isAllowChangeJobDefault" class="cursor-pointer w-7 back-icon mb-3" src="../assets/icons/back.svg"
				alt="back-icon" (click)="onBack()" />
      <div class="row col-xs-12 mb-3">
        <div class="col-xs-12 col-md-12 px-2">
          <div class="form-label">
						<span class="color-ink-text font-size-small font-weight-bold">
							{{"titleJobLbl" | translate}}
						</span>
            <input [(ngModel)]="item.name"
                   [ngClass]="name.invalid? 'inValid-border': 'valid-border'"
                   class="form-control p-2" type="text" formControlName="name" />
          </div>
        </div>
      </div>
			<div *ngIf="isAllowChangeJobDefault" class="row col-xs-12 mb-3">
				<div class="col-xs-12 col-md-12 px-2">
					<div class="form-label">
						<span class="color-ink-text font-size-small font-weight-bold">
							{{"jobLbl" | translate}}
						</span>
					</div>
					<div class="row">
						<!-- <app-selection-menu [isDisable]="!isEditMode" [selectedItem]="selectedJobDefault" [items]="jobDefaultId"
							(onChangeSelected)="onJobDefaultSelected($event)"></app-selection-menu> -->
						<app-selection [isDisable]="!isEditMode" [selectedItem]="selectedJobDefault" [items]="jobDefaultId" [pageMenu]="pageMenu"
                         (onChangeSelected)="onJobDefaultSelected($event)" (refetchScrollMenu)="refetchScrollMenu($event)"></app-selection>
					</div>
				</div>
			</div>
			<div class="row col-xs-12 mb-3">
				<div class="col-xs-12 col-md-6 px-2">
					<div class="form-label">
						<span class="color-ink-text font-size-small font-weight-bold">
							{{"phoneNumberLbl" | translate}}
						</span>
					</div>
					<input [(ngModel)]="item.phone"
                 [ngClass]="phone.invalid? 'inValid-border': 'valid-border'"
                 class="form-control p-2" type="text" formControlName="phone" />
				</div>
				<div class="col-xs-12 col-md-6 px-2">
					<div class="form-label">
						<span class="color-ink-text font-size-small font-weight-bold">
							{{'workingTypeLbl' | translate}}
						</span>
					</div>
					<div class="row">
						<app-selection-menu [isDisable]="!isEditMode" [selectedItem]="selectedWorkingType" [items]="workingTypes"
							(onChangeSelected)="onWorkingTypeSelected($event)">
						</app-selection-menu>
					</div>
				</div>
			</div>
			<div class="row col-xs-12 mb-3">
				<div class="col-xs-12 col-md-6 px-2">
					<div class="form-label">
						<span class="color-ink-text font-size-small font-weight-bold">
							{{'salaryLbl' | translate }} ({{'currency' | translate}})
						</span>
					</div>
					<input [(ngModel)]="item.salary" [ngClass]="salary.invalid? 'inValid-border': 'valid-border'"
						class="form-control p-2" type="text" formControlName="salary" />
				</div>
        <div class="col-xs-12 col-md-6 px-2">
          <div class="form-label">
						<span class="color-ink-text font-size-small font-weight-bold">
							{{"recruiterNumberLbl" | translate}}
						</span>
          </div>
          <input [(ngModel)]="item.number" [ngClass]="number.invalid? 'inValid-border': 'valid-border'" class="form-control p-2" type="text" formControlName="number" />
        </div>
			</div>
			<div class="row col-xs-12 mb-3 px-2">
				<div class="form-label"><span class="color-ink-text font-size-small font-weight-bold">{{desJobLbl}}</span></div>
				<textarea rows="3" class="form-control p-3" formControlName="des" [(ngModel)]="item.des"></textarea>
			</div>
			<div class="row col-xs-12 mb-3 address-wrapper">
				<div class="col-xs-12 col-md-4 px-2">
					<div class="form-label"><span class="color-ink-text font-size-small font-weight-bold">{{provinceLbl}}</span>
					</div>
					<app-selection-menu [isDisable]="!isEditMode" [selectedItem]="selectedProvince" [items]="provinces"
						(onChangeSelected)="onProvinceSelected($event)"></app-selection-menu>
				</div>
				<div class="col-xs-12 col-md-4 px-2">
					<div class="form-label"><span class="color-ink-text font-size-small font-weight-bold">{{districtLbl}}</span>
					</div>
					<app-selection-menu [isDisable]="!isEditMode" [selectedItem]="selectedDistrict" [items]="districts"
						(onChangeSelected)="onDistrictSelected($event)"></app-selection-menu>
				</div>
				<div class="col-xs-12 col-md-4 px-2">
					<div class="form-label"><span class="color-ink-text font-size-small font-weight-bold">{{wardLbl}}</span></div>
					<app-selection-menu [isDisable]="!isEditMode" [selectedItem]="selectedWard" [items]="wards"
						(onChangeSelected)="onWardSelected($event)"></app-selection-menu>
				</div>
			</div>
			<div class="col-xs-12 mb-3 px-2">
				<div class="form-label"><span class="color-ink-text font-size-small font-weight-bold">{{"benefitLbl" |
						translate}}</span></div>
				<textarea rows="3" class="form-control p-3" [(ngModel)]="item.profit" formControlName="profit"></textarea>
			</div>
			<div class="col-xs-12 mb-3 px-2">
				<div class="form-label"><span
						class="color-ink-text font-size-small font-weight-bold">{{"candidateRequirementsLbl" | translate}}</span>
				</div>
				<textarea rows="3" class="form-control p-3" [(ngModel)]="item.requiredSkill"
					formControlName="requiredSkill"></textarea>
			</div>
			<div class="col-xs-12 mb-3 px-2">
				<div class="form-label"><span class="color-ink-text font-size-small font-weight-bold">{{"jobAddressLBL" |
						translate}}</span></div>
				<textarea rows="3" class="form-control p-3" [(ngModel)]="item.address"
					[ngClass]="address.invalid? 'inValid-border': 'valid-border'" formControlName="address"></textarea>
			</div>
			<div class="row col-xs-12 mb-3">
				<div class="col-xs-12 col-md-6 px-2">
					<div class="form-label"><span
							class="color-ink-text font-size-small font-weight-bold">{{creationDateLbl}}</span></div>
					<input [(ngModel)]="item.creationDate" [readonly]="true" formControlName="creationDate" (mousedown)="$event.preventDefault()"
						[ngClass]="creationDate.invalid? 'inValid-border': 'valid-border'" class="form-control bg-gray-200 text-gray-600" type="date">
				</div>
				<div class="col-xs-12 col-md-6 px-2">
					<div class="form-label"><span
							class="color-ink-text font-size-small font-weight-bold">{{expirationDateLbl}}</span></div>
					<input [(ngModel)]="item.expDate" formControlName="expDate"
						[ngClass]="expDate.invalid? 'inValid-border': 'valid-border'" class="form-control" type="date">
				</div>
			</div>
			<div class="row col-xs-12 mb-3"></div>
		</div>
		<div>
			<div class="flex justify-end items-center gap-2 self-stretch rounded-lg px-3 py-2">
				<button (click)="onDelete()"
					class="rounded bg-gray-400 flex px-4 py-2 justify-center items-center gap-2 text-white">{{"deleteLbl" |
					translate}}</button>
				<button [disabled]="detailJobForm.invalid" [ngClass]="detailJobForm.invalid? 'btn-disable' : 'active-btn-save'"
					class="flex px-4 py-2 justify-center items-center gap-2" (click)="onSave(false)">
					<span class="font-body not-italic font-semibold text-xs items-center text-white">{{
						'saveMessageLbl' | translate }}</span>
				</button>
			</div>
		</div>
	</form>
</div>
