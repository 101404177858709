<div class="flex justify-center p-3 w-full md:w-auto">
	<div class="flex justify-center items-center gap-2 sm:gap-10 py-5 rounded-xl bg-white w-[100%]  md:w-3/4"
		style="box-shadow: 0px 6px 15px 0px rgba(228, 118, 27, 0.25);">
		<div class="flex content-center" appPrev>
			<img class="cursor-pointer" src="../assets/icons/previous-icon.svg" alt="" />
		</div>
		<div class="parent inline-flex gap-2 overflow-hidden">
			<div (click)="gotoOtherPage(item)"
				class="item flex flex-col cursor-pointer items-center px-1 sm:px-6 sm:py-3 py-1 sm:gap-2 gap-1  rounded-md w-[100px] bg-white"
				style="border: 1px solid var(--orange-orange-300);" *ngFor="let item of jobDefaults">
				<div class="flex justify-center items-center gap-3 p-2 rounded-full h-20 w-full"
					style="background: var(--light-pink);">
					<img class="" src="{{ item.icon }}" alt="{{item.name}}" />
				</div>
				<span class="font-body font-semibold text-base text-center not-italic" style="color: var(--orange);">{{
					item.name
					}}</span>
			</div>
		</div>
		<div class="flex content-center" appNext>
			<img class="cursor-pointer" src="../assets/icons/next-icon.svg" alt="" />
		</div>
	</div>
</div>
<!--<section class="product">
  <div class="container-fluid">
    <div class="slider-wrap">
      <div class="slider-main">
        <div class="item">
          <div class="text">1</div>
        </div>
        <div class="item">
          <div class="text">2</div>
        </div>
        <div class="item">
          <div class="text">3</div>
        </div>
        <div class="item">
          <div class="text">4</div>
        </div>
        <div class="item">
          <div class="text">5</div>
        </div>
      </div>
      <div class="button-area">
        <button type="button" class="prev" appPrev>Prev</button>
        <button type="button" class="next" appNext>Next</button>
      </div>
    </div>
  </div>
</section>-->
