<div class="popup-container popup-upgrade-account padding ">
	<div class="modal display ">
		<div class="modal-inner center-pos row">
			<div class="col-12  bg-blue rounded-top flex justify-start items-center position-relative h-10 pl-2">
				<span class=" font-weight-bold global-text-color-white">{{"choosePackage" | translate}}</span>
				<img type="button" (click)="closePopup()" src="../assets/icons/white-cancel.svg"
					class="position-absolute top-0 end-0 p-3">
			</div>
			<div class="px-4 py-4">
				<div class="mt-3 flex gap-3 justify-center flex-wrap md:flex-nowrap  border-primary-500">
					<div
						[class]="selectedPackage?.id === item.id?'  bg-orange-100 flex flex-column items-center px-2 py-4 rounded-md border-2 border-orange-500 shadow-md' : '  bg-primary-100 flex flex-column items-center px-2 py-4 rounded-md'"
						*ngFor="let item of packages" (click)="onSelect(item)">
						<div>
							<img class="" src="../assets/icons/pin.svg" alt="check-circle">
						</div>
						<div class="font-bold mt-3 mb-3 text-[20px]">
							<span>{{item.type}} {{"monthLbl" | translate}}</span>
						</div>
						<div class="text-green-500 font-bold text-[30px]">
							<span>{{item.price | number:'1.0-0'}}VND</span>
						</div>
					</div>
				</div>
				<div class="flex items-center justify-center  gap-5 mt-5">
					<button (click)="onValidate()" class="bg-blue-500  w-24 py-1 rounded text-white">{{"upgradeLbl" |
						translate}}</button>
				</div>
			</div>
		</div>
	</div>
</div>
