<div class="toast-wrapper row d-flex justify-content-end
  position-absolute right-15">
	<div [ngClass]="isShow? 'show': ''" class="toast align-items-center position-relative border-none" role="alert"
		aria-live="assertive" aria-atomic="true">
		<div class="d-flex py-4" [ngClass]="bgClass? bgClass : ''">
			<img class="pl-3" src="{{imgLink}}" alt="toast-icon">
			<div class="toast-body color-ink-text global-text">
				{{message | translate}}
			</div>
			<button type="button" class="btn-close me-2 m-auto position-absolute top-0 end-0 p-3"
				data-bs-dismiss="toast" aria-label="Close"></button>
		</div>
	</div>
</div>
