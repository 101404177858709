<div class="">
  <div class="table-wrapper">
    <table
      id="dtOrderExample"
      class="table table-striped table-bordered table-sm dataTable table-body-wrapper"
      cellspacing="0"
      width="100%"
    >
      <thead>
      <tr>
        <th
          class="heading-table"
        >
          <span>{{ sequenceIndexLbl }}</span>
        </th>
        <th
          class="heading-table"
          *ngFor="let col of displayedColumns"
          ngClass="{{ col.key }}"
        >
          <span>{{ col.name }}</span>
        </th>
        <th
          class="heading-table"
        >
          <span>{{ "Action" }}</span>
        </th>
        <th class="md:px-2 px-1">
          <div class="d-flex justify-content-center">
            <input type="checkbox" class="form-check-input form-check-input-all" id="" (click)="onCheckAll($event)" />
          </div>
        </th>
      </tr>
      </thead>
      <tbody class="">
      <tr class="body-table" *ngFor="let rowData of dataTable; index as i">
        <td class="md:px-2 px-1">
          <span>{{i + 1}}</span>
        </td>
        <td class="md:px-2 px-1" *ngFor="let col of displayedColumns">
          <div>
            <span>{{rowData[col.key]?rowData[col.key]:"N/A"}}</span>
          </div>
        </td>
        <td class="md:px-2 px-1">
          <button [disabled]="!rowData.cv" [ngClass]="{'bg-disable !border-none': !rowData.cv}" (click)="onDownloadCv(rowData)" style="border: 1px solid #5B91FB;"
                  class="rounded  w-auto flex justify-center items-center gap-2 py-1 px-2 ">
            <p [ngClass]="{'!text-[#9a9ba0] no-underline': !rowData.cv}" class="flex items-center text-center font-body not-italic font-semibold text-xs underline"
               style="color: #3276FA;">{{"uploadCV" | translate}}</p>
          </button>
        </td>
        <td class="md:px-2 px-1">
          <div class="d-flex justify-content-center">
            <input type="checkbox" class="checkbox-item form-check-input"
                   (click)="onCheckBox($event, rowData);$event.stopPropagation()" />
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="row paginator-wrapper">
    <div class="pagination d-flex justify-content-end">
      <a href="#" (click)="onPrePageClick()">&laquo;</a>
      <li
        class="page-item"
        *ngFor="let i of arrPage"
        (click)="onCurrentPageClick(i)"
      >
        <a class="page-link" *ngIf="isDisplayPageNumber(i)">{{ i }}</a>
      </li>
      <a href="#" (click)="onNextPageClick()">&raquo;</a>
    </div>
  </div>
</div>
