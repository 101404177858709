import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Constants } from 'src/app/constant/Constants';
import { ConstantsApp } from 'src/app/constant/ConstantsApp';
import { DropdownComponent } from 'src/app/pages/form/dropdown-component/dropdown.component';
import { LocalStorageService } from 'src/app/core/auth/local-storage.service';
import { UtilsService } from 'src/app/helper/service/utils.service';
import {
  TranslateService,
} from '@ngx-translate/core';
import { RouterLinkName } from '../../../constant/RouterLinkName';

@Component({
  selector: 'app-header-up',
  templateUrl: './header-up.component.html',
  styleUrls: ['./header-up.component.scss'],
})
export class HeaderUpComponent implements OnInit {
  @ViewChild('dropdown') dropdown: DropdownComponent = new DropdownComponent();
  @Input() pageName: any;
  @Input() searchingSuggestionData: any;
  @Output() selectRole = new EventEmitter<void>();
  @Output() activeLink: EventEmitter<boolean> = new EventEmitter<boolean>();

  title = 'Jober';
  profileLbl = Constants.profileLbl;
  logoutLbl = Constants.logoutLbl;
  changePassLbl = Constants.changePassLbl;
  recruitLbl = Constants.recruitLbl;
  candidateLbl = Constants.candidateLbl;
  isLoginPage: any;
  isCandidatePage: any;
  isHomePage: any;
  isManagementPage: any;
  isPolicyPage: any;
  isListPage: any;
  authenticated: any;
  isDropDown: any;
  isDropDownLanguage: any;
	isDropDownUser: any;
  isNavbar: any;
  dropdownItems?: {};
  role: any;
  VI: string = ConstantsApp.VI;
  EN: string = ConstantsApp.EN;
  MAP_PAGE: string = ConstantsApp.mapPage;
  languageList = [
    { code: ConstantsApp.VI, label: 'English' },
    { code: ConstantsApp.EN, label: 'Vietnam' },
  ];
  candidateRole = ConstantsApp.CANDIDATE;
  recruiterRole = ConstantsApp.RECRUITER;
	currentLanguage = this.localStorageService.getItem(ConstantsApp.language);
  constructor(
    private router: Router,
    private authService: AuthService,
    private utilsService: UtilsService,
    private translate: TranslateService,
    private localStorageService: LocalStorageService
  ) {
    this.dropdownItems = [
      { val: this.profileLbl, id: '1', class_icon: 'fa-solid fa-user' },
      { val: this.changePassLbl, id: '2', class_icon: 'fa-solid fa-lock' },
      {
        val: this.logoutLbl,
        id: '3',
        class_icon: 'fa-solid fa-arrow-right-from-bracket',
      },
    ];
  }
  ngOnInit() {
    this.currentLanguage = this.currentLanguage? this.currentLanguage : this.localStorageService.getItem(ConstantsApp.language)? this.localStorageService.getItem(ConstantsApp.language) :  ConstantsApp.VI;
		this.translate.use(this.currentLanguage)
    this.authenticated = this.authService.authenticated();
    if (!this.authenticated && !this.localStorageService.getItem(ConstantsApp.role)) {
      this.localStorageService.setItem(ConstantsApp.role, this.candidateRole);
    }
    this.role = this.localStorageService.getItem(ConstantsApp.role);
    this.checkUI();
  }
  isMapPage() {
    return this.router.url && (this.router.url).includes(ConstantsApp.mapPage);
  }
  isNotHomePage() {
    return !(this.router.url && ((this.router.url).includes(ConstantsApp.LINK_CANDIDATE_HOME) || (this.router.url).includes(ConstantsApp.LINK_RECRUITER_HOME)));
  }
  onActiveLink(item: any, event: any, idx: any) {
    this.activeLink.emit(item);
    console.log(this.activeLink.emit(item));
    this.cssActiveLink(idx);
  }
  cssActiveLink(idx: any) {
    let linkElements = document.querySelectorAll('.link .content');
    for (let i = 0; i < linkElements.length; i++) {
      let classList = linkElements[i].classList;
      if (i != idx) {
        classList.remove('active');
      } else {
        classList.add('active');
      }
    }
  }
  checkUI() {
    this.isDropDown = false;
		this.isDropDownUser = false;
    this.isDropDownLanguage = false;
    this.isNavbar = false;
    switch (this.pageName) {
      case ConstantsApp.candidateInfoPage:
        this.isCandidatePage = true;
        this.isLoginPage = false;
        this.isHomePage = false;
        this.isManagementPage = false;
        this.isPolicyPage = false;
        this.isListPage = false;
        break;
      case ConstantsApp.loginPage:
        this.isCandidatePage = false;
        this.isLoginPage = true;
        this.isHomePage = false;
        this.isManagementPage = false;
        this.isPolicyPage = false;
        this.isListPage = false;
        break;
      case ConstantsApp.homePage:
        this.isCandidatePage = false;
        this.isLoginPage = false;
        this.isHomePage = true;
        this.isManagementPage = false;
        this.isPolicyPage = false;
        this.isListPage = false;
        break;
      case ConstantsApp.managementPage:
        this.isCandidatePage = false;
        this.isLoginPage = false;
        this.isHomePage = false;
        this.isManagementPage = true;
        this.isPolicyPage = false;
        this.isListPage = false;
        break;
      case ConstantsApp.policyPage:
        this.isCandidatePage = false;
        this.isLoginPage = false;
        this.isHomePage = false;
        this.isManagementPage = false;
        this.isPolicyPage = true;
        this.isListPage = false;
        break;
      case ConstantsApp.listPage:
        this.isCandidatePage = false;
        this.isLoginPage = false;
        this.isHomePage = false;
        this.isManagementPage = false;
        this.isPolicyPage = false;
        this.isListPage = true;
        break;
      default:
        this.isCandidatePage = false;
        this.isLoginPage = false;
        this.isManagementPage = false;
        this.isHomePage = true;
        break;
    }
  }

  onOpenNavbar() {
    this.isNavbar = !this.isNavbar;
  }
  onDropdown() {
    this.isDropDown = !this.isDropDown;
    this.isDropDownLanguage = false;
  }
	handleClickOutsideProfile() {
		this.isDropDownUser = false

  }
	handleClickOutsideLanguage() {
		this.isDropDownLanguage = false
  }
	handleClickOutsideCandidate() {
    this.isDropDown = false;
  }

	onDropdownUser() {
		this.isDropDownUser = !this.isDropDownUser
    this.isDropDownLanguage = false
    this.isDropDown = false;
  }
  onDropdownlangue() {
    this.isDropDownLanguage = !this.isDropDownLanguage;
    this.isDropDown = false;
  }
  redirectToLogin() {
    this.router.navigate(['/app-login']);
  }
  redirectToProfile() {
		if(this.role ===ConstantsApp.CANDIDATE){
			this.router.navigate(['/candidate/profile']);
		}else{
			this.router.navigate(['/recruiter/profile']);
		}

  }

  onChangeSelected(item: any) {
    // console.log(item);
    const id = item.id;
    switch (id) {
      // view profile
      case '1':
        this.redirectToProfile();
        break;
      // change pass
      case '2':
        this.redirectToLogin();
        break;
      // logout
      case '3':
        this.onSignOut();
        break;
    }
  }

  onSignOut() {
    this.authService.logout().subscribe((res) => {
      if (res.code == ConstantsApp.SUCCESS_CODE) {
        this.router.navigate(['/app-login']);
        this.authenticated = this.authService.authenticated();
      }
    }, err => {
      if (err.status == ConstantsApp.UNAUTHORIZED_STATUS) {
        this.router.navigate(['/app-login']);
        this.authService.signOut();
        this.authenticated = this.authService.authenticated();
        console.log("onSignOut authenticated err", this.authenticated, err);
      }
    });
  }

  onChangeRole(role: any) {
    this.role = role;
    console.log('onChangeRole', this.role);
    this.localStorageService.setItem(ConstantsApp.role, role);
    this.localStorageService.setItem(ConstantsApp.language, ConstantsApp.VI);
    this.isDropDown = false
    if (this.isMapPage()) {
      this.selectRole.emit(role);
      return;
    }
    if (role == this.candidateRole) {
      this.router.navigate(['/' + RouterLinkName.CANDIDATE_HOME]);
    } else {
      this.router.navigate(['/' + RouterLinkName.RECRUITER_HOME]);
    }
  }

  changeSiteLanguage(localeCode: string): void {
    const selectedLanguage = this.languageList
      .find((language) => language.code === localeCode)
      ?.label.toString();

    if (selectedLanguage) {
      this.translate.use(localeCode).subscribe(res=>{
        this.currentLanguage = this.translate.currentLang;
		    this.localStorageService.setItem(ConstantsApp.language,this.translate.currentLang);
      });
    }


  }
  scroll(event:Event) {
    event.preventDefault();
    const appItemsListElement = document.getElementById('appItemList');
    appItemsListElement?.scrollIntoView({behavior:'smooth'});
}
}
