<div class="popup-container" id="popup-info-candidate">
  <div class="modal">
    <!-- <div class="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] md:w-[70%] w-full max-h-[90vh] inline-flex flex-col gap-3 rounded-lg"
      *ngIf="methodInterview === 0"> -->
      <div class="modal-inner center-pos row">
      <div class="inline-flex flex-col items-end gap-3 rounded-xl bg-white">
        <div class="flex justify-end items-center px-3 py-2 gap-5 self-stretch"
             style="background: var(--blue-text); border: 8px 8px 0px 0px;">
          <div class="flex items-center gap-28 flex-1">
            <div class="flex items-center gap-5">
							<span class="font-body text-xl font-semibold not-italic" style="color: var(--white);">{{
                "infoCandidateLbl" | translate }}</span>
            </div>
          </div>
          <button (click)="closePopup()"><img src="../../../../../assets/icons/close.svg" alt="icon-close">
          </button>
        </div>

        <div *ngIf="isOpen">
<!--          <app-profile-candidate [jobDefaultId]="candidate.jobDefaultId"  [parentPageCode]="parentPageCode" [status]="candidate.status" [isEditMode]="false" [freelancerId]="candidate.freelancerId? candidate.freelancerId : candidate.id" (onInterview)="onInterview(1)" isEditMode="false" [onlyDisplayInfo]='true'></app-profile-candidate>-->
          <app-candidate-detail-info #appCandidateDetailInfo [jobDefaultId]="candidate.jobDefaultId"
                                     [parentPageCode]="parentPageCode"
                                     [status]="candidate.status"
                                     [isEditMode]="false"
                                     [freelancerId]="candidate.freelancerId? candidate.freelancerId : candidate.id"
                                     (onInterview)="onInterview(1)"
                                     isEditMode="false">
          </app-candidate-detail-info>
        </div>
      </div>
    </div>
  </div>
</div>
<app-popup-booking-interview [candidate]="candidate" #popupBookingInterview></app-popup-booking-interview>
