<div class="management-container">
	<div class="header-up-wrapper row">
		<app-header-up></app-header-up>
	</div>
	<div class="management-container-main mx-auto pl-2 pr-4" style="background-color: var(--background-admin);">
		<div class="management-container-left">
			<app-sidebar-recruiter [isExpanded]="sidebarExpanded" (toggleSidebar)="sidebarExpanded = !sidebarExpanded"
				(activeLink)="onActiveLink($event)" #appSideBar></app-sidebar-recruiter>
		</div>
		<div class="management-container-right">
			<div *ngIf="routerLinkNames.candidateManagement">
				<app-candidate-management-recruiter isEditMode="true"></app-candidate-management-recruiter>
			</div>
      <div *ngIf="routerLinkNames.recruitManagement">
        <app-recruiter-management  isEditMode="true"></app-recruiter-management>
      </div>
      <div *ngIf="routerLinkNames.referralProgram">
        <app-referral-program-candidate  isEditMode="true"></app-referral-program-candidate>
      </div>
      <div *ngIf="routerLinkNames.joberWallet">
        <app-joberwallet-candidate  isEditMode="true"></app-joberwallet-candidate>
      </div>
      <div *ngIf="routerLinkNames.myProfile">
        <app-profile-candidate (uploadAvatar)="uploadAvatar($event)" isEditMode="true"></app-profile-candidate>
      </div>
      <div *ngIf="routerLinkNames.calender">
        <app-calendar  isEditMode="true"></app-calendar>
      </div>
		</div>
	</div>
</div>
<app-footer></app-footer>
<app-loading></app-loading>
