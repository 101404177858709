import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { PopupAddUserComponent } from '../../../layout/popup/popupAddUser/popup-add-user.component';
import { MapService } from '../../../service/map.service';
import { UtilsService } from '../../../helper/service/utils.service';
import { CommonService } from '../../../service/common.service';
import { ConstantsApp } from '../../../constant/ConstantsApp';
import { ApiModel } from '../../../model/ApiModel';
import { PopupSearchComponent } from '../../home/popup-search/popup-search.component';
import { environment } from '../../../../environments/environment';
import { ApiNameConstants } from '../../../constant/ApiNameConstants';
import { AuthService } from '../../../core/auth/auth.service';
import { LocalStorageService } from '../../../core/auth/local-storage.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: './home-recruiter.component.html',
  styleUrls: ['../../home/home-candidate.component.scss'],
})
export class HomeRecruiterComponent implements OnInit, AfterContentInit {
  @ViewChild('popupEditUser') popupEditUser: PopupAddUserComponent =
    new PopupAddUserComponent();
  @ViewChild('popupSearch') popupSearch: PopupSearchComponent =
    new PopupSearchComponent(
      this.commonService,
      this.mapService,
      this.utilsService
    );
  dataSource: any;
  dataJobChildren: any;
  itemsList: any;
  pageNum: any;
  pageMenu: any;
  detailedItem: any;
  center: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
  bodyGetData: any;
  bodyJobChildren: any;
  apiModels: ApiModel[];
  jobDefaultSource: any;
  searchForm: any;
  selectedJob: any;
  jobsList: any;
  selectedProvince: any;
  provinces: any;
  address: any;
  constructor(
    private mapService: MapService,
    private utilsService: UtilsService,
    private commonService: CommonService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private localStorageService: LocalStorageService
  ) {}
  ngOnInit() {
    let body = {
      ids: null,
      parentIds: null,
      coordinates: {
        lat: ConstantsApp.LAT_DEFAULT,
        lng: ConstantsApp.LNG_DEFAULT,
      },
      keySearch: null,
    };
    this.searchForm = new FormGroup({
      keySearch: new FormControl(''),
    });
    if (this.utilsService.getItem(ConstantsApp.address)) {
      this.address = this.utilsService.getItem(ConstantsApp.address);
    } else {
      this.address = {
        province: 'Hà Nội',
        lat: ConstantsApp.LAT_DEFAULT,
        lng: ConstantsApp.LNG_DEFAULT,
        addressDetail: null,
      };
    }
    this.mapService
    .getPosition()
    .then((pos) => {
        this.center = { lat: pos.lat, lng: pos.lng };
        body.coordinates.lat = this.center.lat;
        body.coordinates.lng = this.center.lng;
     })
    .catch((err) => {
      console.log('getCurrentLocation -> err: ', err);
     })
    .finally(() => { 
        this.retrieveAdress();
        this.init(body);
    });
  }
  get keySearch(): any {
    return this.searchForm.get('keySearch');
  }
  init(body: any) {
    if (!this.authService.authenticated()) {
      this.localStorageService.clearAll();
      this.localStorageService.setItem(ConstantsApp.role, ConstantsApp.RECRUITER);
    }
    this.pageNum = 1;
    this.pageMenu = 1;
    this.buildBodyGetData(body);
    this.getCurrentLocation();
  }

  retrieveAdress() {
    try {
      this.commonService.getAllProvince().subscribe((res) => {
        this.provinces = res.data;
        let provinceCode = this.selectedProvinces(this.provinces, this.center);
        this.onProvinceSelected(provinceCode);
        let provinceName = this.address.province;
        this.selectedProvince = this.utilsService.getAddressByName(
          this.provinces,
          provinceName
        );
      });
    } catch (e) {
      console.log(e);
    }
  }

  ngAfterContentInit() {
    // this.openPopupEditUser();
  }
  getCurrentLocation() {
    try {
      this.mapService
        .getPosition()
        .then((pos) => {
          this.center = { lat: pos.lat, lng: pos.lng };
          this.getData(this.bodyGetData);
        })
        .catch((err) => {
          this.getData(this.bodyGetData);
          console.log('getCurrentLocation -> err: ', err);
        });
    } catch (e) {
      this.getData(this.bodyGetData);
    }
  }
  buildBodyGetData(body: any) {
    this.bodyGetData = {
      jobDefaultIds: body != null ? body.ids : this.bodyGetData.jobDefaultIds,
      // keySearch: body != null? body.keySearch : null,
      keySearch: this.keySearch?.value || null,
      // coordinates: {
      //   lat: ConstantsApp.LAT_DEFAULT,
      //   lng: ConstantsApp.LNG_DEFAULT,
      // },
      coordinates: body.coordinates,
      paging: {
        page: this.pageNum,
        size: 12,
      },
      sort: {
        prop: ConstantsApp.ID,
        type: ConstantsApp.DESC,
      },
    };
    console.log(this.bodyGetData);
  }
  getData(body: any) {
    let bodyGetJobDefault = {
      levels: [0],
      paging: {
        page: 1,
        size: 10,
      },
    };
    this.bodyJobChildren = {
      ids: null,
      parentIds: null,
      keySearch: null,
      paging: {
        page: this.pageMenu,
        size: 10,
      },
      sort: {
        prop: ConstantsApp.ID,
        type: ConstantsApp.DESC,
      },
    };
    if (this.bodyJobChildren.ids == null) delete this.bodyJobChildren.ids;
    if (this.utilsService.isEmpty(this.bodyJobChildren.parentIds)) delete this.bodyJobChildren.parentIds;
    if (this.bodyJobChildren.keySearch == null) delete this.bodyJobChildren.keySearch;
    this.apiModels = [
      new ApiModel(
        'get list candidate',
        environment.API_URI + ApiNameConstants.BS_FREELANCER_SEARCH,
        body,
        ConstantsApp.POST
      ),
    ];
    if (this.pageNum <= 1) {
      this.apiModels.push(
        new ApiModel(
          'get list job default',
          environment.API_URI + ApiNameConstants.BS_JOB_DEFAULT_SEARCH,
          bodyGetJobDefault,
          ConstantsApp.POST
        ),
        new ApiModel(
          'get list job children',
          environment.API_URI + ApiNameConstants.BS_JOB_CHILDREN_SEARCH,
          this.bodyJobChildren,
          ConstantsApp.POST
        ),
      );
    }
    this.commonService.retrieveData(this.apiModels).subscribe(
      (res) => {
        if (res && res[0] && res[0].data) {
          this.itemsList = res[0].data;
          this.dataSource = res[0];
        } else {
          this.itemsList = [];
          this.dataSource = null;
        }
        if (res && res[1] && res[1].data) {
          this.jobDefaultSource = res[1];
        }
        if(res && res[2] && res[2].data) {
          this.jobsList = res[2].data;
          this.dataJobChildren = res[2];
        } else {
          this.jobsList = [];
          this.dataJobChildren = null;
        }
        this.cdr.detectChanges();
      },
      (error) => {
        this.dataSource = null;
        this.itemsList = [];
        this.jobsList = [];
        this.dataJobChildren = null;
        console.error('API error:', error);
      }
    );
  }

  changePage(currentPage: any) {
    this.pageNum = currentPage;
    this.buildBodyGetData(this.bodyGetData);
    this.getData(this.bodyGetData);
  }
  showDetailItem(item: any) {
    this.detailedItem = item;
  }
  // openPopupSearch() {
  //   // this.popupSearch.openPopup();
  // }
  onSearchJob(body: any) {
    this.init(body);
    this.popupSearch.closePopup();
  }
  openPopupEditUser() {
    this.popupEditUser.openPopup();
  }

  addUserSuccess() {
    //   show toast message
  }
  onSearchByKey() {
    this.pageNum = 1;
    this.buildBodyGetData(null);
    this.getData(this.bodyGetData);
  }

  onValidate() {
    let addressStr =
    this.address.province;
  let searchCondition = {
    ids: this.selectedJob != null ? [this.selectedJob.id] : null,
    jobDefaultIds: this.selectedJob != null ? [this.selectedJob.id] : null,
    keySearch: null,
    coordinates: {
      lat: this.address.lat,
      lng: this.address.lng,
    },
    paging: {
      page: 1,
      size: 100,
    },
    sort: {
      prop: ConstantsApp.ID,
      type: ConstantsApp.DESC,
    },
  };
  console.log(searchCondition);
  this.commonService.geocodeAddress(addressStr).subscribe(
    (res) => {
      this.onSearchJob(searchCondition);
    },
    (error) => {
      this.onSearchJob(searchCondition);
      console.error('API error:', error);
    }
  );
  }

  onSelectJob(item: any) {
    this.bodyJobChildren.parentIds = item? [item.id]: [];
    this.selectedJob = this.buildSelectedItem(this.bodyJobChildren.parentIds[0], this.jobsList);
  }

  buildSelectedItem(id: any, items: any) {
    let selectedItem = null;
    for (let i = 0; i < items.length; i++) {
      if (items[i].id == id) {
        selectedItem = items[i];
        break;
      }
    }
    return selectedItem;
  }

  onProvinceSelected(provinceCode: any) {
    this.address.province = this.getPositionNameByCode(
      this.provinces,
      provinceCode
    );
    this.address.lat = this.getPositionLatByCode(
      this.provinces,
      provinceCode
    )
    this.address.lng = this.getPositionLngByCode(
      this.provinces,
      provinceCode
    )
  }

  getPositionNameByCode(data: any, code: any) {
    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      if (item.code == code) {
        return item.name;
      }
    }
  }
  getPositionLatByCode(data: any, code: any) {
    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      if (item.code == code) {
        return item.lat;
      }
    }
  }
  getPositionLngByCode(data: any, code: any) {
    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      if (item.code == code) {
        return item.lng;
      }
    }
  }
  selectedProvinces(data: any, center: any) {
    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      if (Math.abs(item.lat - center.lat) < 1 && Math.abs(item.lng - center.lng) < 1) {
        return item.code;
      }
    }
  }

  refetchScrollMenu(el: any) {
    if(el.paging > this.dataJobChildren.totalPage) {
      return;
    }
    this.pageMenu = el.paging;
    this.bodyJobChildren.paging.page = this.pageMenu;
    this.refetchData(this.bodyJobChildren);
  }

  refetchData(body: any) {
    if (body.ids == null) delete body.ids;
    if (this.utilsService.isEmpty(body.parentIds)) delete body.parentIds;
    if (body.keySearch == null) delete body.keySearch;
    const functionName = 'getData';
    const messageLog = 'get list job children';
    const apiUrl = environment.API_URI + ApiNameConstants.BS_JOB_CHILDREN_SEARCH;
    this.commonService
      .postDatas(body, apiUrl, functionName, messageLog)
      .subscribe(
        (res: any) => {
          if (res && res.data) {
            this.jobsList = [...this.jobsList, ...res.data];
            this.dataJobChildren = res;  
          }
        },
        (error) => {
          console.error('API error:', error);
        }
      );
  }
}
