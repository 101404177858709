<div
  class="p-7 rounded-lg border-solid border border-gray-600 cursor-pointer"
  (click)="viewDetailItem()"
>
  <div class="flex">
    <div class="flex col-9">
      <div class="col-2">
        <img class="h-full" src="../assets/icons/home.svg" alt="home-icon" />
      </div>
      <div class="col-10 pl-2">
        <div
          class="font-weight-bold global-text color-ink-text col-span-2 whitespace-nowrap overflow-hidden text-ellipsis first-letter:uppercase"
        >
          <span [title]="item.name">{{ item.name }}</span>
        </div>
        <div
          class="color-light-gray-text font-size-small row-span-2 col-span-2"
        >
          <span>{{ item.district }}, {{ item.province }}</span>
        </div>
      </div>
    </div>
    <div class="col-3 global-text color-light-gray-text">
      <span class="font-size-small float-right">{{ item.expdate }}</span>
    </div>
  </div>
  <div class="flex text-[var(--light-orange)] my-3 gap-3">
    <span
      *ngIf="item.workingType == 0 || item.workingType == null"
      class="font-size-small bg-[var(--bg-yellow-50)] px-2 py-1 rounded-md"
      >{{ "partTimeLbl" | translate }}</span
    >
    <span
      *ngIf="item.workingType == 1"
      class="font-size-small bg-[var(--bg-yellow-50)] px-2 py-1 rounded-md"
      >{{ "fullTimeLbl" | translate }}</span
    >
  </div>
  <div
    class="font-weight-bold global-text color-ink-text col-span-2 whitespace-nowrap overflow-hidden text-ellipsis mb-2"
  >
    <span [title]="item.job">{{ item.job }}</span>
  </div>
  <div class="color-light-gray-text text-description">
    <span class="font-size-small" [title]="item.des">{{ item.des }}</span>
  </div>
  <div class="mt-3 flex justify-between items-center">
    <span class="text-[var(--blue-text)] font-semi-bold text-14">{{
      item.salary
    }}</span>
    <div
      class="flex flex-col justify-center items-center gap-2 rounded-lg border border-[var(--blue-1)] hover:bg-blue-50 hover:border-blue-600 active:bg-blue-400"
    >
      <button
        class="flex justify-center items-center font-semibold py-[10px] px-4 text-[var(--blue-text)] active:text-white"
      >
        {{ "getJobLbl" | translate }}
      </button>
    </div>
  </div>
</div>
