<div class="">
  <div class="table-wrapper">
    <table
      id="dtOrderExample"
      class="table table-striped table-bordered table-sm dataTable table-body-wrapper"
      cellspacing="0"
      width="100%"
    >
      <thead>
      <tr>
        <th
          class="heading-table"
        >
          <span>{{ sequenceIndexLbl }}</span>
        </th>
        <th
          class="heading-table"
          *ngFor="let col of displayedColumns"
          ngClass="{{ col.key }}"
        >
          <span>{{ col.name }}</span>
        </th>
      </tr>
      </thead>
      <tbody class="">
      <tr class="body-table" *ngFor="let rowData of dataTable; index as i">
        <td class="" *ngFor="let col of displayedColumns">
          <span>{{i}}</span>
        </td>
        <td class="" *ngFor="let col of displayedColumns">
          <span>{{rowData[col.key]}}</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="row paginator-wrapper">
    <div class="pagination d-flex justify-content-end">
      <a href="#" (click)="onPrePageClick()">&laquo;</a>
      <li
        class="page-item"
        *ngFor="let i of arrPage"
        (click)="onCurrentPageClick(i)"
      >
        <a class="page-link" *ngIf="isDisplayPageNumber(i)">{{ i }}</a>
      </li>
      <a href="#" (click)="onNextPageClick()">&raquo;</a>
    </div>
  </div>
</div>
