<div class="popup-container" id="popup-change-point">
	<div class="modal relative">
		<div
			class="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[90%] md:w-[40%] inline-flex flex-col gap-3 rounded-lg"
			style="background: var(--white);">
			<div class="flex px-3 py-2 justify-between items-center gap-5 self-stretch"
				style="border-radius: 8px 8px 0px 0px; background: var(--blue-text);">
				<div class="flex items-center gap-28 flex-1">
					<div class="flex items-center gap-5 text-white">
						<span class="font-body font-semibold text-xl">{{ "changePointLbl"
							| translate }}</span>
					</div>
				</div>
				<button (click)="closePopup()"><img src="../../../../../assets/icons/close.svg" alt="icon-close">
				</button>
			</div>

			<div class="flex flex-col p-6 gap-7">
				<div class="flex flex-col items-start gap-2 self-stretch">
					<div class="flex px-3 items-start gap-3">
						<label class="font-body text-sm font-semibold" style="color: var(--ink);">{{ "selectChangeLbl" |
							translate }}</label>
					</div>
					<div class="flex items-center self-stretch rounded-lg cursor-pointer"
						style="border: 1px solid #E3E3E3; background-color: rgba(0, 0, 0, 0.05);">
						<div class="flex px-3 py-2 items-center gap-1 flex-1 rounded-sm">
							<span class="font-body text-sm" style="color: rgba(0, 0, 0, 0.30);">{{
								"selectChangeLbl" | translate }}</span>
						</div>
						<button><img class="pr-5" src="../../../../../assets/icons/icon-down.svg" alt=""></button>
					</div>
				</div>
			</div>

			<div class="flex justify-start items-center  flex-wrap">
				<div class="flex flex-col items-start px-3 gap-2 h-[80px] w-full md:w-5/12">
					<div class="flex items-start px-3 gap-3">
						<label class="font-body text-sm font-semibold" style="color: var(--ink);">{{
							"numberchangePointLbl" | translate}}</label>
					</div>
					<div class="flex items-center  rounded-lg w-full"
						style="border: 1px solid #F6F6F6; background: var(--background-colapse-bar)">
						<input class="flex px-3 py-2 items-center gap-1 rounded-sm outline-none w-full"
							style="background: var(--background-colapse-bar);" type="text">
					</div>
				</div>

				<div class="flex items-center justify-center w-full  md:w-2/12">
					<div>
						<img src="../../../../../assets/icons/Swap.svg" alt="swap" />
					</div>
				</div>


				<div class="flex flex-col items-start px-3 gap-2 h-[80px] w-full md:w-5/12">
					<div class="flex items-start px-3 gap-3">
						<label class="font-body text-sm font-semibold" style="color: var(--ink);">{{
							"rewardLbl" | translate}}</label>
					</div>
					<div class="flex items-center  rounded-lg w-full"
						style="border: 1px solid #F6F6F6; background: var(--background-colapse-bar)">
						<input class="flex px-3 py-2 items-center gap-1 rounded-sm outline-none w-full"
							style="background: var(--background-colapse-bar);" type="text">
					</div>
				</div>

			</div>

			<div class="flex items-start gap-5 justify-center py-10">
				<div class="flex flex-col items-center justify-center gap-2 rounded-lg" style="background: var(--blue-text);">
					<button class="flex px-4 py-2 justify-center items-center gap-2">
						<span class="font-body text-xs font-semibold items-center" style="color: var(--white);">
							{{ "changePointLbl" | translate }}
						</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>