<div class="user-detail-wrapper">
	<div class="flex flex-col items-start gap-5 mt-2 mr-5">
		<div class="flex flex-col items-start p-3 gap-10 bg-white rounded-xl  ">
			<form action="" [formGroup]="detailUserForm">
				<div class="row">
					<div class="col-xs-12 col-md-12 px-2">
            <div class="flex justify-between flex-wrap" *ngIf="parentPageCode == PROFILE_PAGE">
              <div class="mb-3 w-full">
                <div class="form-label"><span class="color-ink-text font-size-small font-weight-bold">{{"jobLbl" |
                  translate }}</span>
                </div>
                <div class="input-container">
                  <app-selection class="w-full" [selectedItem]="selectedJobDefault" [items]="jobDefaults" [pageMenu]="pageMenu"
                                 (onChangeSelected)="onJobDefaultSelected($event)" (refetchScrollMenu)="refetchScrollMenu($event)"></app-selection>
                </div>
              </div>
            </div>
					</div>
					<div class="flex justify-between px-2 flex-wrap">
						<div class="w-full md:w-5/12">
							<div class="mb-3 w-full">
								<div class="form-label">
									<span class="color-ink-text font-size-small font-weight-bold">{{"provinceLbl" |
										translate }}</span>
								</div>
								<app-selection-menu class="w-full" [isDisable]="!isEditMode" [selectedItem]="selectedProvince"
									[items]="provinces" (onChangeSelected)="onProvinceSelected($event)"></app-selection-menu>
							</div>
						</div>
						<div class="w-full md:w-5/12">
							<div class="mb-3 w-full">
								<div class="form-label"><span class="color-ink-text font-size-small font-weight-bold">{{
										"districtLbl" | translate }}</span>
								</div>
								<app-selection-menu class="w-full" [isDisable]="!isEditMode" [selectedItem]="selectedDistrict"
									[items]="districts" (onChangeSelected)="onDistrictSelected($event)"></app-selection-menu>
							</div>
						</div>

					</div>
					<div class="flex justify-between px-2 flex-wrap">
						<div class="w-full md:w-5/12">
							<div class="mb-3 w-full">
								<div class="form-label"><span class="color-ink-text font-size-small font-weight-bold">{{"wardLbl" |
										translate
										}}</span>
								</div>
								<app-selection-menu [isDisable]="!isEditMode" [selectedItem]="selectedWard" [items]="wards"
									(onChangeSelected)="onWardSelected($event)"></app-selection-menu>
							</div>
						</div>
						<div class="w-full md:w-5/12">
							<div class="mb-3 w-full">
								<div class="form-label"><span class="color-ink-text font-size-small font-weight-bold">{{"addressLbl" |
										translate }}</span>
								</div>
								<input class="form-control" [(ngModel)]="userItem.detailAddress"
									[ngClass]="address.invalid? 'inValid-border': 'valid-border'" formControlName="address" />
							</div>
						</div>

					</div>

					<div class="row mb-3 px-2">
						<div class="form-label"><span class="color-ink-text font-size-small font-weight-bold">{{"jobTargetLbl" |
								translate
								}}</span>
						</div>
						<textarea rows="3" class="form-control p-3" formControlName="jobTarget"
							[(ngModel)]="userItem.jobTarget"></textarea>
					</div>
					<div class="row mb-3 px-2">
						<div class="form-label"><span class="color-ink-text font-size-small font-weight-bold">{{"experienceLbl" |
								translate
								}}</span>
						</div>
						<textarea rows="3" class="form-control p-3" formControlName="experience"
							[(ngModel)]="userItem.experience"></textarea>
					</div>
				</div>
				<div class="flex gap-2 mb-2">
					<input type="file" #fileInput style="display:none" (change)="onFileSelected($event)" accept="application/pdf, application/vnd.ms-excel">
					<button (click)="fileInput.click()" style="border: 1px solid #5B91FB;"
						class="rounded  w-auto flex justify-center items-center gap-2 py-1 px-2 ">
						<img src="../../../../../assets/icons/uploadFile.svg" />
						<p class="flex items-center text-center font-body not-italic font-semibold text-xs underline"
							style="color: #3276FA;">{{"uploadCV" | translate}}</p>
					</button>
				</div>
				<div class="flex gap-2 mb-2" *ngIf="selectedFile">
					{{selectedFile.name}}
				</div>
				<div class="flex justify-center">
					<button [disabled]="detailUserForm.invalid"
						[ngClass]="detailUserForm.invalid || !selectedFile? 'btn-disable' : 'active-btn-save'" class="btn
						border-none
						font-size-small
						font-weight-bold
						global-text-color-white
						bg-blue-600
						px-3 w-[170px]" (click)="saveData()" type="button" aria-expanded="false" aria-controls="collapseDetailInfo">
						<span class="px-2 font-body not-italic font-semibold text-xs items-center">{{"postJob" | translate}}</span>
					</button>
				</div>
			</form>
		</div>
	</div>
</div>
<app-toast #appToast></app-toast>
