<div class="">
	<div class="table-wrapper">
		<table class="table table-striped table-bordered table-sm dataTable table-body-wrapper" cellspacing="0" width="100%">
			<thead>
				<tr class="tr-header-wrapper">
					<th class="heading-table px-1">
						<span>{{ sequenceIndexLbl }}</span>
					</th>
					<th class="heading-table px-3" *ngFor="let col of displayedColumns" ngClass="{{ col.key }}">
						<span>{{ col.name }}</span>
					</th>
					<th class="px-1">
						<input type="checkbox" class="form-check-input form-check-input-all" id="" (click)="onCheckAll($event)" />
					</th>
				</tr>
			</thead>
			<tbody>
				<tr (click)="showDetail(rowData)" class="body-table px-2 truncate"
					*ngFor="let rowData of dataTable; index as i">
					<td class="md:px-3 px-2">
						<span>{{i+1+((currentPage-1)*pageSize)}}</span>
					</td>
					<td class="md:px-2 px-1" *ngFor="let col of displayedColumns">
						<div *ngIf="col.key === 'status'">
							<span style="color:var(--reject)" *ngIf="rowData[col.key] == '-3'">{{"rejected" | translate}}</span>
							<span style="color:var(--disable-text-color)" *ngIf="rowData[col.key] == '-2'">{{"canceled" |
								translate}}</span>
							<span style="color:var(--fail)" *ngIf="rowData[col.key] == '-1'">{{"failed" | translate}}</span>
							<span style="color:var(--yellow)" *ngIf="rowData[col.key] == '0'">{{"interviewProcess" |
								translate}}</span>
							<span style="color:var(--green)" *ngIf="rowData[col.key] == '1'">{{"applied" | translate}}</span>
							<span *ngIf="rowData[col.key] == '2'">{{"chosen" | translate}}</span>
							<span style="color:var(--accepted)" *ngIf="rowData[col.key] == '3'">{{"accepted" | translate}}</span>
							<span style="color:var(--interviewed)" *ngIf="rowData[col.key] == '4'">{{"interviewed" |
								translate}}</span>
							<span style="color:var(--passed)" *ngIf="rowData[col.key] == '5'">{{"passed" | translate}}</span>
							<span style="color:var(--contacted)" *ngIf="rowData[col.key] == '6'">{{"contacted" | translate}}</span>
							<span style="color:var(--done)" *ngIf="rowData[col.key] == '7'">{{"done" | translate}}</span>
						</div>
						<div *ngIf="col.key !== 'status' && col.key !== 'workingType'">
							<span class="text-ellipsis overflow-hidden truncate">{{rowData[col.key]}}</span>
						</div>
						<div *ngIf="col.key == 'workingType'">
							<div *ngIf="rowData[col.key] == partTime">
								<span class="text-ellipsis overflow-hidden truncate">{{"partTimeLbl" | translate}}</span>
							</div>
							<div *ngIf="rowData[col.key] == fullTime">
								<span class="text-ellipsis overflow-hidden truncate">{{"fullTimeLbl" | translate}}</span>
							</div>
						</div>
					</td>
					<td class="md:px-2 px-1">
						<div class="d-flex justify-content-center">
							<input type="checkbox" class="checkbox-item form-check-input"
								(click)="onCheckBox($event, rowData);$event.stopPropagation()" />
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="flex flex-row items-center justify-center paginator-wrapper">
		<div class="pagination flex justify-end mt-4 sm:mt-0 mb-3 space-x-2">
			<a (click)="onPrePageClick()" class="px-3 py-1 border border-gray-300 rounded bg-white hover:bg-gray-200 disabled:opacity-50 cursor-pointer" [class.disabled]="!isPrePageActive()" aria-disabled="!isPrePageActive()">&laquo;</a>
			
			<ng-container *ngFor="let i of arrPage">
				<li class="page-item" *ngIf="isDisplayPageNumber(i)" (click)="onCurrentPageClick(i)">
					<a class="page-link px-3 py-1 border border-gray-300 rounded hover:bg-blue-500 hover:text-white {{ currentPage === i ? 'bg-blue-500 text-white' : 'hover:bg-gray-200' }}">{{ i }}</a>
				</li>
			</ng-container>
			
			<a (click)="onNextPageClick()" class="px-3 py-1 border border-gray-300 rounded bg-white hover:bg-gray-200 disabled:opacity-50 cursor-pointer" [class.disabled]="!isNextPageActive()" aria-disabled="!isNextPageActive()">&raquo;</a>
		</div>
	</div>
</div>