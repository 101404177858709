<div class="parent-wrapper horizontal-center-pos global-text">
    <div class="parent-wrapper-inside global-text">
        <div class="row header-wrapper">
            <app-header-up pageName="{{pageName}}"></app-header-up>
        </div>
        <div class="horizontal-center-pos">
            <div class="row candidate-content-wrapper content-wrapper horizontal-center-pos">
                <div class="col-md-12">
                    <h2>
                        CƠ CHẾ GIẢI QUYẾT TRANH CHẤP – JOBER.COM.VN</h2>                    
                    <p ></p>
                    <p>Khi phát sinh tranh chấp, Công ty TNHH  JOBER đề cao giải pháp thương lượng, hòa giải giữa các bên nhằm duy trì sự tin  cậy của thành viên vào chất lượng dịch vụ của Công ty và thực hiện theo các bước  sau:<br>
                    </p>
                    <p>+&nbsp;  Bước 1:Thành viên phản ánh về dịch vụ website cung cấp, trả thông tin  đăng tải về việc làm không chính xác... qua email: lienhe@jober.com.vn <br>
                    </p>
                    <p>+ &nbsp;Bước 2: Bộ phận Chăm Sóc Khách Hàng hoặc Bộ phận  Kinh doanh của Công ty sẽ tiếp nhận các phản ánh của thành viên. Các Bộ phận  này chủ động giải quyết nhanh chóng và trả lời kết quả giải quyết các khiếu nại  trên cơ sở các Chính sách mà Công ty đã công bố.<br>
                    </p>
                    <p>+ &nbsp;Bước 3: Tuy theo mức độ sai phạm Công ty sẽ  đưa ra biện pháp xử lý phù hợp. Trong trường hợp 2 bên không đi đến được tiếng nói  chung qua thương lượng. Công ty sẽ đưa vụ việc này ra cơ quan nhà nước có thẩm  quyền để giải quyết theo pháp luật. Jober.com.vn, người tuyển dụng và người tìm việc có  trách nhiệm tiếp nhận khiếu nại và hỗ trợ người tìm việc hoặc người tuyển dụng  liên quan đến các nội dung công việc đăng tải trên website Jober.com.vn. <br>
                    </p>
                    <p>Thời gian giải quyết khiếu nại trong  vòng 3 ngày kể từ ngày nhận được khiếu nại.<br>
                    </p>
                    <p><strong>Gửi khiếu nại  tại địa chỉ :</strong><br>
                    <strong>Công ty TNHH  JOBER</strong><br>
                    Trụ sở làm việc: Tòa nhà Thăng Long, số 99 Mạc Thái Tổ,  Yên Hòa, Cầu Giấy, Hà Nội <br>
                    Hotline: 035.9999.561<br>
                    Email: <a href="mailto:lienhe@jober.com.vn">lienhe@jober.com.vn</a> <br>
                    </p>
                    <p>Jober.com.vn tôn trọng và nghiêm túc thực hiện các  quy định của pháp luật về bảo vệ&nbsp;quyền lợi của người tìm việc. Vì vậy, đề nghị  các thành viên đăng tin tuyển dụng trên sàn&nbsp;cung cấp đầy đủ, chính xác,  trung thực và chi tiết các thông tin liên quan đến nội dung&nbsp;công việc. Mọi  hành vi lừa đảo, gian lận đều bị lên án và phải chịu hoàn toàn trách nhiệm&nbsp;trước  pháp luật.<br>
                    Các bên bao gồm người tìm việc, người  tuyển dụng sẽ phải có vai trò trách nhiệm trong&nbsp;việc tích cực giải quyết vấn  đề. Đối với người tuyển dụng cần có trách nhiệm cung cấp&nbsp;văn bản giấy tờ  chứng thực thông tin liên quan đến sự việc đang gây mâu thuẫu với người&nbsp;tìm  việc. Đối với Jober.com.vn  sẽ có trách cung cấp những thông tin  liên quan đến người&nbsp;tìm việc và người tuyển dụng nếu được một trong hai  bên (liên quan đến tranh chấp đó)&nbsp;yêu cầu. <br>
                    </p>
                    <p>Sau khi người tìm việc, người tuyển dụng  đã giải quyết xong tranh chấp phải có trách&nbsp;nhiệm báo lại cho ban quản trị  Jober.com.vn. Trong trường hợp giao dịch phát sinh  mâu&nbsp;thuẫn mà lỗi thuộc về người tuyển dụng: Jober.com.vnsẽ có biện pháp cảnh cáo, khóa  tài&nbsp;khoản hoặc chuyển cho cơ quan pháp luật có thẩm quyền tùy theo mức độ  của sai phạm.&nbsp;Jober.com.vnsẽ  chấm dứt và gỡ bỏ toàn bộ tin bài về nội dung công việc của người tuyển&nbsp;dụng  đó trên Jober.com.vn.<br>
                    </p>
                    <p>Nếu thông qua hình thức thỏa thuận mà vẫn  không thể giải quyết được mâu thuẫn phát&nbsp;sinh từ giao dịch giữa 2 bên người  tìm việc, người tuyển dụng, thì một trong hai bên sẽ có&nbsp;quyền nhờ đến cơ  quan pháp luật có thẩm quyền can thiệp nhằm đảm bảo lợi ích hợp&nbsp;pháp của  các bên.</p>
                </div>
            </div>
        </div>
    </div>
    
</div>
