<app-toast #appToast [messageCode]="messageCode"></app-toast>
<div class="user-detail-wrapper">
	<form action="" [formGroup]="detailUserForm" (keyup.enter)="loginWithCredentials()">
		<div class="row mt-5">

			<div class="mb-3">
				<div class="form-label"><span class="color-ink-text font-size-small font-weight-bold">{{"usernameLbl" |
						translate}}</span>
				</div>
				<input [(ngModel)]="userItem.phone" [ngClass]="(phone.invalid||!loginState)? 'inValid-border': 'valid-border'"
					class="form-control p-2" type="text" formControlName="phone" placeholder="0987654321" />
			</div>

			<div class="forgot-pass h-10" *ngIf="phone.invalid">
				<span class="text-sm text-red-600 text-link cursor-pointer">{{"invalidPhone" | translate}}</span>
			</div>
			<div class="mb-3">
				<div class="form-label">
					<span class="color-ink-text font-size-small font-weight-bold">{{'passLbl' | translate }}</span>
				</div>
				<div class="password-container">
					<input [type]="showPassword ? 'text' : 'password'" 
						   [(ngModel)]="userItem.password" 
						   [ngClass]="password.invalid || !loginState ? 'inValid-border' : 'valid-border'" 
						   class="form-control p-2" formControlName="password" 
						   placeholder="{{'placeholderPassword' | translate}}" 
						   (input)="onPasswordInput()" />
					<span *ngIf="userItem.password && userItem.password.length > 0" class="toggle-password" (click)="togglePasswordVisibility()">
						<i [class]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
					</span>
				</div>
			</div>
			
			<div class="forgot-pass h-10" *ngIf="isLogin&&!loginState">
				<span class="text-sm text-red-600 text-link cursor-pointer">{{"wrongPassword" | translate}}</span>
			</div>
			<div class="forgot-pass h-">
				<span class="text-sm underline text-link cursor-pointer" (click)="gotoForgotPassForm()">{{"forgotPassLbl"|
					translate}}</span>
			</div>
			<div class="mt-5">
				<button [ngClass]="'active-btn-save'" class="btn
          border-none
          font-size-small
          font-weight-bold
          global-text-color-white
          bg-green
          px-3" (click)="loginWithCredentials()" type="button" aria-expanded="false"
					aria-controls="collapseDetailInfo">
					<img class="" src="../assets/icons/white-save.svg" alt="">
					<span class="px-2">{{"loginBtnLbl" | translate}}</span>
				</button>
			</div>

		</div>
	</form>
</div>