import {Component, OnInit, Input, OnChanges} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "../../../core/auth/auth.service";

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit, OnChanges {
  jobDefaults: any;
  @Input() dataSource: any;
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}
  ngOnInit() {
    // this.getData();
  }
  ngOnChanges() {
    this.getData();
  }
  getData() {
    /*this.jobDefaults = [
      {
        id: '',
        name: 'Giúp việc',
        icon: '../assets/icons/support-job.svg',
      }*/
    this.jobDefaults = this.dataSource.data;
  }
	gotoOtherPage(item: any) {
		if (this.authService.isCandidate() || (!this.authService.isCandidate()) && !this.authService.isRecruiter()) {
			this.router.navigate(["/job"], { queryParams: { name: item.name, parentId: item.id} }); // change to slug
		} else {
			this.router.navigate(["/freelancer"], { queryParams: { name: item.name, parentId: item.id } }); // change to slug
		}
	}
}
