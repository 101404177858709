
<!-- google-maps-demo.component.html -->
<div class="map-wrapper">
    <google-map 
        height="730px"
        width="1520px"
        [center]="center"
        [zoom]="zoom"
        (mapClick)="moveMap($event)"
        (mapMousemove)="move($event)">
    </google-map>
</div>
<!-- <div>Latitude: {{display?.lat}}</div>
<div>Longitude: {{display?.lng}}</div> -->