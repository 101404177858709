<div class="header-up-wrapper row">
  <app-header-up></app-header-up>
</div>
<div class="rounded-bottom min-h-screen">
  <div class="content font-common relative overflow-hidden">
    <div
      class="absolute w-[2300px] -z-10 h-[1005px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-2/3 bg-light-pink rounded-b-full"
    ></div>
    <div class="row">
      <div
        class="col-md-7 col-xs-12 d-flex align-items-center justify-content-center"
      >
        <div>
          <div class="position-relative">
            <div>
              <span class="text-6xl font-bold color-ink-text"
                >Tìm việc <span class="text-6xl font-bold text-link">KHÓ</span>
              </span>
            </div>
            <div>
              <span class="text-6xl font-bold color-light-orange-text"
                >CÓ <span class="text-6xl font-bold color-ink-text">Jober</span>
              </span>
            </div>
            <img
              class="position-absolute question relative"
              src="../assets/icons/question-mark.svg"
              alt="slogan"
            />
          </div>
          <div class="searching-wrapper mt-4">
            <form [formGroup]="searchForm">
              <!-- <label
                for="default-search"
                class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                >{{ "searchLbl" | translate }}</label
              >
              <div class="relative hidden sm:flex">
                <div
                  class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
                >
                  <svg
                    aria-hidden="true"
                    class="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    ></path>
                  </svg>
                </div>
                <input
                  (click)="openPopupSearch()"
                  id="default-search"
                  class="block w-full pt-4 pb-4 pl-12 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Tìm kiếm việc làm"
                  required
                  readonly
                />
                <button
                  (click)="openPopupSearch()"
                  class="mb-2 text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  {{ "searchLbl" | translate }}
                </button>
              </div> -->
              
              <div class="flex items-center border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 pr-2">
                <!-- Search input -->
                <div class="relative flex items-center">
                      <app-selection class="block w-48 md:w-72 py-2 pl-1"
                      [selectedItem]="selectedJob" [items]="jobsList" [pageMenu]="pageMenu" (onChangeSelected)="onSelectJob($event)" (refetchScrollMenu)="refetchScrollMenu($event)">
                      </app-selection>
                </div>
            
                <!-- Divider -->
                <div class="flex items-center ml-2">
                    <div class="h-6 border-l border-gray-300 dark:border-gray-600"></div>
                </div>
            
                <!-- Dropdown menu -->
                <div class="relative flex items-center">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z"></path>
                            <circle cx="12" cy="9" r="2" fill="currentColor"></circle>
                        </svg>
                    </div>
                      <app-selection-menu class="block w-32 md:w-48 py-2 pl-2 pr-2"
                      [selectedItem]="selectedProvince" [items]=" provinces" (onChangeSelected)="onProvinceSelected($event)">
                      </app-selection-menu>
                </div>
            
                <!-- Search button -->
                <button style="background-color: var(--blue-text);" class="rounded-lg flex py-2 px-4 items-center justify-center gap-2 text-white" (click)="onValidate()">
                    <span class="items-center font-body font-semibold text-base text-white">
                        <svg aria-hidden="true" class="w-5 h-5 text-white-500 dark:text-white-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                        </svg>
                    </span>
                </button>
            </div>      
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-5 col-xs-12 flex justify-content-end">
        <img src="../assets/icons/web-frame.svg" alt="web-frame" />
      </div>
    </div>
    <div class="" *ngIf="jobDefaultSource">
      <app-category [dataSource]="jobDefaultSource"></app-category>
    </div>
  </div>
  <div class="mt-20">
    <div class="flex justify-center">
      <span
        class="font-body font-semibold text-4xl not-italic text-center justify-center"
        style="color: var(--blue-text)"
        >{{ "topCandidateLbl" | translate }}</span>
    </div>
    <div class="mt-14" *ngIf="dataSource">
      <app-candidate-items-list
        [dataSource]="dataSource"
        (changePage)="changePage($event)"
        (onViewDetailItem)="showDetailItem($event)"
        [currentPage]="pageNum"
      ></app-candidate-items-list>
    </div>
  </div>
</div>
<app-popup-search
  #popupSearch
  (validate)="onSearchJob($event)"
></app-popup-search>

<app-footer></app-footer>
<app-loading #appLoading></app-loading>
