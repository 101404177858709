<div class="page-top back-ground-them">
	<div class="">
		<app-header-up [searchingSuggestionData]="searchingSuggestionData"
			(selectRole)="onRoleChange($event)"></app-header-up>
	</div>
	<!-- <div class="">
    <app-searching-component [searchingSuggestionData] = "searchingSuggestionData"
    (search)="onSearch($event)"
  ></app-searching-component>
  </div> -->
</div>