<div
  class="p-7 rounded-lg border-solid border border-gray-600 cursor-pointer"
  (click)="viewDetailItem()"
>
  <div class="flex">
    <div class="flex col-12">
      <div class="col-3">
        <img class="h-full rounded-md" src={{avatarUrl}} alt="home-icon" />
      </div>
      <div class="col-9 pl-2 flex flex-col justify-between">
        <div
          class="font-weight-bold global-text color-ink-text col-span-2 whitespace-nowrap overflow-hidden text-ellipsis first-letter:uppercase text-18"
        >
          <span [title]="item.name">{{ item.name }}</span>
        </div>
        <div
          class="color-light-gray-text font-size-small row-span-2 col-span-1"
        >
          <span>{{item.age?item.age+" tuổi, ":""}}{{ item.district }}-{{ item.province }}</span>
        </div>
        <div class="flex gap-3">
          <span
            class="font-semibold global-text color-ink-textfont-size-small bg-slate-200 px-2 py-1 rounded-md"
            >{{item.job}}</span
          >
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="flex text-[var(--light-orange)] my-3 gap-3">
    <span
      *ngIf="item.workingType == 0 || item.workingType == null"
      class="font-size-small bg-[var(--bg-yellow-50)] px-2 py-1 rounded-md"
      >{{ "partTimeLbl" | translate }}</span
    >
    <span
      *ngIf="item.workingType == 1"
      class="font-size-small bg-[var(--bg-yellow-50)] px-2 py-1 rounded-md"
      >{{ "fullTimeLbl" | translate }}</span
    >
  </div> -->
  <div class="text-description mt-4">
    <span class="font-size-small" [title]="item.des">{{ item.des }}</span>
  </div>
  <div class="flex global-text color-light-gray-text my-3">
    <app-rating [ratingNum]="item.ratingAvg"></app-rating>
  </div>
  <div class="mt-3 flex justify-between items-center">
    <span class="text-[var(--blue-text)] font-semi-bold text-14">{{
      item.salary
    }}</span>
    <div
      class="flex flex-col justify-center items-center gap-2 rounded-lg border border-[var(--blue-1)] hover:bg-blue-50 hover:border-blue-600 active:bg-blue-400"
    >
      <button
        class="flex justify-center items-center font-semibold py-[10px] px-4 text-[var(--blue-text)] active:text-white"
      >
        {{ "recruitLbl" | translate }}
      </button>
    </div>
  </div>
</div>
