<div class="popup-container popup-confirm-upgrade-account padding ">
  <div class="modal">
    <div class="modal-inner center-pos row">
      <div class="col-12  bg-blue rounded-top d-flex justify-content-center align-items-center position-relative h-1/4">
        <span class=" font-weight-bold global-text-color-white">{{"upgradeProfileLbl" | translate}}</span>
        <img type="button" (click)="closePopup()" src="../assets/icons/white-cancel.svg" class="position-absolute top-0 end-0 p-3" >
      </div>
      <div class="px-4 flex flex-col items-center gap-4">
        <span>{{"qaUpgradeAccountLbl" | translate}}</span>
        <div class="flex flex-col items-center justify-center gap-5">
          <div class="grid grid-cols-2 gap-4">
            <button (click)="closePopup()" class="bg-gray-200 text-gray-700 hover:bg-gray-300 py-2 rounded-md w-full text-center transition-colors duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-300">{{ "cancelLbl" | translate }}</button>
            <button (click)="onValidate()" class="bg-blue-500 text-white hover:bg-blue-600 py-2 rounded-md w-full text-center transition-colors duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500">{{ "upgradeLbl" | translate }}</button>
          </div>
        </div>        
      </div>
    </div>
  </div>
</div>
