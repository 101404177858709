<app-toast #appToast [messageCode]="messageCode"></app-toast>
<div class="user-detail-wrapper">
	<div class="flex flex-col items-start p-2.5">
		<!-- avatar -->
		<div class="flex flex-col items-start md:p-3 bg-white rounded-xl  mt-2 md:-mt-9">
			<div class="flex flex-row md:justify-between justify-center items-start flex-wrap md:px-5 px-1 w-full">
				<div class="flex md:flex-row flex-col justify-center items-center flex-wrap md:items-start isolate ">
					<div class="flex relative flex-col justify-center items-center md:mt-0">
						<img [src]="avatarUrl? avatarUrl: '../assets/icons/user.svg'" alt="Profile"
							class="block mx-auto w-20 h-20 md:w-40 md:h-52 rounded-full md:rounded-none">
						<span class="absolute order-2 rounded-2xl hidden md:block"
							style="width: 16px; height: 16px; background-color: #00CA15; left: 150px; top: -5px;">
						</span>
					</div>
					<!-- avatar -->
					<!-- star destkop -->
					<div class="hidden md:flex  flex-col items-start gap-1 ml-10">
						<h1 class="font-body not-italic font-semibold text-sm" style="color: #082639;">
							{{this.userItem.name}}</h1>
						<div class="flex flex-row justify-center items-center gap-10">
							<h4 class='font-12 font-semibold'>{{userItem.recruiterRate.averageRating}} {{ "PointLbl" |
								translate }}
							</h4>
							<h4 class="font-normal text-blurblack ml-2">{{userItem.recruiterRate.totalCount}} {{
								"reviewLbl" |
								translate }} </h4>
						</div>
						<div class="flex flex-row justify-center items-center gap-10">
							<img src="/assets/icons/rating.svg" />
							<h4 class="font-normal text-blurblack">{{userItem.recruiterRate.rating5Star}}</h4>
						</div>
						<div class="flex flex-row justify-center items-center gap-10">
							<img src="/assets/icons/rating4.svg" />
							<h4 class="font-normal text-blurblack">{{userItem.recruiterRate.rating4Star}}</h4>
						</div>
						<div class="flex flex-row justify-center items-center gap-10">
							<img src="/assets/icons/rating3.svg" />
							<h4 class="font-normal text-blurblack">{{userItem.recruiterRate.rating3Star}}</h4>
						</div>
						<div class="flex flex-row justify-center items-center gap-10">
							<img src="/assets/icons/rating2.svg" />
							<h4 class="font-normal text-blurblack">{{userItem.recruiterRate.rating2Star}}</h4>
						</div>
						<div class="flex flex-row justify-center items-center gap-10">
							<img src="/assets/icons/rating1.svg" />
							<h4 class="font-normal text-blurblack">{{userItem.recruiterRate.rating1Star}}</h4>
						</div>
					</div>
					<!--star destkop -->
				</div>
				<!-- Save button desktop -->
				<div *ngIf="parentPageCode != APP_CANDIDATE_ITEMS_LIST"
					class=" md:flex flex-col items-start flex-wrap pt-1 gap-3">
				</div>
			</div>
			<div *ngIf="parentPageCode == APP_CANDIDATE_ITEMS_LIST">
				<div class="hidden md:flex flex-col items-start flex-wrap pt-1 gap-3">
					<div>
						<div class="flex flex-col px-0 items-start gap-3">
							<div class="flex flex-col justify-center items-center gap-2 self-stretch rounded-lg"
								[ngStyle]="{background: userItem.status != chosenStatus? '#3276FA': '#a1a1a1'}"
								style="background: var(--blue-text);">
								<button [disabled]="userItem.status == chosenStatus"
									class="flex px-4 py-2 justify-center items-center gap-2"
									(click)="checkExistedJob()">
									<span class="font-body not-italic font-semibold text-xs items-center text-white">{{
										'recruitLbl' | translate }}</span>
								</button>
							</div>
							<div class="flex flex-col justify-center items-center gap-2 self-stretch rounded-lg"
								style="background: var(--white); border: 1px solid var(--blue-1);">
								<button class="flex px-4 py-2 justify-center items-center gap-2">
									<span class=" font-body not-italic font-semibold text-xs items-center"
										style="color: var(--blue-text);">{{
										'viewLaterLbl' | translate }}</span>
								</button>
							</div>
							<div class="flex flex-col justify-center items-center gap-2 self-stretch rounded-lg"
								style="background: var(--white); border: 1px solid var(--blue-1);">
								<button [disabled]="!userItem.cv" [ngClass]="{'bg-disable !border-none': !userItem.cv}"
									(click)="onDownloadCv(userItem)" style="border: 1px solid #5B91FB;"
									class="rounded  w-auto flex justify-center items-center gap-2 py-1 px-2 ">
									<p [ngClass]="{'!text-[#9a9ba0] no-underline': !userItem.cv}"
										class="flex items-center text-center font-body not-italic font-semibold text-xs underline"
										style="color: #3276FA;">{{"uploadCV" | translate}}</p>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Save button desktop -->
		</div>
		<div class="flex flex-col justify-center items-center gap-2 self-stretch md:hidden my-4">
			<p [ngClass]="{'!text-[#9a9ba0] no-underline': !userItem.cv}"
				class="flex items-center text-center font-body not-italic font-semibold text-sm underline"
				style="color: #3276FA; cursor: pointer; background: none; border: none; padding: 0;"
				(click)="onDownloadCv(userItem)">
				{{ "uploadCV" | translate }}
			</p>
		</div>
		<!-- form -->
		<form action="" [formGroup]="detailUserForm">
			<div class="row">
				<div class="col-xs-12 col-md-12 px-2">
					<div class="mb-3">
						<div class="form-label"><span
								class="color-ink-text font-size-small font-weight-bold">{{"nameLbl" |
								translate
								}}</span></div>
						<input [(ngModel)]="userItem.name" [ngClass]="name.invalid? 'inValid-border': 'valid-border'"
							class="form-control p-2" type="text" formControlName="name" />
					</div>
					<!--<div class="mb-3">
							<div class="form-label"><span class="color-ink-text font-size-small font-weight-bold">{{"phoneNumberLbl"
									|
									translate }}</span>
							</div>
							<input [(ngModel)]="userItem.phone"
                     [readonly]="true"
                     [ngClass]="phone.invalid? 'inValid-border': 'valid-border'"
								class="form-control p-2 bg-gray-200 text-gray-600" type="text" formControlName="phone" (mousedown)="$event.preventDefault()"/>
						</div>-->

					<div class="flex justify-between flex-wrap">
						<div class="mb-3 w-5/12">
							<div class="form-label"><span
									class="color-ink-text font-size-small font-weight-bold">{{"dateOfBirthLbl"
									|
									translate }}</span>
							</div>
							<div class="input-container">
								<input *ngIf="userItem.dateOfBirth" [(ngModel)]="userItem.dateOfBirth" [disabled]="true"
									formControlName="birthyear"
									[ngClass]="birthyear.invalid? 'inValid-border': 'valid-border'" class="form-control"
									type="date">
							</div>
						</div>
						<div class="mb-3 w-5/12">
							<div class="form-label"><span
									class="color-ink-text font-size-small font-weight-bold">{{"genderLBL" |
									translate }}</span>
							</div>
							<div class="input-container ">
								<div class="input-container ">
									<app-selection-menu [isDisable]="!isEditMode" [selectedItem]="selectedGender"
										[items]="genders"></app-selection-menu>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!--<div class="col-xs-12 col-md-12 px-2">
						<div class="mb-3">
							<div class="form-label"><span class="color-ink-text font-size-small font-weight-bold">{{"emailLbl" |
									translate
									}}</span>
							</div>
							<input type="email" [(ngModel)]="userItem.email"
                     [readonly]="true"
                     (mousedown)="$event.preventDefault()"
								      [ngClass]="email.invalid? 'inValid-border': 'valid-border'"
                     class="form-control p-2 bg-gray-200 text-gray-600" type="text" formControlName="email" />
						</div>
					</div>-->
				<div class="flex justify-between px-2 flex-wrap">
					<div class="w-5/12">
						<div class="mb-3 w-full">
							<div class="form-label">
								<span class="color-ink-text font-size-small font-weight-bold">{{"provinceLbl" |
									translate }}</span>
							</div>
							<app-selection-menu class="w-full" [isDisable]="!isEditMode"
								[selectedItem]="selectedProvince" [items]="provinces"></app-selection-menu>
						</div>
					</div>
					<div class="w-5/12">
						<div class="mb-3 w-full">
							<div class="form-label"><span class="color-ink-text font-size-small font-weight-bold">{{
									"districtLbl" | translate }}</span>
							</div>
							<app-selection-menu class="w-full" [isDisable]="!isEditMode"
								[selectedItem]="selectedDistrict" [items]="districts"></app-selection-menu>
						</div>
					</div>
				</div>
				<div class="flex justify-between px-2 flex-wrap">
					<div class="w-5/12">
						<div class="mb-3 w-full">
							<div class="form-label"><span
									class="color-ink-text font-size-small font-weight-bold">{{"wardLbl" |
									translate
									}}</span>
							</div>
							<app-selection-menu [isDisable]="!isEditMode" [selectedItem]="selectedWard"
								[items]="wards"></app-selection-menu>
						</div>
					</div>
					<div class="w-5/12">
						<div class="mb-3 w-full">
							<div class="form-label"><span
									class="color-ink-text font-size-small font-weight-bold">{{"addressLbl" |
									translate }}</span>
							</div>
							<input class="form-control" [(ngModel)]="userItem.detailAddress"
								[ngClass]="address.invalid? 'inValid-border': 'valid-border'"
								formControlName="detailAddress" />
						</div>
					</div>

				</div>

				<div class="row mb-3 px-2">
					<div class="form-label"><span
							class="color-ink-text font-size-small font-weight-bold">{{"jobTargetLbl" |
							translate
							}}</span>
					</div>
					<textarea rows="3" class="form-control p-3" formControlName="jobTarget"
						[(ngModel)]="userItem.jobTarget"></textarea>
				</div>
				<div class="row mb-3 px-2">
					<div class="form-label"><span
							class="color-ink-text font-size-small font-weight-bold">{{"experienceLbl" |
							translate
							}}</span>
					</div>
					<textarea rows="3" class="form-control p-3" formControlName="experience"
						[(ngModel)]="userItem.experience"></textarea>
				</div>
			</div>
		</form>
	</div>
	<div *ngIf="parentPageCode != APP_CANDIDATE_ITEMS_LIST">
		<div class="flex md:py-5 px-0 items-center justify-center md:gap-3 gap-1">
			<div class="flex flex-col justify-center items-center gap-2 self-stretch rounded-lg md:flex-row md:w-auto"
				style="background: var(--white); border: 1px solid var(--blue-1); width: 40%; text-align: center; margin-right: 10px;">
				<button class="flex px-3 py-2 justify-center items-center gap-1 md:gap-2"
					(click)="closeCandidateInfoPopup()">
					<span class="font-body not-italic font-semibold text-xs items-center"
						style="color: var(--blue-text);">
						{{ 'viewLaterLbl' | translate }}
					</span>
				</button>
			</div>
			<div class="flex flex-col justify-center items-center gap-2 self-stretch rounded-lg"
				style="background: var(--blue-text); width: 40%; text-align: center; margin-left: 10px;">
				<button class="flex px-3 py-2 justify-center items-center gap-1 md:gap-2"
					(click)="onOpenInterviewPopup()">
					<span class="font-body not-italic font-semibold text-xs items-center text-white">
						{{ 'bookInterviewLbl' | translate }}
					</span>
				</button>
			</div>
		</div>
	</div>
</div>

<app-popup-confirm [confirmCode]="CONFIRM_CREATING_NEW_POST" (validate)="showPopupCreatingPost()"
	#popupInformCreatingPost>
</app-popup-confirm>
<app-popup-creating-post [jobDefaultId]="jobDefaultId" [freelancerId]="freelancerId" #popupCreatingPost>
</app-popup-creating-post>