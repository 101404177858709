import {
  Component,
  ViewChild,
  OnInit,
} from '@angular/core';
import { CalendarOptions,EventContentArg } from '@fullcalendar/core'; // useful for typechecking
import { FullCalendarComponent } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { environment } from '../../../../environments/environment';
import { ApiNameConstants } from '../../../constant/ApiNameConstants';
import { LocalStorageService } from 'src/app/core/auth/local-storage.service';
import { ConstantsApp } from 'src/app/constant/ConstantsApp';
import { CommonService } from '../../../service/common.service';
import {UtilsService} from "../../../helper/service/utils.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {PopupCalendarItemComponent} from "./popup-calendar-item/popup-calendar-item.component";
import {EventCalendar} from "../../../model/EventCalendar";

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarCandidateComponent implements OnInit {
	@ViewChild('fullCalendar') fullCalendar: FullCalendarComponent;
  startDate: any = this.utilsService.formatDate(this.utilsService.getStartAndEndOfWeek().startDate);
  endDate: any = this.utilsService.formatDate(this.utilsService.getStartAndEndOfWeek().endDate);
  dataSource: any;
  dataTable: any;
  @ViewChild('popupCalendarItemComponent') popupCalendarItemComponent: PopupCalendarItemComponent = new PopupCalendarItemComponent(this.utilsService, this.commonService);
  displayedColumns: any;
  daysInWeek: string[] = [];
  matrixCalendar: any[][] = [];
  dateForm: FormGroup;
  constructor(
    private localStorage: LocalStorageService,
    private commonService: CommonService,
    private utilsService: UtilsService
  ) {
    this.dateForm = new FormGroup({
      startDate: new FormControl('', [Validators.required]),
      endDate: new FormControl('', [Validators.required]),
    });
  }
	currentView: string = 'timeGridWeek';
	calendarOptions: CalendarOptions = {
    initialView: this.currentView,
		weekends: true,
    slotEventOverlap: false,
    allDaySlot: false,
    slotDuration: '01:00:00',
    slotLabelInterval: '01:00:00',
    slotMinTime: '00:00:00',
    slotMaxTime: '24:00:00',
		selectable: true,
		headerToolbar:false,
		eventContent:this.customEventContent.bind(this),
    // height: 'parent',
    events: [
      new EventCalendar(),
    ],
		plugins: [dayGridPlugin,timeGridPlugin,interactionPlugin]
  };
  buildCalendarEvent(data: any []) {
    let events: any = [];
    data.forEach(item => {
      events.push(
        new EventCalendar(item.jobName, item.startDate.substring(0, item.startDate.indexOf('T')), item.startDate, item.id));
    });
    this.calendarOptions.events = events;
    this.calendarOptions.eventClick = this.handleDateClick.bind(this);
  }
  handleDateClick(info: any) {
    alert('date click! ' + info.event.startStr)
  }
  handleMouseEnter(info: any) {

  }
  ngOnInit(): void {

    // Listen for window resize events
    window.addEventListener('resize', this.onResize.bind(this));
		// let calApi=this.fullCalendar.getApi()
    //  const today = new Date();
    // // const startDate = calApi.view.activeStart
    // this.startDate = this.utilsService.getStartAndEndOfWeek(today);
    // // const endDate = calApi.view.activeEnd
    // this.endDate = this.utilsService.getStartAndEndOfWeek(today);
		// // console.log('start date,',endDate)
    this.initData();
  }
	ngOnDestroy() {
    // Remove the resize event listener when the component is destroyed
    window.removeEventListener('resize', this.onResize.bind(this));
  }

  onResize() {
    // Check the screen width and update the view accordingly
    this.currentView = window.innerWidth < 600 ? 'timeGridWeek' : 'timeGridDay';
  }

  customEventContent(arg: EventContentArg) {
    const eventTitle = arg.event.title;

    // Customize the event content
    const content = document.createElement('div');
    content.innerHTML = `
      <div class="text-black">${eventTitle || 'title	'}</div>
      <p class="text-black">Additional information or custom content here</p>
    `;

    return { domNodes: [content] };
  }
  initData() {
    let body = {
      startDate: this.utilsService.formatLocalDateTime(this.startDate),
      endDate: this.utilsService.formatLocalDateTime(this.endDate),
      statuses: [ConstantsApp.PASSED, ConstantsApp.FAILED,
        ConstantsApp.INTERVIEWED, ConstantsApp.INTERVIEW_PROCESSING,
        ConstantsApp.CANCELED],
    };
    this.getData(body);
    this.buildMatrixCalendar(new Date());
  }
  getData(body: any) {
    const functionName = 'getData';
    const messageLog = 'get calendars';
    const apiUrl = environment.API_URI + ApiNameConstants.BS_SCHEDULE_CALENDAR;
    this.commonService
      .postDatas(body, apiUrl, functionName, messageLog)
      .subscribe(
        (res: any) => {
          if (res && res.data) {
            this.dataTable = res.data;
            this.dataSource = res;
            this.buildCalendarEvent(res.data);
          } else {
            this.dataTable = [];
            this.dataSource = null;
          }
        },
        (error) => {
          this.dataSource = null;
          this.dataTable = [];
          console.error('API error:', error);
        }
      );
  }

  onOpenPopup(item: any): void {
    console.log(item);
    this.popupCalendarItemComponent.openPopup(item);
  }

  counter(count: number) {
    return Array(count).fill(0).map((x, i) => i);
  }

  onChoseStartDate(date: any) {
    const startDate = new Date(date);
    // this.endDate = this.utilsService.formatDate(startDate.setDate(startDate.getDate() + 6));
		let calApi=this.fullCalendar.getApi()

		calApi.gotoDate(startDate || new Date())
		this.startDate=this.utilsService.formatDate(calApi.view.activeStart)
		this.endDate=this.utilsService.formatDate(calApi.view.activeEnd)
    // this.buildMatrixCalendar(this.endDate);
    this.onSearch();
  }
  onChoseEndDate(date: any) {
    const endDate = new Date(date);
		let calApi=this.fullCalendar.getApi()
		calApi.gotoDate(endDate || new Date())
    // this.startDate = this.utilsService.formatDate(endDate.setDate(endDate.getDate() - 6));
    // this.buildMatrixCalendar(this.startDate);
    this.onSearch();
  }
  buildMatrixCalendar(start: Date) {
    this.daysInWeek = [];
    let col = 7;
    let row = 15;
    let array: any[][]=[];
    for (let i = 0; i < col; i ++) {
      const currentDate = new Date(start);
      currentDate.setDate(currentDate.getDate() + i);
      this.daysInWeek.push(currentDate.toDateString());
      array[i] = [];
      for (let j = 0; j < row; j ++) {
        currentDate.setMinutes(0);
        currentDate.setSeconds(0);
        let startTime = new Date(currentDate);
        let endTime = new Date(currentDate);
        startTime.setHours(j + 7);
        endTime.setHours(j + 8);
        let duration = {
          startDate: startTime,
          endDate: endTime
        }
        array[i][j] = duration;
      }
    }
    this.matrixCalendar = array;
  }

  isInsideTheDuration(duration: any, item: any) {
    let startDay1 = this.utilsService.formatDate(duration.startDate);
    let startTime1 = this.utilsService.formatTime(duration.startDate);
    let endDay1 = this.utilsService.formatDate(duration.endDate);
    let endTime1 = this.utilsService.formatTime(duration.endDate);

    let startDay2 = this.utilsService.formatDate(item.startDate);
    let startTime2 = this.utilsService.formatTime(item.startDate);
    let endDay2 = this.utilsService.formatDate(item.endDate);
    let endTime2 = this.utilsService.formatTime(item.endDate);
    if (startDay1 == startDay2 && endDay1 == endDay2) {
      // @ts-ignore
      if ((startTime2 >= startTime1 && startTime2 <= endTime1) || (endTime2 <= endTime1 && endTime2 >= startTime1)) {
        return true;
      }
    }
    return false;
  }

  onSearch() {
    let body = {
      startDate: this.utilsService.formatLocalDateTime(this.startDate),
      endDate: this.utilsService.formatLocalDateTime(this.endDate)
    };
    this.getData(body);
  }
}
