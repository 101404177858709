<div class="parent-wrapper horizontal-center-pos">
    <div class="parent-wrapper-inside global-text">
        <div class="row mb-9">
            <app-header [searchingSuggestionData] = "searchingSuggestionData"
            (selectRole)="onRoleChange($event)"
            (search)="onSearch($event)"></app-header>
        </div>
        <div class="row w-full">
            <div class="w-full p-2.5 mt-5">
                <div class="map" id="map">
                    <google-map
                                height="830px"
                                width="1520px"
                                [center]="center"
                                [zoom]="zoom"
                                (mapClick)="addMarker($event)">
                    <map-marker *ngFor="let markerPosition of markerPositions"
                                [position]="markerPosition"
                                [options]="markerOptions" (mapMouseover)="markerMouseover($event)" [title]="markerTitle(markerPosition)"
                                (mapClick)="markerClick(markerPosition)"></map-marker>
                    </google-map>
                </div>
              <div>
                <form [formGroup]="searchingForm">
                  <!-- <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">{{
                    "searchLbl" |
                      translate }}</label>
                  <div class="absolute flex top-[15%] left-[2%] w-[40%]">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor"
                           viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                      </svg>
                    </div>
                    <input id="default-search"
                           class="block w-full pt-3 pb-3 pl-12 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                           placeholder="{{'searchplacehoder' | translate}}" formControlName="searchInput" required [(ngModel)]="keySearch" />
                      <button (click)="handleSearch()"
                              class="text-white right-0 h-[55px] rounded-l-none absolute bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        {{ "searchLbl" | translate }}
                      </button>
                  </div> -->

                  <!-- <div class="absolute flex flex-wrap top-[23%] items-center left-[2%] w-[50%] gap-3 mt-2">
                    <div *ngFor="let item of jobSuggest">
                      <button
                        class="flex justify-center items-center gap-2 px-4 py-1 rounded-lg bg-[--white] hover:bg-[--border-history]"
                        style="border: 1px solid rgba(0, 0, 0, 0.1);" (click)="reloadMap(item)">
                        <span
                          class="font-body font-normal not-italic text-sm text-center text-[--base-ink-border] hover:text-[--blue-text]">{{
                          item.name }}</span>
                      </button>
                    </div>
                  </div> -->

                  <div class="absolute flex top-[17.5%] left-[2%] w-[40%]">
                    <div class="flex items-center border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 pr-2">
                      <!-- Search input -->
                      <div class="relative flex items-center">
                            <app-selection class="block w-48 md:w-72 py-2 pl-1"
                            [selectedItem]="selectedItem" [items]="jobDefaults" [pageMenu]="pageMenu" (onChangeSelected)="onSelectItem($event)" (refetchScrollMenu)="refetchScrollMenu($event)">
                            </app-selection>
                      </div>
                  
                      <!-- Divider -->
                      <div class="flex items-center ml-2">
                          <div class="h-6 border-l border-gray-300 dark:border-gray-600"></div>
                      </div>
                  
                      <!-- Dropdown menu -->
                      <div class="relative flex items-center">
                          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                              <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z"></path>
                                  <circle cx="12" cy="9" r="2" fill="currentColor"></circle>
                              </svg>
                          </div>
                            <app-selection-menu class="block w-32 md:w-48 py-2 pl-2 pr-2"
                            [selectedItem]="selectedProvince" [items]=" provinces" (onChangeSelected)="onProvinceSelected($event)">
                            </app-selection-menu>
                      </div>
                  
                      <!-- Search button -->
                      <button style="background-color: var(--blue-text);" class="rounded-lg flex py-2 px-4 items-center justify-center gap-2 text-white" (click)="handleSearch()">
                          <span class="items-center font-body font-semibold text-base text-white">
                              <svg aria-hidden="true" class="w-5 h-5 text-white-500 dark:text-white-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                              </svg>
                          </span>
                      </button>
                  </div>      
                </div>
                </form>
              </div>
            </div>
        </div>
    </div>
    <app-loading-global ></app-loading-global>
</div>
<div *ngIf="role === CANDIDATE_ROLE">
  <app-popup-detail-item (applyInform)="showInfoSuccessfullyApply($event)"
                         (openPopupConfirm)="showPopupConfirmApply($event)" #popupDetailItem></app-popup-detail-item>
  <app-popup-confirm-apply (validate)="onValidate($event)" #popupConfirmApply></app-popup-confirm-apply>
  <app-popup-job-finding #jobFinding></app-popup-job-finding>

</div>
<div *ngIf="role === RECRUITER_ROLE">
  <app-popup-info-candidate  [parentPageCode]="APP_CANDIDATE_ITEMS_LIST"  #popupInfoCandidate></app-popup-info-candidate>

</div>


