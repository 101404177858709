import {
  Component,
  ViewChild,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { Constants } from 'src/app/constant/Constants';
import { ConstantsApp } from 'src/app/constant/ConstantsApp';
import { PopupRechargeComponent } from './popup-recharge/popup-recharge.component';
import { environment } from 'src/environments/environment';
import { ApiNameConstants } from 'src/app/constant/ApiNameConstants';
import { CommonService } from 'src/app/service/common.service';
import { HttpClient,HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-joberwallet-candidate',
  templateUrl: './joberwallet.component.html',
  styleUrls: ['./joberwallet.component.scss'],
})
export class JoberWalletCandidateComponent implements OnInit {
	dataTable: any;
	arrPage:any
	dataOrg:any;
  user: any;
  @ViewChild('popupRecharge') popupRecharge: PopupRechargeComponent =
    new PopupRechargeComponent();
  displayedColumns: any = [];
  pageNum: number = 1;
	constructor(
    private commonService: CommonService,
    private http: HttpClient
  ) {
  }
  ngOnInit(): void {
    this.init();
		this.getData();
    this.getWalletUser();
  }

  init() {
    this.displayedColumns = [
      {
        name: Constants.transactionDateLbl,
        key: ConstantsApp.creationDate,
        isAsc: true,
      },
      {
        name: Constants.transactionNameLbl,
        key: ConstantsApp.actionName,
        isAsc: false,
      },
      {
        name: Constants.transPointLbl,
        key: ConstantsApp.transferredPoint,
        isAsc: false,
      },
      {
        name: Constants.transMoneyLbl,
        key: ConstantsApp.transferredMoney,
        isAsc: false,
      }
    ];
  }

  openPopupRecharge() {
    this.popupRecharge.openPopup();
  }
	ngOnChanges() {
    this.initData();
  }
	initData() {
		this.dataTable = [];
    this.arrPage = [];
	}
  
	getData() {
		const functionName = 'getData';
    const messageLog = 'get transaction-history';
    const apiUrl = environment.API_URI + ApiNameConstants.BS_PAYMENT_TRANSACTION_HISTORY
    let body = {
      page: this.pageNum,
      size: ConstantsApp.PAGE_SIZE
    }
    this.commonService
      .postDatas(body, apiUrl, functionName, messageLog)
      .subscribe((res: any) => {
        this.dataOrg = res;
        console.log("ádsadsd",this.dataOrg);
    });
  }

  getWalletUser(){
    const functionName = 'getWalletUser';
    const messageLog = 'get wallet of user';

    // lấy data của account đang đăng nhập
    const getUserLocalstorage = localStorage.getItem(ConstantsApp.user);
    if(getUserLocalstorage){
      this.user = JSON.parse(getUserLocalstorage);
    }

    const apiUrl = environment.API_URI + ApiNameConstants.BS_PAYMENT_WALLET_BY_USER;
    // console.log("API: "+ apiUrl);
    // lấy data từ API
    return this.http.get<any>(apiUrl).toPromise()
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          // console.log(data);
            this.user.id = data.id;
            this.user.totalMoney = data.totalMoney.toLocaleString('en-US');
            this.user.totalPoint = data.totalPoint;
            this.user.bankAccount = data.bankAccount;
            this.user.creationDate = data.creationDate;
        }
      })
      .catch((error) => {
        console.error('Error fetching wallet data:', error);
        throw error;
      });
  }
  changePage(currentPage: any) {
    this.pageNum = currentPage;
    this.getData();
  }
}
