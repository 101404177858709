<div class="row">
  <div class="col-md-3 col-3">
    <img src="../assets/img/delivery.png" alt="">
  </div>
  <div class="col-md-9 col-9 title global-text-bold">
    {{item.name}}
  </div>
</div>
<div class="row py-2">
  <div class="col-md-2 col-4 btn-wrapper small-btn-blue-rounder salary-wrapper">
    <span class="">{{item.salary}}</span>
  </div>
  <div class="col-md-9 col-4">
    <button *ngIf="item.workingType"
    type="button" 
    class="small-btn-blue-rounder"
    >{{item.workingType}}</button>
  </div>
  <div class="col-md-1 col-4 text-end">
    <i class="fa-regular fa-heart heart-color"></i>
  </div>
</div>

<!-- 
<div class="job-candidate-item-wrapper global-text">
  <div class="row header-item">
    <div class="image-item-wrapper col-xl-1">
      <img src="../assets/img/delivery.png" alt="">
    </div>
    <div class="title col-xl-11 global-text-bold">{{item.name}}</div>
  </div>
  <div class="row footer-item">
    <div class="col-xl-2 btn-wrapper small-btn-blue-rounder salary-wrapper">
      <span class="">{{item.salary}}</span>
    </div>
    <div class="btn-wrapper col-xl-9" >
      <button *ngIf="item.workingType"
        type="button" 
        class="small-btn-blue-rounder"
        >{{item.workingType}}</button>
    </div>
    <div class="btn-wrapper col-xl-1">
      <i class="fa-regular fa-heart heart-color"></i>
    </div>
  </div>
</div> -->