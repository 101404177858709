<div class="management-container">
    <div class="header-up-wrapper row">
        <app-header-up (activeLink)="onActiveLink($event)"></app-header-up>
    </div>
    <div class="management-container-main mx-auto pl-2 pr-4" style="background-color: var(--background-admin);">
        <div class="management-container-left">
            <app-sidebar #appSideBar [isExpanded]="sidebarExpanded" (toggleSidebar)="sidebarExpanded = !sidebarExpanded"
                (activeLink)="onActiveLink($event)"></app-sidebar>
        </div>
        <div class="management-container-right lg:mt-2 ">
            <div *ngIf="routerLinkNames.referralProgram">
                <app-referral-program-candidate isEditMode="true"></app-referral-program-candidate>
            </div>
            <div *ngIf="routerLinkNames.joberWallet">
                <app-joberwallet-candidate isEditMode="true"></app-joberwallet-candidate>
            </div>
            <div *ngIf="routerLinkNames.mainScreen">
                <app-profile-candidate (uploadAvatar)="uploadAvatar($event)"  isEditMode="true"></app-profile-candidate>
            </div>
            <div *ngIf="routerLinkNames.listJob">
                <app-listjob-candidate isEditMode="true"></app-listjob-candidate>
            </div>
            <div *ngIf="routerLinkNames.posts">
                <app-candidate-posts isEditMode="true"></app-candidate-posts>
            </div>
            <div *ngIf="routerLinkNames.calendar">
                <app-calendar isEditMode="true"></app-calendar>
            </div>
            <div *ngIf="routerLinkNames.listSavedJob">
                <app-saved-jobs isEditMode="true"></app-saved-jobs>
            </div>
        </div>
		
    </div>
	<app-loading></app-loading>

	
</div>
<app-footer></app-footer>
