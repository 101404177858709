<div class="dropdown-wrapper">
    <!-- <div class="list-items global-text color-light-dart-1"  *ngIf="isDropdown && items && items.length != 0" > -->
        <div class="list-items global-text color-light-dart-1">
        <ul class="global-text">
            <li class="item cursor-pointer" *ngFor="let item of items" (click)="onSelected(item)">
                <i *ngIf="item.class_icon" class="{{item.class_icon}}" ></i>
                <span class="global-text-medium-size color-light-dart-1">{{item.val}}</span>
            </li>
        </ul>
    </div>
</div>