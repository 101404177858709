<div>
  <div *ngIf="ratingNum == 5" class="flex flex-row justify-center items-center gap-20">
    <img src="/assets/icons/rating.svg" />
  </div>
  <div *ngIf="ratingNum == 4" class="flex flex-row justify-center items-center gap-20">
    <img src="/assets/icons/rating4.svg" />
  </div>
  <div *ngIf="ratingNum == 3" class="flex flex-row justify-center items-center gap-20">
    <img src="/assets/icons/rating3.svg" />
  </div>
  <div *ngIf="ratingNum == 2" class="flex flex-row justify-center items-center gap-20">
    <img src="/assets/icons/rating2.svg" />
  </div>
  <div *ngIf="ratingNum == 1" class="flex flex-row justify-center items-center gap-20">
    <img src="/assets/icons/rating1.svg" />
  </div>
  <div *ngIf="ratingNum == 0" class="flex flex-row justify-center items-center gap-20">
    <img src="/assets/icons/rating0.svg" />
  </div>
</div>
