export class ApiNameConstants {
  public static BS_USER_SEARCH = 'bs-user/user_common/_search';
  public static BS_FREELANCER_SEARCH = 'bs-user/freelancer/_search';
  public static BS_USER_CANDIDATE = 'recruiter/candidates';
  public static BS_USER_SIGNIN = 'bs-user/login';
	public static BS_USER_COMMON = 'bs-user/user_common/profile';
	public static BS_USER_LOGOUT = 'bs-user/user_common/logout';
  public static BS_USER_UPDATE = 'bs-user/user_common/_update';
  public static BS_USER_CHANGE_PASS = 'bs-user/user_common/_change_pass';
  public static BS_USER_FORGET_PASS = 'bs-user/user_common/_forget_pass';

  public static BS_USER_CREATE = 'bs-user/user_common/_create';
  public static BS_SEARCH_SEARCHING_ALL = 'bs-search/getDataSearch';
  public static BS_GET_ALL_PROVINCES = 'bs-address/_provinces';
  public static BS_GET_DISTRICS_BY_PROVINCE =
    'bs-address/_districts?provinceCode=';
  public static BS_GET_WARDS_BY_DISTRICT = 'bs-address/_wards?districtCode=';

  public static BS_CREATE_FREELANCER = 'bs-user/candidate/cv';
  public static BS_CREATE_RECRUITER = 'recruiter/jd';
  public static BS_GET_SETTING = 'bs-admin/getSettings';
  public static BS_UPDATE_SETTING = 'bs-admin/updateSettings';
  public static BS_ADMIN_BONUS_FOR_USER_INFO = 'bs-admin/bonusForUser';
  public static BS_ADMIN_UPDATE_BONUS_FOR_USER = 'bs-admin/updateBonusForUser';
  public static BS_JOB_SEARCH = 'bs-user/bs-job/_search';
  public static BS_JOB_SEARCH_NEAR_BY = 'bs-user/bs-job/_search_jobs_near_by';
  public static BS_RECRUITER_CONTACTED_CANDIDATE =
    'recruiter/candidates/contacted';
  public static BS_RECRUITER_SAVED_CANDIDATE = 'recruiter/candidates/saved';
  public static BS_RECRUITER_PASSED_CANDIDATE = 'recruiter/candidates/selected';
  public static BS_RECRUITER_REJECTED_CANDIDATE = 'recruiter/candidates/refuse';
  public static BS_RECRUITER_SIGNED_CANDIDATE = 'recruiter/candidates/signed';
  public static BS_RECRUITER_RANK = 'recruiter/rank';
  public static BS_RECRUITER_JOBS_BY_USER = 'recruiter/jobs-by-user';
  public static BS_RECRUITER_SAVE_CANDIDATE = 'recruiter/_update_note';
  public static BS_CANDIDATE_SAVE_JOB =
    'bs-user/candidate/candidate_management/_save';
  public static BS_MATCH_JOB = 'bs-user/candidate/jobs';
  public static BS_APPLIED_JOB = 'bs-user/candidate/jobs/apply';
  public static BS_INTERVIEW_JOB = 'bs-user/candidate/jobs/interview';
  public static BS_SAVED_JOB = 'bs-user/candidate/jobs/save';
  public static BS_POSTED_JOB = 'bs-user/candidate/cv';
  public static BS_JOB_LATEST = 'bs-user/bs-job/_latest_jobs';
  public static BS_FREELANCER_LATEST = 'bs-user/freelancer/_latest_jobs';
  public static BS_JOB_DEFAULT_SEARCH = 'bs-configuration/job_default/_search';
  public static BS_JOB_CHILDREN_SEARCH = 'bs-configuration/job_default/_search_with_children';
  public static BS_LIST_JOBDEFAULT_REMAIN = 'bs-configuration/job_default/list_remain';
  public static BS_JOB_APPLY = 'bs-user/bs-job/_apply_job';
  public static BS_SCHEDULE_CALENDAR = 'bs-user/schedule/_calendar';
  public static BS_PAYMENT_TRANSACTION_HISTORY = 'bs-payment/trx-his/transaction-history';
  public static BS_USER_INTRODUCED_USERS = 'bs-user/introduced-users';
  public static BS_USER_JOBS_OF_CANDIDATE = 'bs-user/candidate/candidate_management/_jobs_of_candidate';
  public static BS_USER_FIND_FREELANCER_BY_USER_ID = 'recruiter/candidates/find_by_user_id';
  public static BS_USER_SCHEDULE_SAVE = 'bs-user/schedule/_save';
  public static BS_USER_FREELANCER_CREATE = 'bs-user/freelancer/_create';
  public static BS_USER_UPLOAD = 'bs-user/upload';
  public static BS_USER_GET_JOB_BY_ID = 'bs-user/candidate/candidate_management/get_job_by_id';
  public static BS_USER_FIND_FREELANCER_BY_USER_AND_JOB_DEFAULT = 'bs-user/freelancer/_get_by_user_and_job_default';
  public static BS_USER_FIND_SCHEDULE_BY_ID = 'bs-user/schedule/get_by_id';
  public static BS_USER_SAVED_JOBS = 'bs-user/candidate/saved_jobs';
	public static BS_USER_RECRUITER_MANAGEMENT_SAVE = 'bb-user/recruiter/management_save';
  public static BS_USER_GET_APPLIED_CANDIDATE = 'bs-user/recruiter/_get_applied_candidate';
  public static BS_CONFIGURATION_PACKAGE_SEARCH = 'bs-configuration/package/_search';
  public static BS_PAYMENT_CREATE = 'bs-payment/vnpay/create-payment';
  public static BS_PAYMENT_WALLET_BY_USER = 'bs-payment/wallet/_get_by_user_phone';
  public static BS_PAYMENT_WALLET_SAVE = 'bs-payment/wallet/_save';
  public static BS_PAYMENT_TRX_HIS_SAVE = 'bs-payment/trx-his/_save';
  public static BS_USER_FREELANCER_GET_BY_ID = 'bs-user/freelancer/_get_by_id';
  public static BS_USER_RECRUITER_GET_POSTS = 'bs-user/recruiter/posts';
  public static BS_SAVE_JOB = 'bs-user/bs-job/_save';
  public static BS_JOB_GET_BY_ID = 'bs-user/bs-job/_get_by_id';
  public static BS_JOB_GET_BY_JOBDEFAULT_ID = 'bs-user/bs-job/_get_by_jobdefault_id';
  public static OAUTH2_AUTHORIZATION_GOOGLE = 'oauth2/authorization/google';
  public static OAUTH2_AUTHORIZATION_FACEBOOK = 'oauth2/authorization/facebook';
  public static BS_USER_CANDIDATE_POSTS = 'bs-user/freelancer/_posts';
  public static BS_USER_FILE_DOWNLOAD = 'bs-user/file/download';
  public static BS_CANDIDATE_DELETE_SAVED_JOB = 'bs-user/candidate/candidate_management/_delete';
  public static BS_SCHEDULE_DELETE = 'bs-user/schedule/_delete';
  public static BS_PAYMENT_FAILED = 'payment-failed';
  public static BS_FREELANCER_DELETE = 'bs-user/freelancer/_delete';
  public static BS_SEARCH_ORG = 'bs-search/organization/_search';
}
