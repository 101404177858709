<!--this make call twice when click choose an item-->
<div class="radio-button-wrapper">
	<div class="radio-role" *ngFor="let item of items; index as indexOfElement;" (click)="onChecked(item)">
		<input type="radio" name="fav_language" [value]="item.val" [checked]="item.checked" [id]="'radio_' + indexOfElement"
			class="cursor-pointer">
		<label [for]="'radio_' + indexOfElement">{{item.label | translate}}</label><br>
	</div>
</div>

<!--<div class="radio-button-wrapper">-->
<!--  <div class="radio-role" *ngFor="let item of items; index as indexOfelement;" (click)="onChecked(item)">-->
<!--    <div>-->
<!--      <input type="radio" name="fav_language" value="item.val" [checked]="item.checked" class="cursor-pointer">-->
<!--      <label>{{item.label|translate}}</label><br>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
