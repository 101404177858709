<div class="popup-container" id="popup-search">
	<div class="modal hidden">
    <div class="modal-inner center-pos row" *ngIf="!isDisplayAddressForm">
      <div class="flex px-3 py-2 items-center gap-5"
           style="border-radius: 8px 8px 0px 0px; background: var(--blue-text);">
        <!-- <button (click)="onPrevious()"><img src="../../../../../assets/icons/arrow-left.svg"
                                            alt="icon-arrow-left">
        </button> -->
        <div class="flex items-center justify-center gap-28 flex-1">
          <div class="flex items-center gap-5 text-white">
						<span class="font-body font-semibold text-xl">{{ "searchJobDefaultLbl"
              | translate }}</span>
          </div>
        </div>
        <button (click)="closePopup()"><img src="../../../../../assets/icons/close.svg" alt="icon-close">
        </button>
      </div>
      <div class="popup-content p-4">
        <div class="w-full">
          <app-selection class="w-full" [selectedItem]="selectedItem" [items]="itemsList"
                         (onChangeSelected)="onSelectItem($event)" (refetchMenu)="refetchSelectMenu($event)"></app-selection>
        </div>
      </div>
      <div class="flex justify-items-center m-5">
        <a
          (click)="onNext()"
          class="md:block hidden text-white bg-blueprimary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-semi-bold rounded-lg text-14 leading-20 px-4 lg:px-5 py-2 lg:py-2.5 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800 w-28 cursor-pointer">
          {{ "continueLbl" | translate }}
        </a>
      </div>
    </div>

    <div class="modal-inner center-pos row" style="width: 60%;" *ngIf="isDisplayAddressForm">
      <div class="w-full">
        <div class="flex px-3 py-2 items-center gap-5"
             style="border-radius: 8px 8px 0px 0px; background: var(--blue-text);">
          <button (click)="onPrevious()"><img src="../../../../../assets/icons/arrow-left.svg"
                                              alt="icon-arrow-left">
          </button>
          <div class="flex items-center justify-center gap-28 flex-1">
            <div class="flex items-center gap-5 text-white">
							<span class="font-body font-semibold text-xl">{{ "searchJobDefaultLbl"
                | translate }}</span>
            </div>
          </div>
          <button (click)="closePopup()"><img src="../../../../../assets/icons/close.svg" alt="icon-close">
          </button>
        </div>
        <div class="flex flex-col justify-between px-2 gap-7">
          <div class="flex flex-row mt-8">
						<span class="font-body font-semibold text-3xl not-italic text-black">
							{{ "JobselectLbl" | translate}}:
						</span>
            <span class="font-body font-semibold text-3xl not-italic" style="color: var(--blue-text);">
							&nbsp;{{selectedItem.name}}
						</span>
          </div>

          <span class="font-body font-semibold not-italic text-base" style="color: #082639;">{{
            "enterLocationLbl" | translate }}</span>
          <div class="flex justify-between px-2">
            <div class="w-5/12">
              <div class="mb-3 w-full">
                <div class="form-label">
									<span class="color-ink-text font-size-small font-weight-bold">{{"provinceLbl" |
                    translate }}</span>
                </div>
                <app-selection-menu class="w-full" [isDisable]="!isEditMode"
                                    [selectedItem]="selectedProvince" [items]="provinces"
                                    (onChangeSelected)="onProvinceSelected($event)"></app-selection-menu>
              </div>
            </div>
            <div class="w-5/12">
              <div class="mb-3 w-full">
                <div class="form-label"><span class="color-ink-text font-size-small font-weight-bold">{{
                  "districtLbl" | translate }}</span>
                </div>
                <app-selection-menu class="w-full" [isDisable]="!isEditMode"
                                    [selectedItem]="selectedDistrict" [items]="districts"
                                    (onChangeSelected)="onDistrictSelected($event)"></app-selection-menu>
              </div>
            </div>
          </div>
          <div class="flex justify-between px-2">
            <div class="w-5/12">
              <div class="mb-3 w-full">
                <div class="form-label"><span
                  class="color-ink-text font-size-small font-weight-bold">{{"wardLbl" | translate
                  }}</span>
                </div>
                <app-selection-menu [isDisable]="!isEditMode" [selectedItem]="selectedWard"
                                    [items]="wards" (onChangeSelected)="onWardSelected($event)"></app-selection-menu>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isDisplayAddressForm" class="flex flex-col justify-center items-center gap-2 mb-4">
        <button style="background-color: var(--blue-text);"
                class="rounded-lg flex py-2 px-4 items-center justify-center gap-2 text-white">
				<span class="items-center font-body font-semibold text-base text-white" (click)="onValidate()">{{
          "searchLbl"
            | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
