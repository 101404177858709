import { Injectable, Injector} from "@angular/core";
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { LocalStorageService } from 'src/app/core/auth/local-storage.service';
import { Constants } from 'src/app/constant/Constants';
import { ConstantsApp } from 'src/app/constant/ConstantsApp';
import { AuthService } from 'src/app/core/auth/auth.service';
import { DatePipe } from '@angular/common';
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root',
  })
export class UtilsService {
  datePipe = this.injector.get(DatePipe);
  decimalPattern = /^\d+(\.\d{1,2})?$/;
  numberPattern = /^[1-9]\d*$/;
  phonePattern = /^(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})$/;
  emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
  passwordPattern =/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  addressPattern = /^[\w\s]+,\s[\w\s]+,\s[\w\s]+$/;
  constructor(private localStorageService: LocalStorageService,
    private authService: AuthService,
    private injector: Injector
    ) {
  }
  isValidDOB(dob: string) {
    let param: any = new Date(dob);
    let currentDate: any = new Date();
    let diffYears = Math.floor((currentDate - param) / (1000 * 60 * 60 * 24 * 365));
    return diffYears >= 15 && diffYears <= 60;
  }
  isEmpty(val: any): boolean {
    // if (val == null || val == 'undefined' || val == '' || val.length == 0) {
    if (val == null || val == 'undefined' || val == '') {
        return true;
    }
    return false;
  }

  requestHeader(): HttpHeaders {
      let headers = new HttpHeaders();
      headers = headers.append('content-type','application/json');
      headers = headers.append('Access-Control-Allow-Origin', '*');
      return headers;
  }

  requestHeaderAuth(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('content-type','application/json');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append(Constants.authorization, ConstantsApp.bearer + this.authService.getAccessToken());
    return headers;
  }

/**
 *
 * @param paramsMap Map<string, string>
 * @returns
 */
  requestParam(paramsMap: any): HttpParams {
      let params = new HttpParams();
      let keys = Object.keys(paramsMap);
      keys.forEach(key => {
        let val = paramsMap[key];
        params.set(key, val);
      });
      return params;
  }

  getRandomInt() {
    let min = 100000, max = 999999;
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }

  getRole() {
    let user = this.localStorageService.getItem(ConstantsApp.user);
    if (!user) {
      let role = {
        isCandidate: false,
        isRecruiter: true,
      }
      return role;
    }
    const roleVal = user.role;
    let role = {
      isCandidate: false,
      isRecruiter: false,
    }
    switch (roleVal) {
      case ConstantsApp.CANDIDATE: role.isCandidate = true; break;
      case ConstantsApp.RECRUITER: role.isRecruiter = true; break;
    }
    return role;
  }

  getRoleNumber() {
    let user = this.localStorageService.getItem(ConstantsApp.user);
    if (!this.isEmpty(user)) {
      return user.role;
    }
    return 0;
  }

  /**
   * compare 2 objects
   * @param o1
   * @param o2
   */
  isObjectEqual(o1: any, o2: any) {
    return Object.entries(o1).toString() === Object.entries(o2).toString()
  }

  formatDate(date: any) {
    if (this.isEmpty(date)) return;
    return this.datePipe.transform(date,"yyyy-MM-dd")
  }
  formatLocalDateTime(date: any) {
    if (this.isEmpty(date)) return;
    return this.datePipe.transform(date,"yyyy-MM-ddTHH:mm:ss")
  }
  formatTime(date: any) {
    if (this.isEmpty(date)) return;
    return this.datePipe.transform(date,"HH:mm:ss")
  }
  setItem(key: any, val: any) {
    this.localStorageService.setItem(key, val);
  }
  getItem(key: any) {
    return this.localStorageService.getItem(key);
  }

  getValEncode(val:any) {
    return window.btoa(val);
  }
	getStartAndEndOfWeek(selectedDate: Date = new Date()): { startDate: string, endDate: string } {
    const dayOfWeek = selectedDate.getDay();
    const diff = selectedDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust to Monday or Sunday accordingly

    const startDate = new Date(selectedDate);
    startDate.setDate(diff);

    const endDate = new Date(selectedDate);
    endDate.setDate(diff + 6);

    return {
      startDate: this.datePipe.transform(startDate, 'yyyy-MM-dd') || '',
      endDate: this.datePipe.transform(endDate, 'yyyy-MM-dd') || '',
    };
	}
  /**
   *
   * @param data , can be province or wards or districts
   * @param name
   * @returns
   */
  getAddressByName(data: any, name: any) {
    if (!data || !name) return '';
    for (let i = 0; i < data.length; i ++) {
      let item = data[i];
      name = name.toLowerCase();
      let itemName = item.name.toLowerCase();
      if (itemName.includes(name) || name.includes(itemName)) {
        return item;
      }
    }
  }

  buildSelectedItem(key: any, items: any, object: any) {
    if (!key || !items || !object) return null;
    let selectedItem = null;
    if (!object || !object[key]) return;
    let val = object[key];
    for (let i = 0; i < items.length; i ++) {
        if (items[i].code == val) {
            selectedItem = items[i];
            break;
        }
    }
    return selectedItem;
  }

  getAddress(province: any, district: any, ward: any) {
    return province + ' - ' + district + ward? '' : ' - ' + ward;
  }
  sortASC(a: any, b: any, key: any) {
    if (a[key] > b[key]) {
      return 1;
    }
    if (a[key] < b[key]) {
      return -1;
    }
    return 0;
  }
  sortDESC(a: any, b: any, key: any) {
    if (a[key] > b[key]) {
      return -1;
    }
    if (a[key] < b[key]) {
      return 1;
    }
    return 0;
  }

  getGender(type: number) {
    if (type == ConstantsApp.MALE) {
      return Constants.maleLbl;
    }
    if (type == ConstantsApp.FEMALE) {
      return Constants.femaleLbl;
    }
    if (type == ConstantsApp.OTHER) {
      return Constants.otherLbl;
    }
    return null;
  }

  public shuffleArray(jobDefaults: any) {
    console.log('Shuffle');
    const shuffledArray = jobDefaults.slice(); // Create a copy of the array to avoid modifying the original
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  }
}
