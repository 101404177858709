<div>
	<div class="">
		<div class="table-wrapper overflow-auto">
			<table id="dtOrderExample" class="table table-striped table-bordered table-sm dataTable table-body-wrapper"
				cellspacing="0" width="100%">
				<thead>
					<tr class="tr-header-wrapper">
						<th class="heading-table md:w-[100px] w-[150px]">
							<div class="col-title-wrapper flex items-center md:w-[40px] w-[40px] justify-center">
								<span>{{ sequenceIndexLbl }}</span>
							</div>

						</th>
						<th *ngFor="let col of displayedColumns" class="sorting">
							<div class="col-title-wrapper flex items-center md:w-[100px] w-[150px] " ngClass="{{ col.key }}">
								<div class="col-name-wrapper">
									<span class="font-body not-italic text-base font-semibold" style="color: #082639;">{{
										col.name }}</span>
									<div class="" *ngIf="col.key == selectField">
										<input type="checkbox" class="form-check-input form-check-input-all" id=""
											(click)="onCheckAll($event)" />
									</div>
								</div>
								<div class="px-2" *ngIf="isNotInFields(col.key)">
									<i *ngIf="col.isAsc" class="fa-solid fa-sort-down sort-icon mb-1"
										(click)="onSortDESC(col, false)"></i>
									<i *ngIf="!col.isAsc" class="fa-solid fa-sort-up sort-icon mt-2" (click)="onSortASC(col, true)"></i>
								</div>
							</div>
						</th>
						<th class="heading-table md:w-[100px] w-[150px]">
							<div class="col-title-wrapper flex items-center md:w-[40px] w-[40px] justify-center">
								<span>{{"CV"}}</span>
							</div>
						</th>
						<th class="flex justify-center items-center">
							<input type="checkbox" class="form-check-input form-check-input-all" id="" (click)="onCheckAll($event)" />
						</th>
					</tr>
				</thead>
				<tbody class="items-center justify-center">
					<tr class="" *ngFor="let rowData of dataTable; index as i" [hidden]="shouldHideRow(rowData)" (click)="openPopupInfoCandidate(rowData)">
						<td class="" style="--bs-table-accent-bg: white">
							<div class="col-title-wrapper flex items-center md:w-[40px] w-[40px] justify-center">
								<span>{{ (currentPage - 1) * pageSize + i + 1 }}</span>
							</div>
						</td>
						<td class="" *ngFor="let col of displayedColumns" style="--bs-table-accent-bg: white">
							<div *ngIf="col.key != statusField">
								<span>{{rowData[col.key]}}</span>
							</div>
							<div *ngIf="col.key == statusField">
								<span *ngIf="rowData[col.key] == 0">{{"inProgressingLbl" | translate}}</span>
								<span *ngIf="rowData[col.key] == -2">{{"canceled" | translate}}</span>
								<span *ngIf="rowData[col.key] == -1">{{"failed" | translate}}</span>
								<span *ngIf="rowData[col.key] == 1">{{"applied" | translate}}</span>
								<span *ngIf="rowData[col.key] == 2">{{"chosen" | translate}}</span>
								<span *ngIf="rowData[col.key] == 3">{{"accepted" | translate}}</span>
								<span *ngIf="rowData[col.key] == 5">{{"passed" | translate}}</span>
								<span *ngIf="rowData[col.key] == 6">{{"contacted" | translate}}</span>
								<span *ngIf="rowData[col.key] == 7">{{"done" | translate}}</span>
							</div>
						</td>
						<td class="md:px-2 px-1">
							<button [disabled]="!rowData.cv" [ngClass]="{'bg-disable !border-none': !rowData.cv}" (click)="onDownloadCv(rowData);$event.stopPropagation()" style="border: 1px solid #5B91FB;"
									class="rounded  w-auto flex justify-center items-center gap-2 py-1 px-2 ">
							  <p [ngClass]="{'!text-[#9a9ba0] no-underline': !rowData.cv}" class="flex items-center text-center font-body not-italic font-semibold text-xs underline"
								 style="color: #3276FA;">{{"uploadCV" | translate}}</p>
							</button>
						</td>
						<td class="md:px-2 px-1">
							<div class="flex justify-center">
								<input type="checkbox" class="checkbox-item form-check-input"
									(click)="onCheckBox($event, rowData);$event.stopPropagation()" />
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="flex flex-row items-center justify-center paginator-wrapper">
			<div class="pagination flex justify-end mt-4 sm:mt-0 mb-3 space-x-2">
				<a (click)="onPrePageClick()" class="px-3 py-1 border border-gray-300 rounded bg-white hover:bg-gray-200 disabled:opacity-50 cursor-pointer" [class.disabled]="!isPrePageActive()" aria-disabled="!isPrePageActive()">&laquo;</a>
				
				<ng-container *ngFor="let i of arrPage">
					<li class="page-item" *ngIf="isDisplayPageNumber(i)" (click)="onCurrentPageClick(i)">
						<a class="page-link px-3 py-1 border border-gray-300 rounded hover:bg-blue-500 hover:text-white {{ currentPage === i ? 'bg-blue-500 text-white' : 'hover:bg-gray-200' }}">{{ i }}</a>
					</li>
				</ng-container>
				
				<a (click)="onNextPageClick()" class="px-3 py-1 border border-gray-300 rounded bg-white hover:bg-gray-200 disabled:opacity-50 cursor-pointer" [class.disabled]="!isNextPageActive()" aria-disabled="!isNextPageActive()">&raquo;</a>
			</div>
		</div>
			
			
		</div>
	</div>
<!-- </div> -->
<app-popup-rating #popupRating></app-popup-rating>
<app-popup-info-candidate #popupInfoCandidate></app-popup-info-candidate>
<!-- <div>
	<div class="">
		<div class="table-wrapper">
			<table id="dtOrderExample" class="table table-striped table-bordered table-sm dataTable table-body-wrapper px-2"
				cellspacing="0" width="100%">
				<thead>
					<tr class="tr-header-wrapper">
						<th class="heading-table ">
							<span>{{ sequenceIndexLbl }}</span>
						</th>
						<th *ngFor="let col of displayedColumns" class="sorting">
							<div class="col-title-wrapper flex items-center md:w-[100px] w-[150px] justify-start"
								ngClass="{{ col.key }}">
								<div class="col-name-wrapper">
									<span class="font-body not-italic text-base font-semibold" style="color: #082639;">{{
										col.name }}</span>
									<div class="" *ngIf="col.key == selectField">
										<input type="checkbox" class="form-check-input form-check-input-all" id=""
											(click)="onCheckAll($event)" />
									</div>
								</div>
								<div class="px-2 flex justify-center items-center" *ngIf="isNotInFields(col.key)">
									<i *ngIf="col.isAsc" class="fa-solid fa-sort-down sort-icon mb-1"
										(click)="onSortDESC(col, false)"></i>
									<i *ngIf="!col.isAsc" class="fa-solid fa-sort-up sort-icon mt-2" (click)="onSortASC(col, true)"></i>
								</div>
							</div>
						</th>
					</tr>
				</thead>
				<tbody class="items-center justify-center">
					<tr class="" *ngFor="let rowData of dataTable; index as i" (click)="openPopupInfoCandidate(rowData);">
						<td class="">
							<span>{{i}}</span>
						</td>
						<td class="flex justify-center" *ngFor="let col of displayedColumns" style="--bs-table-accent-bg: white">
							<div *ngIf="col.key != statusField">
								<span>{{rowData[col.key]}}</span>
							</div>
							<div *ngIf="col.key == statusField">
								<span *ngIf="rowData[col.key] == 0">{{"inProgressingLbl" | translate}}</span>
								<span *ngIf="rowData[col.key] == -2">{{"canceled" | translate}}</span>
								<span *ngIf="rowData[col.key] == -1">{{"failed" | translate}}</span>
								<span *ngIf="rowData[col.key] == 1">{{"applied" | translate}}</span>
								<span *ngIf="rowData[col.key] == 2">{{"chosen" | translate}}</span>
								<span *ngIf="rowData[col.key] == 3">{{"accepted" | translate}}</span>
								<span *ngIf="rowData[col.key] == 5">{{"passed" | translate}}</span>
								<span *ngIf="rowData[col.key] == 6">{{"contacted" | translate}}</span>
								<span *ngIf="rowData[col.key] == 7">{{"done" | translate}}</span>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="flex flex-row items-center justify-center paginator-wrapper">
			<div class="pagination flex justify-content-end">
				<a href="#" (click)="onPrePageClick()">&laquo;</a>
				<li class="page-item" *ngFor="let i of arrPage" (click)="onCurrentPageClick(i)">
					<a class="page-link" *ngIf="isDisplayPageNumber(i)">{{ i }}</a>
				</li>
				<a href="#" (click)="onNextPageClick()">&raquo;</a>
			</div>
		</div>

	</div>
</div>
<app-popup-rating #popupRating></app-popup-rating>
<app-popup-info-candidate #popupInfoCandidate></app-popup-info-candidate> -->