<div class="popup-container popup-confirm-topup-account padding ">
  <div class="modal">
    <div class="modal-inner center-pos row">
      <div class="col-12  bg-blue rounded-top d-flex justify-content-center align-items-center position-relative h-1/4">
        <span class=" font-weight-bold global-text-color-white">{{"noticeLbl" | translate}}</span>
        <img type="button" (click)="closePopup()" src="../assets/icons/white-cancel.svg" class="position-absolute top-0 end-0 p-3" >
      </div>
      <div class="px-4 flex flex-col items-center gap-4">
        <span>{{"qaTopupLbl" | translate}}</span>
        <div class="flex items-center justify-center  gap-5">
          <button (click)="closePopup()" class="bg-gray-400 w-24 py-1 rounded text-white">{{"cancelLbl" | translate}}</button>
          <button (click)="onValidate()" class="bg-blue-500  w-24 py-1 rounded text-white">{{"acceptLbl" | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
