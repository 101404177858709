

<div class="flex flex-col w-full h-min bg-white bottom-0 items-start md:items-center pl-4 lg:pl-0">
  <div class="flex w-full justify-center">
    <div class="flex flex-col md:flex-row gap-5">
      <img src="../../../assets/img/savvycom-logo-1.png" class="mt-3 lg:w-auto h-12 lg:h-16"  alt="savvy Logo"/>
      <div class="flex flex-row-reverse gap-2 justify-center">
        <img src="../../../assets/img/jober-logo-1.png" class="mt-3 w-24 lg:w-36 h-12 lg:h-16" />
        <img src="../../../assets/img/jober-logo-2.png" class="mt-3 w-12 lg:w-16 h-12 lg:h-16" />
      </div>
    </div>
  </div>
    <div class="flex flex-col lg:flex-row pt-5  lg:w-full h-auto gap-2 lg:gap-0 mr-1 md:mr-0">

        <div class="flex items-center lg:w-1/2 justify-start lg:justify-center">
            <div class="flex flex-col items-start gap-2 w-auto h-full">
                <div class="flex flex-row gap-2 items-center">
                    <img class="w-3.5 h-3.5" src="../../../assets/img/mail-04.png" />
                    <div class="text-base"> Email:</div>
                    <span href="#" class="text-base underline text-blue-400 italic">job@savvycomsoftware.com</span>
                </div>
                <div class="flex flex-row gap-2 items-center">
                    <img class="w-4  h-4" src="../../../assets/img/fbicon.png" />
                    <div class="text-base">Facebook:</div>
                    <a href="#" class="text-base underline text-blue-400 italic">www.facebook.com/savvycomsoftware</a>
                </div>
                <div class="flex flex-row gap-2 items-center">
                  <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22 3.47059V20.5294C22 20.9194 21.845 21.2935 21.5692 21.5693C21.2935 21.8451 20.9194 22 20.5294 22H3.47056C3.08053 22 2.70648 21.8451 2.43069 21.5693C2.15491 21.2935 1.99997 20.9194 1.99997 20.5294V3.47059C1.99997 3.08056 2.15491 2.70651 2.43069 2.43073C2.70648 2.15494 3.08053 2 3.47056 2H20.5294C20.9194 2 21.2935 2.15494 21.5692 2.43073C21.845 2.70651 22 3.08056 22 3.47059V3.47059ZM7.88232 9.64706H4.94115V19.0588H7.88232V9.64706ZM8.14703 6.41176C8.14858 6.18929 8.10629 5.96869 8.02258 5.76255C7.93888 5.55642 7.81539 5.36879 7.65916 5.21039C7.50294 5.05198 7.31705 4.92589 7.1121 4.83933C6.90715 4.75277 6.68715 4.70742 6.46468 4.70588H6.41173C5.95931 4.70588 5.52541 4.88561 5.20549 5.20552C4.88558 5.52544 4.70585 5.95934 4.70585 6.41176C4.70585 6.86419 4.88558 7.29809 5.20549 7.61801C5.52541 7.93792 5.95931 8.11765 6.41173 8.11765V8.11765C6.63423 8.12312 6.85562 8.0847 7.06325 8.00458C7.27089 7.92447 7.46071 7.80422 7.62186 7.65072C7.78301 7.49722 7.91234 7.31346 8.00245 7.10996C8.09256 6.90646 8.14169 6.6872 8.14703 6.46471V6.41176ZM19.0588 13.3412C19.0588 10.5118 17.2588 9.41177 15.4706 9.41177C14.8851 9.38245 14.3021 9.50715 13.7798 9.77345C13.2576 10.0397 12.8142 10.4383 12.4941 10.9294H12.4117V9.64706H9.64703V19.0588H12.5882V14.0529C12.5457 13.5403 12.7072 13.0315 13.0376 12.6372C13.368 12.2429 13.8407 11.9949 14.3529 11.9471H14.4647C15.4 11.9471 16.0941 12.5353 16.0941 14.0176V19.0588H19.0353L19.0588 13.3412Z" stroke="#000000" stroke-linejoin="round"/>
                  </svg>
                  <div class="text-base">Linkedin:</div>
                  <a href="#" class="text-base underline text-blue-400 italic">www.linkedin.com/in/savvycomcareers</a>
                </div>
                <div class="flex flex-row gap-2 items-center">
                  <svg width="18px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.8217 5.1344C16.0886 4.29394 15.6479 3.19805 15.6479 2H14.7293M16.8217 5.1344C17.4898 5.90063 18.3944 6.45788 19.4245 6.67608C19.7446 6.74574 20.0786 6.78293 20.4266 6.78293V10.2191C18.645 10.2191 16.9932 9.64801 15.6477 8.68211V15.6707C15.6477 19.1627 12.8082 22 9.32386 22C7.50043 22 5.85334 21.2198 4.69806 19.98C3.64486 18.847 2.99994 17.3331 2.99994 15.6707C2.99994 12.2298 5.75592 9.42509 9.17073 9.35079M16.8217 5.1344C16.8039 5.12276 16.7861 5.11101 16.7684 5.09914M6.9855 17.3517C6.64217 16.8781 6.43802 16.2977 6.43802 15.6661C6.43802 14.0734 7.73249 12.7778 9.32394 12.7778C9.62087 12.7778 9.9085 12.8288 10.1776 12.9124V9.40192C9.89921 9.36473 9.61622 9.34149 9.32394 9.34149C9.27287 9.34149 8.86177 9.36884 8.81073 9.36884M14.7244 2H12.2097L12.2051 15.7775C12.1494 17.3192 10.8781 18.5591 9.32386 18.5591C8.35878 18.5591 7.50971 18.0808 6.98079 17.3564" stroke="#000000" stroke-linejoin="round"/>
                  </svg>
                  <div class="text-base">Tiktok:</div>
                  <a href="#" class="text-base underline text-blue-400 italic">www.tiktok.com/@savvycomsoftware</a>
                </div>
                <div class="flex flex-row gap-2 items-center">
                    <img class="w-3.5  h-3.5" src="../../../assets/img/hotline.png" />
                    <div class="text-base">Hotline:</div>
                    <span href="#" class="text-base underline text-blue-400 italic">032 657 2886</span>
                </div>
            </div>
        </div>
        <div class="flex lg:pl-12 w-full lg:w-1/2 gap-2 lg:items-start justify-center lg:justify-start ">
            <div class="flex flex-col gap-2 w-full lg:w-auto h-full items-start">
                <div class="flex flex-row gap-2 lg:items-center">
                    <img class="w-3.5  h-4" src="../../../assets/img/address.png" />
                    <div class="text-base md:flex md:flex-row mr-2">
                        <span class="mr-2">Địa Chỉ:</span>
                         <span href="#" class=" text-base underline text-blue-400 italic">
                        Level 7, Sky Park tower B, No. 3 Ton That Thuyet, Cau Giay, Hanoi, Vietnam.</span>
                    </div>


                </div>
                <div class="flex flex-row gap-2 lg:items-center">
                    <img class="w-3.5  h-4" src="../../../assets/img/license.png" />
                    <div class="text-base md:flex md:flex-row">
                        <span class="mr-2">Commercial License:</span>
                        <span href="#" class="text-base underline text-blue-400 italic">0108471484 issued by SKH & CN VN
                         </span>
                    </div>

                </div>
                <div class="flex flex-row gap-2  lg:items-center">
                    <img class="w-3.5  h-3.5" src="../../../assets/img/intellecutal.png" />
                    <div class="text-base md:flex md:flex-row gap-2 mr-2">
                        <span class="mr-2">Intellecutal Right Dep:</span>
                        <span href="#" class=" text-base underline text-blue-400 italic">77140/QD-SHTT 30-10-2018</span>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="flex w-full h-auto items-center justify-center pt-5 pb-2">
        <img class="w-36 h-auto" src="../../../assets/img/dabaoke.png" />
    </div>
</div>
