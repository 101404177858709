<div class="left-panel-wrapper"  *ngIf="isOpen">
    <div class="job-candidate-item-wrapper global-text">
        <div class="row header-item">
            <div class="image-item-wrapper col-xl-3">
                <img src="../assets/img/delivery.png" alt="">
            </div>
            <div class="title col-xl-8 global-text-bold">{{itemMarker.name}}</div>
            <div class="col-xl-1 horizontal-end-pos cursor-pointer">          
                <i class="fa-solid fa-xmark" (click)="closePopup()"></i>
            </div>
        </div>
        <div class="row content-item">{{itemMarker.name}}</div>
        <div class="row">
            <div class="col-xl-2 small-btn-blue-rounder salary-wrapper">
                <span class="">{{itemMarker.salary}}</span>
            </div>
            <div class="col-xl-2" *ngIf="itemMarker.type">
                <button 
                type="button" 
                class="small-btn-blue-rounder"
                >{{itemMarker.type}}</button>
            </div>
        </div>
        <div class="row address-wrapper">
            <div class="col-xl-2">
                <i class="fa-solid fa-location-dot"></i>
            </div>
            <div class="col-xl-10">
                <span>{{itemMarker.address}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-2">
                <i class="fa-solid fa-phone"></i>
            </div>
            <div class="col-xl-10">
                <span>{{itemMarker.phone}}</span>
            </div>
        </div>
        <div class="row des-job-wrapper">
            <!-- <span>{{itemMarker.des}}</span> -->
            <textarea>{{itemMarker.des}}</textarea>
        </div>
        <div class="row btn-wrapper justify-content-around">
            <div class="col-md-5">
                <button 
                type="button" 
                class="btn btn-primary"
                (click)="onApply()"
                >{{applyJobLbl}}</button>
            </div>

            <div class="col-md-5">
                <button 
                type="button" 
                class="btn btn-primary"
                (click)="saveJob()"
                >{{markLbl}}</button>
            </div>
        </div>
  </div>
</div>