<div class="left-panel-wrapper" *ngIf="isOpen">
    <div class="job-candidate-item-wrapper global-text">
        <div class="row header-item">
            <div class="col-xl-11"></div>
            <div class="col-xl-1 offset-xl-11 horizontal-end-pos cursor-pointer">          
                <i class="fa-solid fa-xmark" (click)="closePopup()"></i>
            </div>
        </div>
        <div class="row content-item horizontal-center-pos">
            <div class="row horizontal-center-pos">
                <img src="../assets/img/avatar.png" alt="">
            </div>
            <div class="row horizontal-center-pos global-text-bold">
                <span>{{itemMarker.name}}</span>
            </div>
        </div>
        
        <div class="row address-wrapper">
            <div class="col-xl-1">
                <i class="fa-solid fa-location-dot"></i>
            </div>
            <div class="col-xl-11">
                <span>{{itemMarker.address}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-1">
                <i class="fa-solid fa-phone"></i>
            </div>
            <div class="col-xl-11">
                <span>{{itemMarker.phone}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-2 small-btn-blue-rounder salary-wrapper">
                <span class="">{{itemMarker.salary}}</span>
            </div>
            <div class="col-xl-2" *ngIf="itemMarker.workingType">
                <button 
                type="button" 
                class="small-btn-blue-rounder"
                >{{itemMarker.workingType}}</button>
            </div>
        </div>
        <div class="row des-job-wrapper">
            <span>{{introYourselfLbl}}</span>
        </div>
        <div class="row detail-content">
            <textarea>{{itemMarker.des}}</textarea>
        </div>
        <div class="row btn-wrapper justify-content-around">
            <div class="col-md-5">
                <button 
              type="button" 
              class="btn btn-primary"
              (click)="contact()"
              >{{contactLbl}}</button>
            </div>

            <div class="col-md-5">
                <button 
              type="button" 
              class="btn btn-primary"
              (click)="saveCandidate()"
              >{{markLbl}}</button>
            </div>
          </div>
      </div>
</div>