import {Component, OnInit, OnChanges, ViewChild} from '@angular/core';
import { ConstantsApp } from 'src/app/constant/ConstantsApp';
import { UtilsService } from 'src/app/helper/service/utils.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SidebarRecruiterComponent} from "../../layout/sidebar-recruiter/sidebar-recruiter.component";
import {LocalStorageService} from "../../core/auth/local-storage.service";
import {TranslateService} from "@ngx-translate/core";
@Component({
  selector: 'app-management',
  templateUrl: './candidate.component.html',
  styleUrls: ['./candidate.component.scss'],
})
export class CandidateComponent implements OnInit, OnChanges {
  routerLinkNames: any;
  role: any;
  sidebarExpanded: boolean = false;
  @ViewChild('appSideBar') appSideBar: SidebarRecruiterComponent = new SidebarRecruiterComponent(this.utilsService, this.localStorageService, this.translate, this.router);

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit() {
    this.init();
    let currentPath = window.location.pathname;
    if (currentPath == ConstantsApp.LINK_JOB_LIST) {
      this.onInitDir('listJob');
    } else if (currentPath == ConstantsApp.LINK_USER_LIST) {
      this.onInitDir('mainScreen');
    } else if (currentPath == ConstantsApp.LINK_CALENDAR) {
      this.onInitDir('calendar');
    } else if (currentPath == ConstantsApp.LINK_WALLET) {
      this.onInitDir('joberWallet');
    } else if (currentPath == ConstantsApp.LINK_REFERRAL) {
      this.onInitDir('referralProgram');
    } else if (currentPath == ConstantsApp.LINK_CANDIDATE_POSTS) {
      this.onInitDir('posts');
    }
    this.setCurrentPage(currentPath);
  }
  ngOnChanges() {
    // rerendering after showLoading is updated from parent page
  }

  init() {
    // this.onActiveLink();

    this.role = 1;
    if (this.routerLinkNames == undefined) {
      this.routerLinkNames = {
        mainScreen: true,
        listJob: false,
        referralProgram: false,
        joberWallet: false,
        savedJobs: false,
        postedJob: false,
        bonusPoint: false,
        calendar: false,
        posts: false,
      };
    }

    if (this.role == ConstantsApp.CANDIDATE) {
      this.routerLinkNames = {
        mainScreen: false,
        listJob: false,
        referralProgram: true,
        joberWallet: false,
        savedJobs: false,
        postedJob: false,
        bonusPoint: false,
        calendar: false,
        listSavedJob: false,
        posts: false
      };
    } else if (this.role == ConstantsApp.RECRUITER) {
      this.routerLinkNames = {
        suitableCandidate: true,
        contactedCandidate: false,
        passedCandidates: false,
        signedCandidates: false,
        rejectedCandidates: false,
        savedCandidates: false,
        introCandidates: false,
        reputationRating: false,
        transactionInfo: false,
        bonusPoint: false,
        changeInfo: false,
        posts: false,
      };
    }
  }

  onActiveLink(routerLink: any) {
    let keys = Object.keys(this.routerLinkNames);
    keys.forEach((item) => {
      if (item == routerLink.key) {
        this.routerLinkNames[routerLink.key] = true;
        this.setCurrentPage(routerLink.routerLink);
      } else {
        this.routerLinkNames[item] = false;
      }
    });
  }

  onInitDir(route: any) {
    let keys = Object.keys(this.routerLinkNames);
    keys.forEach((val) => {
      if (val == route) {
        this.routerLinkNames[route] = true;
      } else {
        this.routerLinkNames[val] = false;
      }
    });
  }

  setCurrentPage(routerLink: any) {
    console.log('routerLink', routerLink);
    this.router.navigate([routerLink]);
  }

  uploadAvatar(url: string) {
    this.appSideBar.handleImageUrl(url);
  }
}
