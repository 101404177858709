<div class="parent-wrapper horizontal-center-pos global-text">
	<div class="parent-wrapper-inside global-text h-screen position-fixed">
		<div class="flex bg-blue-50 flex-row overflow-y-scroll max-h-full">
			<div class="sm:w-1/2 w-[90%] flex justify-center items-stretch mx-auto">
				<div class="md:max-w-md box">
					<a class="flex w-52 h-14 mt-10 gap-2" href="/">
						<img src="./assets/icons/Jlogo.png" />
						<img src="./assets/icons/FullNameLogo.png" class="mt-2" />
					</a>

					<p *ngIf="isLogin&&!changePassState"
						class=" mt-8 global-text-big-size global-text-color-black body-title font-semi-bold text-24">
						{{"loginLbl" | translate }}</p>
					<p *ngIf="isLogin&&!changePassState" class="mt-3 mb-10 text-[#183B52]">{{loginQuote}}</p>

					<p *ngIf="isRegister&&!changePassState"
						class=" mt-8 global-text-big-size global-text-color-black body-title font-semi-bold text-24">
						{{ "registerTitle" | translate }}</p>
					<p *ngIf="isRegister&&!changePassState" class="mt-3 mb-8 text-[#183B52]">{{registerQuote}}</p>

					<p *ngIf="isForgotPass||changePassState"
						class=" mt-8 global-text-big-size global-text-color-black body-title font-semi-bold text-24">
						{{"forgotPasswordTitle" | translate}}</p>


					<div *ngIf="changePassState"
						class="bg-green-200 w-96 h-24 rounded-xl flex items-center mt-8 justify-center pl-4 gap-4 ">
						<img src="./assets/icons/Icon.png" />
						<div class="flex flex-col justify-center">
							<span class="font-semibold">
								Bạn đã thay đổi mật khẩu thành công
							</span>
							<span>
								Hệ thống sẽ tự động chuyển sang màn hình đăng nhập
								sau một vài giây nữa.
							</span>
						</div>

					</div>
					<app-login-form [isLogin]="isLogin" (toForgotPass)="gotoForgotPassForm($event)"
						*ngIf="isLogin&&!changePassState"></app-login-form>
					<app-register-form *ngIf="isRegister&&!changePassState" (toLogin)="gotoLoginForm()" />
					<app-forgotpass-form *ngIf="isForgotPass&&!changePassState"
						(changePassSuccess)="changePassSuccess($event)"></app-forgotpass-form>

					<div class=" other-option horizontal-center-txt-position " *ngIf="!isForgotPass&&!changePassState">
						<span class="text-sm text-black-50">{{otherLoginOptionLbl}}</span>
					</div>

					<div *ngIf="!isForgotPass&&!changePassState"
						class="flex flex-col sm:flex-row justify-center items-center mx-auto gap-6 mt-2.5">
						<div
							class="flex btn-wrapper cursor-pointer border border-black-10 pl-2 pr-5 w-52 h-14 rounded-lg bg-white">
							<div class=" items-center flex gap-8 " (click)="onLoginByFacebook()">
								<img src="./assets/icons/Facebook.png" alt="google-icon" class=" h-10 w-10">
								<span>{{"loginByFbLbl" | translate }}</span>
							</div>
						</div>
						<div class=" cursor-pointer  flex flex-row">
							<div class="gap-8 items-center flex  border border-black-10 pl-2 pr-5 w-52 h-14 rounded-lg bg-white"
								(click)="onLoginByGoogle()">
								<a href="">
									<img src="./assets/icons/mailIcon.png" alt="google-icon" class="mt-0.5 h-8 w-10 ">
								</a>
								<span class="global-text-color-black">{{ "loginByGgLbl" | translate }}</span>
							</div>
						</div>

					</div>
					<div class="mt-8" *ngIf="isLogin&&!changePassState">
						<span class="global-text  text-[#183B52]">{{isHasntAccountQuestion}} <span
								class="global-text text-link cursor-pointer underline"
								(click)="gotoRegisterForm()">{{"registerLbl" | translate }}</span></span>
					</div>
					<div class=" mt-8 " *ngIf="isRegister&&!changePassState">
						<span class="global-text">{{isHadAccountQuestion}} <span
								class="global-text text-link cursor-pointer underline" (click)="gotoLoginForm()">{{
								"loginBtnLbl" | translate }}</span></span>
					</div>
					<div class="flex w-96 mt-4" *ngIf="isForgotPass&&!changePassState">
						<div class="underline col-xl-6 horizontal-start-pos cursor-pointer text-[#183B52] text-link">
							<span (click)="gotoLoginForm()">{{ "loginBtnLbl" | translate }}</span>
						</div>
						<div class="underline col-xl-6 horizontal-end-pos cursor-pointer text-[#183B52] text-link">
							<span (click)="gotoRegisterForm()">{{ "registNewAccLbl" | translate }}</span>
						</div>
					</div>
					<div class="mt-8">
						<span class="global-text  text-[#183B52]">{{ "helpPromptQuestion" | translate }} <span
								class="global-text mr-1">{{ "callNowLbl" | translate }}</span> <span
								(click)="handleClick()"
								class="global-text text-link cursor-pointer text-red-600 underline">{{ "helpPhone" |
								translate }}</span></span>
					</div>
				</div>
			</div>
			<div class="w-1/2 h-full sm:flex hidden">
				<img src="./assets/icons/loginPicture.png" class="h-full w-full" />
			</div>
		</div>
	</div>
</div>