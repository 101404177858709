<div class="selection-wrapper" >
    <select [disabled]="isDisable" class="form-select font-body font-semibold text-base text-gray-900 dark:text-white dark:placeholder-gray-400 truncate" (change)="onSelected($event)" [ngClass]="isNotPadding? 'px-2': 'py-2 px-4'">
      <option *ngIf="placeholder" value="" disabled selected hidden>{{placeholder}}</option>
      <option class="font-body font-semibold text-base text-gray-900 dark:text-white dark:placeholder-gray-400"
        *ngFor="let item of items"
        [selected]="isSelected(item)"
        [value]="item.code? item.code : item.id">
            {{item.name}}
      </option>
    </select>
</div>
