/**
 * Contain constant multilang
 */
export class Constants {
  public static title = 'angular-mateiral';
  public static searchLbl = 'Tìm kiếm';
  public static searchJobLbl = 'Tìm việc';
  public static enterJobNameLbl = 'Nhập công việc bạn cần tìm';
  public static enterAddNameLbl = 'Nhập địa chỉ bạn cần tìm';
  public static language = 'VN';
  public static loginLbl = 'Chào mừng bạn trở lại';
  public static loginBtnLbl = 'Đăng nhập';
  public static loginQuote =
    'Cùng xây dựng một hồ sơ nổi bật và nhận được các cơ hội sự nghiệp lý tưởng';
  public static registerNameLbl = 'Họ và Tên';
  public static registerEmailLbl = 'Email';
  public static registerNamePlaceholder = 'Nguyễn Văn A';
  public static registerEmailPlaceholder = 'envkt@example.com';
  public static registerTitle = 'Chào mừng bạn đến với Jober';
  public static registerQuote =
    'Cùng xây dựng một hồ sơ nổi bật và nhận được các cơ hội sự nghiệp lý tưởng';
  public static forgotPasswordTitle = 'Quên mật khẩu';
  public static phoneDNE = 'Số điện thoại không tồn tại!';
  public static signUpBtnLbl = 'Đăng ký';
  public static invalidEmail = 'Email không tồn tại!';
  public static emailOrPhoneExistedLbl = 'Số điện thoại hoặc Email đã tồn tại!';
  public static nameCantNull = 'Họ và Tên không được bỏ trống!';
  public static emailCantNull = 'Email không được bỏ trống!';
  public static phoneCantNull = 'Số điện thoại không được bỏ trống!';

  public static recruiterLbl = 'Nhà tuyển dụng';
  public static recruitLbl = 'Tuyển dụng';
  public static supportLbl = 'Hỗ trợ';
  public static profileLbl = 'Hồ sơ';
  public static policyLbl = 'Chính sách bảo mật';
  public static postInfoLbl = 'Đăng tin';
  public static postSearchLbl = 'Đăng tin tìm việc';
  public static upgradeProfileLbl = 'Nâng cấp tài khoản';
  public static nearlyLbl = 'Gần đây';
  public static popularLbl = 'Phổ biến';
  public static savedLbl = 'Đã lưu';
  public static foundJobLbl = 'Tìm thấy';
  public static forgotPassLbl = 'Quên mật khẩu?';
  public static getBackPassLbl = 'Lấy lại mật khẩu';
  public static otherLoginOptionLbl = 'Hoặc đăng nhập bằng:';
  public static registerLbl = 'Đăng ký ngay';
  public static isHasntAccountQuestion = 'Bạn chưa có tài khoản? ';
  public static isHadAccountQuestion = 'Bạn đã có tài khoản? ';
  public static close = 'Đóng';
  public static usernameLbl = 'Số điện thoại';
  public static passLbl = 'Mật khẩu';
  public static enterUsernameLbl = '0987654321';
  public static enterPassLbl = 'Password';
  public static loginByFbLbl = 'Facebook';
  public static loginByGgLbl = 'Gmail';
  public static backToLoginLbl = 'Quay lại đăng nhập';
  public static registNewAccLbl = 'Đăng ký tài khoản mới';
  public static changePassLbl = 'Đổi mật khẩu';
  public static contactLbl = 'Liên hệ';
  public static updateProfileLbl = 'Cập nhật hồ sơ';
  public static desJobLbl = 'Mô tả công việc';
  public static applyJobLbl = 'Ứng tuyển';
  public static introYourselfLbl = 'Giới thiệu bản thân';
  public static recruiterPositionLbl = 'Vị trí tuyển dụng';
  public static workingTypeLbl = 'Hình thức làm việc';
  public static recruiterNumberLbl = 'Số lượng';
  public static expirationDateLbl = 'Ngày hết hạn';
  public static salaryLbl = 'Mức lương';
  public static radius = 'Bán kính';
  public static durationTime = 'Thời gian';
  public static currentLocation = 'Vị trí hiện tại';
  public static labelLbl = 'Nhãn';
  public static cancelLbl = 'Hủy';
  public static logoutLbl = 'Đăng xuất';
  public static authorization = 'Authorization';
  public static candidateNearbyLbl = 'ứng viên gần bạn';
  public static jobNearbyLbl = 'công việc gần bạn';
  public static jobOrCompanyNameLbl = 'Tên công việc, công ty...';
  public static placeLbl = 'Khu vực';
  public static majorLbl = 'Ngành nghề';
  public static phoneIsExistedLbl = 'Số điện thoại này đã được đăng ký';
  public static managementLbl = 'Quản lý';
  public static positionLbl = 'Vị trí';
  public static helpPromptQuestion = 'Bạn cần hỗ trợ?';
  public static helpPhone = '(024) 6680 5588';
  public static callNowLbl = 'Gọi ngay đến';
  public static wrongPassword = 'Số điện thoại hoặc mật khẩu không đúng!';

  public static candidateNameLBL = 'Tên ứng viên';
  public static ageLBL = 'Tuổi';
  public static genderLBL = 'Giới tính';
  public static jobAddressLBL = 'Địa chỉ';
  public static introLBL = 'Giới thiệu';
  public static CV = 'CV';
  public static noteLbl = 'Ghi chú';
  public static selectLbl = 'Chọn';

  public static nameLbl = 'Họ và tên';
  public static smsNumLbl = 'Số điện thoại';
  public static jobLbl = 'Công việc';
  public static desLbl = 'Mô tả';
  public static activeLbl = 'Kích hoạt';
  public static inActiveLbl = 'Không kích hoạt';
  public static creationDateLbl = 'Ngày tạo';
  public static deleteLbl = 'Xóa';
  public static roleLbl = 'Phân quyền';

  // For recruiter
  public static suitableCandidatesLbl = 'Ứng viên phù hợp';
  public static contactedCandidatesLbl = 'Ứng viên đã liên hệ';
  public static passedCandidatesLbl = 'Ứng viên đã trúng tuyển';
  public static signedCandidatesLbl = 'Ứng viên đã ký hợp đồng';
  public static rejectedCandidatesLbl = 'Ứng viên đã từ chối offer';
  public static savedCandidatesLbl = 'Ứng viên đã lưu';
  public static introCandidatesLbl = 'Giới thiệu ứng viên';
  public static reputationRatingLbl = 'Xếp hạng uy tín';
  public static postLbl = 'Đăng tuyển của tôi';
  public static transactionInfo = 'Thông tin giao dịch';
  public static bonusPointLBL = 'Điểm thưởng';
  public static changeInfoLbl = 'Thay đổi thông tin';
  public static ruleForGetBonus =
    'Quy định đổi thưởng, cách tính tính điểm thưởng';

  // for candidate
  public static suitableJobsLbl = 'Việc làm phù hợp';
  public static appliedJobsLbl = 'Việc làm đã ứng tuyển';
  public static choosedJobsForInterviewLbl = 'Việc làm được chọn phỏng vấn';
  public static savedJobsLbl = 'Việc làm đã lưu';
  public static postedJobLbl = 'Đăng tìm việc của tôi';
  public static savechangeInfoLbl = 'Lưu thông tin thay đổi';
  public static changePwdLbl = 'Thay đổi mật khẩu';
  public static namePostLbl = 'Tên bài đăng';
  public static workingDayLbl = 'Ngày làm việc';
  public static tradingDayLbl = 'Ngày giao dịch';
  public static activityTradingLbl = 'Hoạt động giao dịch';
  public static numberPointLbl = 'Số lượng điểm giao dịch';
  public static transactionNameLbl = 'Hoạt động giao dịch';
  public static transPointLbl = 'Điểm giao dịch';
  public static transMoneyLbl = 'Tiền giao dịch';
  public static transactionDateLbl = 'Ngày giao dịch';
  // for admin
  public static userListLbl = 'Danh sách người dùng';
  public static blockedUserListLbl = 'Danh sách đã block';
  // theo tháng/ năm
  public static statisticalUserLbl = 'Thống kê người dùng';
  // public static = 'Thống kê người dùng / năm';
  public static scanUserLbl = 'Scan Người tìm việc - nhà tuyển dụng';
  public static addingJobCandidateLbl = 'Thêm Người tìm việc - nhà tuyển dụng';
  public static staticalIncomeLbl = 'Thống kê doanh thu';
  public static bonusUserLbl = 'Quỹ thưởng người dùng';
  public static activeFeeLbl = 'Kích hoạt thu phí';
  public static adminListLbl = 'Danh sách Admin';
  public static latestRecruiterLbl = 'Đăng tuyển mới nhất';
  public static findingLatestJobLbl = 'Tìm việc mới nhất';
  public static revenueInRealtimeLbl = 'Doanh thu theo thời gian thực';
  public static statisticalRevenueByTimeLbl = 'Doanh thu theo tháng - quý năm';
  public static statisticalRevenueLbl = 'Thống kê doanh thu';
  public static addNewAdminLbl = 'Thêm mới admin';
  public static addNewLbl = 'Thêm mới';
  public static addNewUserLbl = 'Thêm mới người dùng';

  public static phoneNumberLbl = 'Số điện thoại';
  public static statusLbl = 'Trạng thái';
  public static typeLbl = 'Loại';
  public static confirmDeleteLbl = 'Bạn có muốn xóa không?';
  public static yesLbl = 'Có';

  public static adminLbl = 'Admin thứ cấp';
  public static viceAdminLbl = 'Admin phó tổng';
  public static candidateLbl = 'Ứng viên';

  public static updateLbl = 'Cập nhật';
  public static exportExcelLbl = 'Xuất ra tệp';
  public static ratingLbl = 'Xếp hạng';
  public static totalMoneyLbl = 'Số dư';
  public static loginNumberLbl = 'Số lần đăng nhập';
  public static emailLbl = 'Email';
  public static statisticByMonthLbl = 'Thống kê theo tháng';
  public static statisticByYearLbl = 'Thống kê theo năm';
  public static statisticUsersLbl = 'Thống kê người dùng';
  public static monthLbl = 'Tháng';
  public static yearLbl = 'Năm';
  public static companyNameLbl = 'Tên công ty';
  public static jobNameLbl = 'Tiêu đề công việc';
  public static candidateNameLbl = 'Tên ứng viên';
  public static uploadFileLbl = 'Tải tệp lên';
  public static templateLbl = 'Mẫu';
  public static dateOfBirthLbl = 'Ngày sinh';
  public static maleLbl = 'Nam';
  public static femaleLbl = 'Nữ';
  public static otherLbl = 'Khác';
  public static strengLbl = 'Điểm mạnh';
  public static avatarLbl = 'Ảnh đại diện';
  public static wardLbl = 'Phường/xã';
  public static districtLbl = 'Quận/huyện';
  public static provinceLbl = 'Tỉnh/thành phố';
  public static addNewRecruiterLbl = 'Thêm nhà tuyển dụng';
  public static addNewCandidateLbl = 'Thêm người tìm việc';
  public static companyProfileLbl = 'Hồ sơ công ty';
  public static personalProfileLbl = 'Hồ sơ của tôi';
  public static feePerEachTimeInterview =
    'Giá tiền cho một lần phỏng vấn(VNĐ): ';
  public static currentFeeLbl =
    'Giá tiền cho một lần phỏng vấn hiện tại (VNĐ): ';
  public static pointLbl = 'Điểm thưởng';
  public static ratioLbl = 'Tỷ lệ đổi thưởng';
  public static moneyLbl = 'Thành tiền';
  public static accountInfoLbl = 'Thông tin tài khoản';
  public static bonusForUserLbl = 'Quỹ thưởng người dùng';
  public static sumOfRevenueInRealtimeLbl =
    'Tổng doanh thu theo thời gian thực';
  public static suggestionAboutRevenueLbl =
    'Quỹ thưởng gợi ý là 10% của tổng doanh thu là';
  public static distanceLbl = 'Khoảng cách';
  public static appliedUserLbl = 'Người đã giới thiệu';
  public static appliedDateLbl = 'Ngày ứng tuyển';
  public static ruleLbl = 'Quy định';
  public static starNumberLbl = 'Số sao tương ứng';
  public static defaultInitLbl = 'Mặc định khởi tạo';
  public static moreThan100UserApplyLbl =
    'Được nhiều hơn 100 người dùng ứng tuyển';
  public static moreThan1000UserApplyLbl =
    'Được nhiều hơn 1000 người dùng ứng tuyển';
  public static moreThan10000UserApplyLbl =
    'Được nhiều hơn 10000 người dùng ứng tuyển';
  public static moreThan100000UserApplyLbl =
    'Được nhiều hơn 100000 người dùng ứng tuyển';
  public static star1 = '1 sao';
  public static star2 = '2 sao';
  public static star3 = '3 sao';
  public static star4 = '4 sao';
  public static star5 = '5 sao';
  public static saveChangeLbl = 'Lưu thay đổi';
  public static saveSuccessLbl = 'Lưu thành công';
  public static saveFailedLbl = 'Lưu thất bại';
  public static markLbl = 'Đánh dấu';
  public static listPageLbl = 'Danh sách';
  // ============================================
  public static sequenceIndexLbl = 'STT';
  public static noticeLbl = 'Lưu ý';
  public static confirmUpdateLbl = 'Bạn có muốn lưu thay đổi không? ';
  public static confirmDeactiveLbl =
    'Bạn có muốn ngừng hoạt động của tài khoản này không?';
  public static allLbl = 'Tất cả';
  public static nationalityLbl = 'Quốc tịch';
  public static nationalLbl = 'Quốc gia';
  public static adminSelectRankLbl = 'Loại tài khoản';
  public static addressLbl = 'Địa chỉ';
  public static jobTargetLbl = 'Mục tiêu nghề nghiệp';
  public static experienceLbl = 'Kinh nghiệm làm việc';
  public static editLbl = 'Kinh nghiệm làm việc';
  public static deleteAccountLbl = 'Xóa tài khoản';
  public static deactiveLbl = 'Bỏ kích hoạt';
  public static introProgramingLbl = 'Chương trình giới thiệu';
  public static jobFindingLbl = 'Đăng tìm việc';
  public static desiredSalaryLbl = 'Mức lương mong muốn';
  public static onlineLbl = 'Trực tuyến';
  public static offLineLbl = 'Trực tiếp';
  public static fullTimeLbl = 'Toàn thời gian';
  public static partTimeLbl = 'Theo giờ';
  public static informCreatingPostLbl = 'Bạn chưa có bài đăng cho công việc này, vui lòng đăng tuyển';
  public static provinceNameDefault = 'Hà Nội';
  public static districtNameDefault = 'Quận Ba Đình';
  public static wardNameDefault = 'Phường Thành Công';

}
