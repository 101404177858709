export class ScheduleDetail {
  organizationName: string;
  defaultJobName: string;
  startDate: any;
  endDate: any;
  jobName: any;
  status: any;
  expDate: any;
  district: any;
  province: any;
  ward: any;

  constructor(
    organizationName: string = '',
    defaultJobName: string = '',
    startDate: any = new Date(),
    endDate: any = new Date(),
    jobName: any = '',
    status: any = '',
    expDate: any = new Date(),
    district: any = '',
    province: any = '',
    ward: any = '',
  ) {
    this.organizationName = organizationName;
    this.defaultJobName = defaultJobName;
    this.startDate = startDate;
    this.endDate = endDate;
    this.jobName = jobName;
    this.status = status;
    this.expDate = expDate;
    this.district = district;
    this.province = province;
    this.ward = ward;
  }
}
