<div class="popup-container" id="popup-confirm">
	<!-- [ngClass]="confirmCode == 1 ? 'popup-confirm-update-wrapper' : confirmCode == 2 ? 'popup-confirm-deactive-wrapper' : 'popup-confirm-delete-wrapper'" -->
	<div class="modal">
		<div class="modal-inner center-pos row">
			<div class="py-3 bg-blue rounded-top flex justify-between items-center">
				<div>
					<span class="font-size-big font-weight-bold global-text-color-white">{{noticeLbl}}</span>
					<img class="px-4" src="../assets/icons/white-info.svg">
				</div>
				<img type="button" (click)="closePopup()" src="../assets/icons/x-close.svg"
					class="position-absolute top-0 end-0 p-3">
			</div>
			<div class="col-12 py-4 d-flex justify-content-center">
				<div class="popup-content">
					<span>{{confirmMessage}} aaaa</span>
				</div>
			</div>
			<div class="col-12 py-4 horizontal-center-pos">
				<div class="col-md-6 left-btn-wrapper horizontal-end-pos vertical-center-pos-1">
					<button class="btn
                    border-none
                    font-size-small
                    font-weight-bold
                    color-disable-text
                    bg-disable px-5" (click)="closePopup()">{{cancelLbl}}</button>
				</div>
				<div class="col-md-6 right-btn-wrapper horizontal-start-pos vertical-center-pos-1">
					<button class="btn
                    border-none
                    font-size-small
                    font-weight-bold
                    global-text-color-white
                    bg-blue px-5" (click)="onValidate()">{{yesLbl}}</button>
				</div>
			</div>
		</div>
	</div>
</div>