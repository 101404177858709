// payment-failed.component.ts
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-failed',
  templateUrl: './payment-failed.component.html'
})
export class PaymentFailedComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {}

}