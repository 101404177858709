<div class="popup-container popup-add-user-wrapper">
	<div class="modal display">
		<div class="modal-inner center-pos row">
			<div class="flex px-3 py-2 justify-between items-center gap-5 self-stretch"
				style="border-radius: 8px 8px 0px 0px; background: var(--blue-text);">
				<div class="flex items-center gap-28">
					<span class="font-body font-semibold text-xl item" style="color: var(--white);">
						{{ "completeInfoLbl" | translate }}
					</span>
				</div>
				<button (click)="closePopup()"><img src="../../../../../assets/icons/close.svg" alt="icon-close">
				</button>
			</div>
			<div class="px-4 mt-5">
				<app-user-detail isEditMode="true"></app-user-detail>
			</div>
		</div>
	</div>
</div>