<app-toast #appToast [messageCode]=""></app-toast>
<div class="container">
	<div class="grid xs:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-4">
		<div *ngFor="let i of data">
			<app-item [item]="i" (viewDetail)="openPopupDetailItem($event)"></app-item>
		</div>
	</div>
	<div class="text-[60px] text-center text-gray-300 font-extrabold" *ngIf="data.length==0">Không tìm thấy công việc phù hợp</div>
	<div class="paginator-wrapper flex justify-center p-4">
		<div class="pagination flex justify-content">
			<div class="flex items-center">
				<img type="button" (click)="onPrePageClick()" src="../assets/img/back.png" class="px-2 h-4">
			</div>
			<li class="page-item" *ngFor="let i of arrPage" (click)="onCurrentPageClick(i)" >
				<a class="page-link" [ngClass]="{'bg-blue-500 text-white':currentPage===i||data.length==0}" *ngIf="isDisplayPageNumber(i)">{{ i }}</a>
			</li>
			<div class="flex items-center">
				<img type="button" (click)="onNextPageClick()" src="../assets/img/next.png" class="px-2 h-4">
			</div>
		</div>
	</div>
</div>
<app-popup-detail-item (applyInform)="showInfoSuccessfullyApply($event)"
	(openPopupConfirm)="showPopupConfirmApply($event)" #popupDetailItem></app-popup-detail-item>
