import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import * as $ from 'jquery';
import { Constants } from 'src/app/constant/Constants';
import {ConstantsApp} from "../../../../../constant/ConstantsApp";
import {Schedule} from "../../../../../model/Schedule";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UtilsService} from "../../../../../helper/service/utils.service";
import {environment} from "../../../../../../environments/environment";
import {ApiNameConstants} from "../../../../../constant/ApiNameConstants";
import {CommonService} from "../../../../../service/common.service";
@Component({
  selector: 'app-popup-booking-interview',
  templateUrl: './popup-booking-interview.component.html',
  styleUrls: ['./../popupInfoCandidate/popup-info-candidate.component.scss']
})

export class PopupBookingInterviewComponent implements OnInit {
  @Output() validate = new EventEmitter();
  @Input() candidate: any;

  cancelLbl = Constants.cancelLbl;
  yesLbl = Constants.yesLbl;
  noticeLbl = Constants.noticeLbl;
  freelancerId: number;
  ratingStart: number;
  comment: string;
  isRedirect: any;
  methodInterview: number;
  radioItem: any;
  scheduleForm: any;
  schedule: Schedule;
  isEditMode: boolean;

  meetingId: string;
  passcode: string;
  linkzoom: string;
  radioItems = [
    {
      label: Constants.onlineLbl,
      val: ConstantsApp.ONLINE,
      checked: true
    },
    {
      label: Constants.offLineLbl,
      val: ConstantsApp.OFFLINE,
      checked: false
    }
  ]
  constructor(
    private utilsService: UtilsService,
    private commonService: CommonService
  ) {
    this.methodInterview = 1;
    this.closePopup();
    this.schedule = new Schedule();
    this.radioItem = this.radioItems[0];
    this.schedule.type = this.radioItem.val;
  }

  ngOnInit(): void {
    this.closePopup();
    this.scheduleForm = new FormGroup({
      topic: new FormControl('', [Validators.required]),
      des: new FormControl('', [Validators.required]),
      interviewDate: new FormControl('', [Validators.required]),
      interviewStartTime: new FormControl('', [Validators.required]),
      interviewEndTime: new FormControl('', [Validators.required]),
      address: new FormControl(''),
      meetingId: new FormControl('', [Validators.required]),
      passcode: new FormControl('', [Validators.required]),
      linkzoom: new FormControl('', [Validators.required]),
    });
    this.scheduleForm.enable();
  }

  openPopup(methodInterview: number) {
    document.body.classList.add('no-scroll');
    $('#popup-booking .modal').removeClass("hide");
    $('#popup-booking .modal').addClass("display");
  }

  closePopup() {
    document.body.classList.remove('no-scroll'); 
    $('#popup-booking .modal').removeClass("display");
    $('#popup-booking .modal').addClass("hide");
  }

  onRate(rate: number) {
    this.ratingStart = rate;
  }
  onInterview(methodInterview: number) {
    console.log(this.scheduleForm)
    console.log(this.scheduleForm.valid);
    
    if (methodInterview == 2 && this.scheduleForm.valid) {
      this.createScheduleInterview();
    } 
  }

  createScheduleInterview() {
    let body = {
      scheduleId: this.candidate.scheduleId,
      freelancerId: this.candidate.freelancerId,
      jobId: this.candidate.jobId,
      type: this.schedule.type,
      topic: this.schedule.topic,
      des: this.schedule.des,
      address: this.schedule.address,
      startDate: new Date(this.schedule.interviewDate + 'T' + this.schedule.interviewStartTime + ':00'),
      endDate: new Date(this.schedule.interviewDate + 'T' + this.schedule.interviewEndTime + ':00'),
      status: ConstantsApp.INTERVIEW_PROCESSING,
      meetingId: this.schedule.meetingId,
      passcode: this.schedule.passcode,
      linkzoom: this.schedule.linkzoom,
    };
    const functionName = 'createScheduleInterview';
    const messageLog = 'create schedule to interview';
    let apiUrl = environment.API_URI + ApiNameConstants.BS_USER_SCHEDULE_SAVE;
    this.commonService
      .postDatas(body, apiUrl, functionName, messageLog)
      .subscribe(
        (res: any) => {
          console.log('createScheduleInterview: ', res)
          if (res && res.status == ConstantsApp.CREATED) {
            // this.closePopup();
            this.scheduleForm.disable();
          } else {
          }
        },
        (error) => {
          console.error('API error:', error);
        }
      );
  }
  onValidate() {

  }
  radioOnChecked(item: any) {
    this.radioItem = item;
    this.schedule.type = this.radioItem.val;

    if (this.isChoseOnline()) {
      this.scheduleForm.get('address').setValidators([Validators.required]);
      this.scheduleForm.get('meetingId').clearValidators();
      this.scheduleForm.get('passcode').clearValidators();
      this.scheduleForm.get('linkzoom').clearValidators();
    } else {
      this.scheduleForm.get('address').clearValidators();
      this.scheduleForm.get('meetingId').setValidators([Validators.required]);
      this.scheduleForm.get('passcode').setValidators([Validators.required]);
      this.scheduleForm.get('linkzoom').setValidators([Validators.required]);
    }
    
    this.scheduleForm.get('address').updateValueAndValidity();
    this.scheduleForm.get('meetingId').updateValueAndValidity();
    this.scheduleForm.get('passcode').updateValueAndValidity();
    this.scheduleForm.get('linkzoom').updateValueAndValidity();
  }
  isChoseOnline() {
    return this.radioItem.val == ConstantsApp.ONLINE;
  }
  checkDisableForm() {
    if (!this.isEditMode) {
      this.scheduleForm.disable();
    } else {
      this.scheduleForm.enable();
    }
  }
}
