<div class="">
  <div class="table-wrapper">
    <table
      id="dtOrderExample"
      class="table table-striped table-bordered table-sm dataTable table-body-wrapper"
      cellspacing="0"
      width="100%"
    >
      <thead>
      <tr>
        <th
          class="heading-table"
        >
          <span>{{ sequenceIndexLbl }}</span>
        </th>
        <th
          class="heading-table"
          *ngFor="let col of displayedColumns"
          ngClass="{{ col.key }}"
        >
          <span>{{ col.name }}</span>
        </th>
      </tr>
      </thead>
      <tbody class="">
      <tr class="body-table" *ngFor="let rowData of dataTable; index as i">
        <td class="">
          <span>{{ (currentPage - 1) * pageSize + i + 1 }}</span>
        </td>
        <td class="" *ngFor="let col of displayedColumns">
          <span>{{rowData[col.key]}}</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="flex flex-row items-center justify-center paginator-wrapper">
    <div class="pagination flex justify-end mt-2 sm:mt-0 mb-3 space-x-2">
      <a (click)="onPrePageClick()" class="px-3 py-1 border border-gray-300 rounded bg-white hover:bg-gray-200 disabled:opacity-50 cursor-pointer" [class.disabled]="!isPrePageActive()" aria-disabled="!isPrePageActive()">&laquo;</a>
      
      <ng-container *ngFor="let i of arrPage">
        <li class="page-item" *ngIf="isDisplayPageNumber(i)" (click)="onCurrentPageClick(i)">
          <a class="page-link px-3 py-1 border border-gray-300 rounded hover:bg-blue-500 hover:text-white {{ currentPage === i ? 'bg-blue-500 text-white' : 'hover:bg-gray-200' }}">{{ i }}</a>
        </li>
      </ng-container>
      
      <a (click)="onNextPageClick()" class="px-3 py-1 border border-gray-300 rounded bg-white hover:bg-gray-200 disabled:opacity-50 cursor-pointer" [class.disabled]="!isNextPageActive()" aria-disabled="!isNextPageActive()">&raquo;</a>
    </div>
  </div>
</div>
