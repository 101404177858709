<div class="popup-container popup-change-password padding ">
	<div class="modal">
		<div class="modal-inner center-pos row">
			<div class="modal-header bg-blue rounded-t-lg flex justify-center items-center p-4">
				<span class=" font-weight-bold global-text-color-white">{{'changePwdLbl' | translate}}</span>
				<img type="button" (click)="closePopup()" src="../assets/icons/white-cancel.svg"
					class="absolute top-0 right-0 p-3 cursor-pointer">
			</div>
			<div class="p-4">
				<app-changepass-form (newValidPassword)="changePass($event)"></app-changepass-form>
			</div>
		</div>
	</div>
</div>