<div class="popup-container popup-job-finding padding">
	<div class="modal">
		<div class="modal-inner center-pos row">
			<div class="col-12 py-3 d-flex justify-content-center align-items-center position-relative  ">

				<img type="button" (click)="closePopup()" src="../../../../../assets/icons/close.svg"
					class="position-absolute bg-red-500 rounded-tr-lg top-0 end-0 p-3 ">
			</div>
			<div class="md:px-4 px-2">
				<app-job-finding (validate)="validate.emit($event)" #appJobFinding [parentPageCode]="parentPageCode"  [jobItem]="jobItem" [modeType]="modeType" isEditMode="true"></app-job-finding>
			</div>
		</div>
	</div>
</div>
