import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import * as $ from 'jquery';
import { Constants } from 'src/app/constant/Constants';

@Component({
  selector: 'app-popup-recharge',
  templateUrl: './popup-recharge.component.html',
  styleUrls: ['./popup-recharge.component.scss'],
})
export class PopupRechargeComponent implements OnInit {
  @Output() validate = new EventEmitter();
  @Input() confirmCode: any;

  cancelLbl = Constants.cancelLbl;
  confirmDeleteLbl = Constants.confirmDeleteLbl;
  yesLbl = Constants.yesLbl;
  confirmMessage: any;
  noticeLbl = Constants.noticeLbl;
  className: any;
  methodPayment = 0;
  constructor() {
    this.closePopup();
  }

  ngOnInit(): void {
    this.closePopup();
  }

  openPopup() {
    $('#popup-recharge .modal').removeClass('hide');
    $('#popup-recharge .modal').addClass('display');
  }

  closePopup() {
    $('#popup-recharge .modal').removeClass('display');
    $('#popup-recharge .modal').addClass('hide');
  }

  onValidate() {
    this.validate.emit();
    this.closePopup();
  }

  onChangeMethodPayment(i: number) {
    this.methodPayment = i;
  }
}
