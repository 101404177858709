<app-toast #appToast [messageCode]="messageCode"></app-toast>
<div class="user-detail-wrapper">
	<div class="flex flex-col items-start gap-5 mt-2 mr-5">
		<div class="flex flex-col items-start p-3 gap-10 bg-white rounded-xl -mt-9">
			<form action="" [formGroup]="detailUserForm">
				<div class="row mt-2">
					<div class="col-xs-12 col-md-12 px-2">
						<div class="mb-3">
							<div class="form-label"><span
									class="color-ink-text font-size-small font-weight-bold">{{"nameLbl" | translate
									}}</span></div>
							<input [(ngModel)]="userItem.name"
								[ngClass]="name.invalid? 'inValid-border': 'valid-border'" class="form-control p-2"
								type="text" formControlName="name" />
						</div>
						<div class="mb-3">
							<div class="form-label"><span
									class="color-ink-text font-size-small font-weight-bold">{{"phoneNumberLbl" |
									translate }}</span>
							</div>
							<input [(ngModel)]="userItem.phone"
								[ngClass]="phone.invalid? 'inValid-border': 'valid-border'" class="form-control p-2"
								type="text" formControlName="phone" />
						</div>

						<div class="flex justify-between">
							<div class="mb-3 w-5/12">
								<div class="form-label"><span
										class="color-ink-text font-size-small font-weight-bold">{{"dateOfBirthLbl" |
										translate }}</span>
								</div>
								<div class="input-container">
									<input [(ngModel)]="userItem.birthyear" [disabled]="true"
										formControlName="birthyear"
										[ngClass]="birthyear.invalid? 'inValid-border': 'valid-border'"
										class="form-control" type="date">
								</div>
							</div>
							<div class="mb-3 w-5/12">
								<div class="form-label"><span
										class="color-ink-text font-size-small font-weight-bold">{{"genderLBL" |
										translate }}</span>
								</div>
								<div class="input-container ">
									<app-selection-menu [isDisable]="!isEditMode" [selectedItem]="selectedGender"
										[items]="genders"
										(onChangeSelected)="onGenderSelected($event)"></app-selection-menu>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xs-12 col-md-12 px-2">
						<div class="mb-3">
							<div class="form-label"><span
									class="color-ink-text font-size-small font-weight-bold">{{"emailLbl" | translate
									}}</span>
							</div>
							<input type="email" [(ngModel)]="userItem.email"
								[ngClass]="email.invalid? 'inValid-border': 'valid-border'" class="form-control p-2"
								type="text" formControlName="email" />
						</div>
					</div>
					<div class="flex justify-between px-2">
						<div class="w-5/12">
							<div class="mb-3 w-full">
								<div class="form-label">
									<span class="color-ink-text font-size-small font-weight-bold">{{"provinceLbl" |
										translate }}</span>
								</div>
								<app-selection-menu class="w-full" [isDisable]="!isEditMode"
									[selectedItem]="selectedProvince" [items]="provinces"
									(onChangeSelected)="onProvinceSelected($event)"></app-selection-menu>
							</div>
						</div>
						<div class="w-5/12">
							<div class="mb-3 w-full">
								<div class="form-label"><span class="color-ink-text font-size-small font-weight-bold">{{
										"districtLbl" | translate }}</span>
								</div>
								<app-selection-menu class="w-full" [isDisable]="!isEditMode"
									[selectedItem]="selectedDistrict" [items]="districts"
									(onChangeSelected)="onDistrictSelected($event)"></app-selection-menu>
							</div>
						</div>

					</div>
					<div class="flex justify-between px-2">
						<div class="w-5/12">
							<div class="mb-3 w-full">
								<div class="form-label"><span
										class="color-ink-text font-size-small font-weight-bold">{{"wardLbl" | translate
										}}</span>
								</div>
								<app-selection-menu [isDisable]="!isEditMode" [selectedItem]="selectedWard"
									[items]="wards" (onChangeSelected)="onWardSelected($event)"></app-selection-menu>
							</div>
						</div>
						<div class="w-5/12">
							<div class="mb-3 w-full">
								<div class="form-label"><span
										class="color-ink-text font-size-small font-weight-bold">{{"addressLbl" |
										translate }}</span>
								</div>
								<input class="form-control" [(ngModel)]="userItem.detailAddress"
									[ngClass]="address.invalid? 'inValid-border': 'valid-border'"
									formControlName="address" />
							</div>
						</div>
					</div>
					<div class="row mb-3 px-2">
						<div class="form-label"><span
								class="color-ink-text font-size-small font-weight-bold">{{"jobTargetLbl" | translate
								}}</span>
						</div>
						<textarea rows="3" class="form-control p-3" formControlName="jobTarget"
							[(ngModel)]="userItem.jobTarget"></textarea>
					</div>
					<div class="row mb-3 px-2">
						<div class="form-label"><span
								class="color-ink-text font-size-small font-weight-bold">{{"experienceLbl" | translate
								}}</span>
						</div>
						<textarea rows="3" class="form-control p-3" formControlName="experience"
							[(ngModel)]="userItem.experience"></textarea>
					</div>
				</div>
				<div class="flex justify-center">
					<button [disabled]="detailUserForm.invalid"
						[ngClass]="detailUserForm.invalid? 'btn-disable' : 'active-btn-save'" class="btn
						border-none
						font-size-small
						font-weight-bold
						global-text-color-white
						bg-blue-600
						px-3 w-[170px]" (click)="saveData(1)" type="button" aria-expanded="false" aria-controls="collapseDetailInfo">
						<span class="px-2 font-body not-italic font-semibold text-xs items-center">{{"saveChangeLbl"
							| translate }}</span>
					</button>
				</div>
			</form>
		</div>
	</div>
</div>