import {Component, OnInit, Output, EventEmitter, Input, ViewChild, ChangeDetectorRef} from '@angular/core';
import * as $ from 'jquery';
import {PopupBookingInterviewComponent} from "../popup-booking-interview/popup-booking-interview.component";
import {UtilsService} from "../../../../../helper/service/utils.service";
import {CommonService} from "../../../../../service/common.service";
import {ToastComponent} from "../../../../../layout/toast/toast.component";
import {CandidateDetailInfoComponent} from "../../../home/candidate-detail-info/candidate-detail-info.component";
import {UserService} from "../../../../../service/user.service";
import {MapService} from "../../../../../service/map.service";
import {ProfileCandidateService} from "../../../../candidate/profile/profile.service";
import {LocalStorageService} from "../../../../../core/auth/local-storage.service";
import {FileService} from "../../../../../service/file-service/FileService";
import {Router} from "@angular/router";
import {AuthService} from "../../../../../core/auth/auth.service";
@Component({
  selector: 'app-popup-info-candidate',
  templateUrl: './popup-info-candidate.component.html',
  styleUrls: ['./popup-info-candidate.component.scss'],
})
export class PopupInfoCandidateComponent implements OnInit {
  @Input() confirmCode: any;
  @Input() parentPageCode: string;
  @Output() validate = new EventEmitter();
  @Output() popupBookingInterview: PopupBookingInterviewComponent = new PopupBookingInterviewComponent(this.utilsService, this.commonService);
  methodInterview = 0;
  isOpen: boolean;
  candidate: any;
  @ViewChild('appCandidateDetailInfo') appCandidateDetailInfo: CandidateDetailInfoComponent
    = new CandidateDetailInfoComponent(this.userService, this.mapService, this.userDetailService, this.commonService, this.localStorage, this.fileService, this.cdr, this.router, this.authService, this.utilsService);
  constructor(
    private userService: UserService,
    private mapService: MapService,
    private userDetailService: ProfileCandidateService,
    private commonService: CommonService,
    private localStorage: LocalStorageService,
    private fileService: FileService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private authService: AuthService,
    private utilsService: UtilsService) {}

  ngOnInit(): void {
    this.closePopup();
  }

  openPopup(item: number) {
    document.body.classList.add('no-scroll');
    this.candidate = item;
    this.isOpen = true;
    setTimeout(() => {
      // this.appCandidateDetailInfo.getData();
    }, 500);
    let modal = $('#popup-info-candidate .modal');
    modal.removeClass('hide');
    modal.addClass('display');
  }

  closePopup() {
    // document.body.classList.remove('no-scroll');
    this.isOpen = false;
    this.methodInterview = 0;
    let modal = $('#popup-info-candidate .modal');
    modal.removeClass('display');
    modal.addClass('hide');
  }

  onValidate() {
    // this.onCreateUser();
  }

  onInterview(i: number) {
    this.methodInterview = i;
    this.closePopup();
    this.popupBookingInterview.openPopup(i);
  }
}
