<div class="popup-container" id="popup-detail-item">
	<div class="modal display">
		<div class="center-pos row md:w-[80%] w-full px-4 md:px-0">
			<div class="flex flex-col md:gap-10 gap-2 rounded-xl bg-white px-2">
				<button (click)="closePopup()" class="flex mt-1 right-0 justify-end items-center">
					<img src="../../../../../assets/icons/x-close.svg" alt="icon-close" />
				</button>
				<app-job-detail [isDisplayBackBtn]="false" (onSaveJob)="onSave()"
					[itemParam]="item"></app-job-detail>
			</div>
		</div>
	</div>
</div>
