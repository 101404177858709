<div>
<div>
  <div class="table-wrapper">
    <table id="dtOrderExample" class="table table-striped table-bordered table-sm dataTable table-body-wrapper"
      cellspacing="0" width="100%">
      <thead>
        <tr class="tr-header-wrapper">
          <th class="heading-table md:w-[100px] w-[150px]">
            <div class="col-title-wrapper flex items-center md:w-[40px] w-[40px] justify-center">
              <span>{{ sequenceIndexLbl }}</span>
            </div>
          </th>
          
          <th class="heading-table" *ngFor="let col of displayedColumns" ngClass="{{ col.key }}">
            <span>{{ col.name }}</span>
          </th>
          <th class="md:px-2 px-1">
            <div class="d-flex justify-content-center">
              <input type="checkbox" class="form-check-input form-check-input-all" id="" (click)="onCheckAll($event)" />
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="">
        <tr class="body-table" *ngFor="let rowData of dataTable; index as i">
          <td class="md:px-2 px-3">
            <span>{{(currentPage - 1) * pageSize + i + 1 }}</span>
          </td>
          <td class="md:px-2 px-1" *ngFor="let col of displayedColumns">
            <div *ngIf="col.key != 'cv'">
              <span>{{rowData[col.key]}}</span>
            </div>
            <div *ngIf="col.key == 'cv'">
              <div class="flex gap-2 mb-2">
                <button [disabled]="!rowData.cv" [ngClass]="{'bg-disable !border-none': !rowData.cv}"
                  (click)="onDownloadCv(rowData)" style="border: 1px solid #5B91FB;"
                  class="rounded  w-auto flex justify-center items-center gap-2 py-1 px-2 ">
                  <p [ngClass]="{'!text-[#9a9ba0] no-underline': !rowData.cv}"
                    class="flex items-center text-center font-body not-italic font-semibold text-xs underline"
                    style="color: #3276FA;">{{"uploadCV" | translate}}</p>
                </button>
              </div>
            </div>
          </td>
          <td class="md:px-2 px-1">
            <div class="d-flex justify-content-center">
              <input type="checkbox" class="checkbox-item form-check-input"
                (click)="onCheckBox($event, rowData);$event.stopPropagation()" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="flex flex-row items-center justify-center paginator-wrapper">
    <div class="pagination flex justify-end mt-4 sm:mt-0 mb-3 space-x-2">
      <a (click)="onPrePageClick()"
        class="px-3 py-1 border border-gray-300 rounded bg-white hover:bg-gray-200 disabled:opacity-50 cursor-pointer"
        [class.disabled]="!isPrePageActive()" aria-disabled="!isPrePageActive()">&laquo;</a>

      <ng-container *ngFor="let i of arrPage">
        <li class="page-item" *ngIf="isDisplayPageNumber(i)" (click)="onCurrentPageClick(i)">
          <a
            class="page-link px-3 py-1 border border-gray-300 rounded hover:bg-blue-500 hover:text-white {{ currentPage === i ? 'bg-blue-500 text-white' : 'hover:bg-gray-200' }}">{{
            i }}</a>
        </li>
      </ng-container>

      <a (click)="onNextPageClick()"
        class="px-3 py-1 border border-gray-300 rounded bg-white hover:bg-gray-200 disabled:opacity-50 cursor-pointer"
        [class.disabled]="!isNextPageActive()" aria-disabled="!isNextPageActive()">&raquo;</a>
    </div>
  </div>
</div>
</div>