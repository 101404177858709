import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {ApiNameConstants} from "../../constant/ApiNameConstants";

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private http: HttpClient) { }

  uploadFile(file: File) {
    const formData = new FormData();
    const apiUrl = environment.API_URI + ApiNameConstants.BS_USER_UPLOAD;
    formData.append('file', file, file.name);
    return this.http.post<any>(apiUrl, formData);
  }
}
