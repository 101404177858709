import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import * as $ from 'jquery';
import { Constants } from 'src/app/constant/Constants';

@Component({
  selector: 'app-popup-changepoint',
  templateUrl: './popup-changepoint.component.html',
  styleUrls: ['./popup-changepoint.component.scss'],
})
export class PopupChangePointComponent implements OnInit {
  @Output() validate = new EventEmitter();
  @Input() confirmCode: any;

  constructor() {}

  ngOnInit(): void {
    this.closePopup();
  }

  openPopup() {
    document.body.classList.add('no-scroll');
    $('#popup-change-point .modal').removeClass('hide');
    $('#popup-change-point .modal').addClass('display');
  }

  closePopup() {
    document.body.classList.remove('no-scroll');
    $('#popup-change-point .modal').removeClass('display');
    $('#popup-change-point .modal').addClass('hide');
  }

  onValidate() {
    this.validate.emit();
    this.closePopup();
  }
}
