import {
  Component,
  ViewChild,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import {Router} from "@angular/router";
import { UserService } from 'src/app/service/user.service';
import { Constants } from 'src/app/constant/Constants';
import { ConstantsApp } from 'src/app/constant/ConstantsApp';
import { CommonService } from 'src/app/service/common.service';
import { UtilsService } from 'src/app/helper/service/utils.service';
import { FormGroup, FormControl, Validators, EmailValidator } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ApiNameConstants } from 'src/app/constant/ApiNameConstants';
import { ToastComponent } from 'src/app/layout/toast/toast.component';
import { MapService } from 'src/app/service/map.service';
import {ProfileCandidateService} from "../../../candidate/profile/profile.service";
import {ApiModel} from "../../../../model/ApiModel";
import {FileService} from "../../../../service/file-service/FileService";
import {AuthService} from "../../../../core/auth/auth.service";
import {LocalStorageService} from 'src/app/core/auth/local-storage.service';
import {User} from "../../../../model/User";
import {PopupCreatingPostComponent} from "../../popup/popup-creating-post/popup-creating-post.component";
import {PopupConfirmComponent} from "../../../form/popup-confirm/popup-confirm.component";
import { PopupBookingInterviewComponent } from '../../candidate-management/table-candidate/popup-booking-interview/popup-booking-interview.component';

@Component({
    selector: 'app-candidate-detail-info',
    templateUrl: './candidate-detail-info.component.html',
    styleUrls: ['./candidate-detail-info.component.scss']
})

export class CandidateDetailInfoComponent implements OnInit {
		private readonly CDN_URI = `${environment.CDN_URI}`;
    @Input() parentPageCode: string;
    @Input() userItem: any;
    @Input() jobDefaultId: any;
    @Input() isEditMode: any;
    @Input() modeType: any;
    @Input() freelancerId: number;
    @Input() status: number;
    @Output() validate = new EventEmitter();
    @Output() cancel = new EventEmitter();
    @Output() onInterview = new EventEmitter();
    @ViewChild('appToast') appToast: ToastComponent = new ToastComponent();
    cancelLbl = Constants.cancelLbl;
    phoneNumberLbl = Constants.phoneNumberLbl;
    nameLbl = Constants.nameLbl;
    dateOfBirthLbl = Constants.dateOfBirthLbl;
    cv = Constants.CV;
    wardLbl = Constants.wardLbl;
    districtLbl = Constants.districtLbl;
    provinceLbl = Constants.provinceLbl;
    nationalityLbl = Constants.nationalityLbl;
    emailLbl = Constants.emailLbl;
    addressLbl = Constants.addressLbl;
    jobTargetLbl = Constants.jobTargetLbl;
    experienceLbl = Constants.experienceLbl;
    activeLbl = Constants.activeLbl;
    provinces: any;
    districts: any;
    wards: any;
    isChecked: boolean | true;
    genders: any;
    detailUserForm: any;
    province: any;
    district: any;
    ward: any;
    userTypes: any;
    selectedProvince: any;
    selectedDistrict: any;
    selectedWard: any;
    selectedUserType: any;
    selectedGender: any;
    userItemOrg: any;
    messageCode: any;
    CONFIRM_CREATING_NEW_POST = ConstantsApp.CONFIRM_CREATING_NEW_POST;
    center: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
    dob: any;
    avatarUrl: string | ArrayBuffer | null = null;
    user: any;
    role:any;
    recruiterRole:any;
		actionMobile:any;
    jobId: number;
    chosenStatus: number = ConstantsApp.CHOSEN;
    APP_CANDIDATE_ITEMS_LIST: string = ConstantsApp.APP_CANDIDATE_ITEMS_LIST;
    authenticated: boolean;
  @ViewChild('popupCreatingPost') popupCreatingPost: PopupCreatingPostComponent = new PopupCreatingPostComponent(this.commonService);
  @ViewChild('popupInformCreatingPost') popupInformCreatingPost: PopupConfirmComponent = new PopupConfirmComponent();
  @Output() popupBookingInterview: PopupBookingInterviewComponent = new PopupBookingInterviewComponent(this.utilsService, this.commonService);

  constructor(
        private userService: UserService,
        private mapService: MapService,
        private userDetailService: ProfileCandidateService,
        private commonService: CommonService,
        private localStorage: LocalStorageService,
        private fileService: FileService,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private authService: AuthService,
        private utilsService: UtilsService) {
        this.user = this.utilsService.getItem(ConstantsApp.user);
        this.role = this.localStorage.getItem(ConstantsApp.role);
        this.recruiterRole = ConstantsApp.RECRUITER;
        this.isChecked = true;
    }

    ngOnInit() {
     this.authenticated = this.authService.authenticated();
     this.initData();
    }
    initData() {
      this.actionMobile = ConstantsApp.optionActionProfile;
      this.genders = [{code: 1, name: Constants.maleLbl}, {code: 2, name: Constants.femaleLbl}, {code: 3, name: Constants.otherLbl}]
      let item = this.localStorage.getItem(ConstantsApp.user);
      let date = new Date();
      if (item) {
        date = item.birthyear? new Date(item.birthyear): item.dateOfBirth? new Date(item.dateOfBirth) : new Date();
      }
      let dateString = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0')+'-'+date.getDate();
      this.userItem =  item? {
        detailAddress: item.detailAddress,
        addree: item.detailAddress,
        phone: item.phone,
        name: item.name,
        email: item.email,
        job: item.job,
        dateOfBirth: dateString,
        des: item.des,
        salary: item.salary,
        cv: item.cv,
        img: item.img,
        lat: item.lat,
        lg: item.lg,
        gender: item.gender,
        role: item.role,
        introPhone: item.introPhone,
        jobTarget: item.jobTarget,
        country: 'Việt Nam',
        nationality:'Việt Nam',
        experience: item.experience,
        province: item.province? item.province: 'Hà Nội',
        district: item.district? item.district : 'Cầu Giấy',
        ward: item.ward? item.ward: 'Dịch Vọng Hậu',
        avatar:item.avatar?`${item.avatar}`:null,
        status:item.status,
        recruiterRate: {
          totalCount: 0,
          averageRating: 0,
          rating1Star: 0,
          rating2Star: 0,
          rating3Star: 0,
          rating4Star: 0,
          rating5Star: 0
        },
      } : new User();
      if(this.userItem.avatar!=null){
        this.avatarUrl = `${this.CDN_URI}/${this.userItem.avatar}`;
      }
     // this.getData();

      this.userItemOrg = {...this.userItem};
      if (!this.userItem.province) this.userItem.province = 'Hà Nội';
      if (!this.userItem.district) this.userItem.district = 'Cầu Giấy';
      if (!this.userItem.ward) this.userItem.ward = 'Dịch Vọng Hậu';
      if (!this.userItem.jobTarget) {
        this.userItem.jobTarget = null;
      }
      if (!this.userItem.experience) {
        this.userItem.experience = null;
      }
      this.detailUserForm = new FormGroup({
        phone : new FormControl('', [Validators.required, Validators.pattern(this.utilsService.phonePattern)]),
        name : new FormControl(this.userItem.name, [Validators.required]),
        detailAddress : new FormControl(this.userItem.detailAddress, [Validators.required]),
        jobTarget : new FormControl(this.userItem.jobTarget),
        experience : new FormControl(this.userItem.experience),
        birthyear : new FormControl( new Date(this.userItem.dateOfBirth), [Validators.required]),
        email : new FormControl(this.userItem.email, [Validators.required,  Validators.pattern(this.utilsService.emailPattern)]),
      });
      console.log(" this.detailUserForm", this.detailUserForm)
      this.checkDisableForm();
      this.getData();
    }
    get phone(): any {
        return this.detailUserForm.get('phone');
    }
    get salary(): any {
        return this.detailUserForm.get('salary');
    }
    get strength(): any {
        return this.detailUserForm.get('strength');
    }
    get name(): any {
        return this.detailUserForm.get('name');
    }
    get job(): any {
        return this.detailUserForm.get('job');
    }
    get des(): any {
        return this.detailUserForm.get('des');
    }
    get email(): any {
        return this.detailUserForm.get('email');
    }
    get address(): any {
        return this.detailUserForm.get('detailAddress');
    }
    get birthyear(): any {
        return this.detailUserForm.get('birthyear');
    }

    checkDisableForm() {
        if (!this.isEditMode) {
            this.detailUserForm.disable();
        } else {
            this.detailUserForm.enable();
        }
    }

    getLocation() {
        // todo google API
        // this.mapService.getPosition().then(pos=>
        //     {
        //       this.center = {lat: pos.lat , lng: pos.lng};
        //     });
        this.retrieveAddress();
    }
    /**
     * build data to the address fields
     * @returns
     */
    retrieveAddress() {
        try {
            let provinceName = this.userItem.province;
            this.selectedProvince = this.utilsService.getAddressByName(this.provinces, provinceName);
            let provinceCode = this.selectedProvince.code;
            this.commonService.getDistrictsByProvince(provinceCode).subscribe(res => {
                if (res && res.data) {
                    let data = res.data;
                    if (data)
                        this.districts = data;
                    let districtName = this.userItem.district;
                    this.selectedDistrict = this.utilsService.getAddressByName(this.districts, districtName);
                    let districtCode = this.selectedDistrict.code;
                    this.commonService.getWardsByDistrict(districtCode).subscribe(res => {
                        if (res && res.data) {
                            let data = res.data;
                            if (data)
                                this.wards = data;
                            let wardName = this.userItem.ward;
                            this.selectedWard = this.utilsService.getAddressByName(this.wards, wardName);
                        }
                    });
                }
            });
        } catch (e) {
            console.log(e);
        }
    }

    checkIsRecruited() {
      let apiUrl = environment.API_URI + ApiNameConstants.BS_JOB_GET_BY_JOBDEFAULT_ID + '?jobDefaultId=' + this.jobDefaultId;
      this.commonService
        .getData(apiUrl, 'checkIsRecruited', '' )
        .subscribe(res => {
            if (res) {
              this.jobId = res;
            }
            this.getCandidateInfo();
          },
          (error) => {
            this.getCandidateInfo();
            console.error('checkIsRecruited API error:', error);
          });
    }

    getCandidateInfo() {
      let apiModels = [
        new ApiModel('get freelancer by id', environment.API_URI + ApiNameConstants.BS_USER_FREELANCER_GET_BY_ID + '?freelancerId=' + this.freelancerId + (this.jobId? '&jobId=' + this.jobId : ''), null, ConstantsApp.GET),
        new ApiModel('get all province', environment.API_URI + ApiNameConstants.BS_GET_ALL_PROVINCES, null, ConstantsApp.GET),
      ];
      this.commonService
        .retrieveData(apiModels)
        .subscribe(res => {
            if (res && res[0] && res[0].data) {
              let data = res[0].data;
              this.userItem = data && data ? data : this.userItem;
              this.userItem.freelancerId = this.freelancerId;
              this.userItem.dateOfBirth = this.userItem.dateOfBirth? this.utilsService.formatDate(new Date(this.userItem.dateOfBirth)) : new Date();
              this.avatarUrl =  `${this.CDN_URI}/${this.userItem.avatar}`;
              console.log('user status: ', this.userItem.status);
            }
            if (res && res[1] && res[1].data) {
              this.provinces = res[1].data;
              if (!this.utilsService.getItem(ConstantsApp.provinces)) {
                this.utilsService.setItem(ConstantsApp.provinces, this.provinces);
              }
            }
            this.buildUserInfoForDisplay();
          },
          (error) => {
            console.error('API error:', error);
          });
    }

    getData() {
      this.checkIsRecruited();
    }

    /**
    * build data to the selection fields
    * @returns
    */
    buildUserInfoForDisplay() {
        this.getLocation();
        this.dob = new FormControl(new Date(this.userItem.dateOfBirth))
        this.selectedGender = this.utilsService.buildSelectedItem(ConstantsApp.gender, this.genders, this.userItem);
        this.selectedUserType = this.utilsService.buildSelectedItem(ConstantsApp.role, this.userTypes, this.userItem);
    }
    onOpenInterviewPopup() {
      this.popupBookingInterview.openPopup(1);
    }
    closeCandidateInfoPopup() {
      this.popupBookingInterview.closePopup();
    }
  /**
   * Check this recruiter have post corresponding with this job, if not, request creat a post
   */
  checkExistedJob() {
    let authenticated = this.authService.authenticated();
    if (!authenticated) {
      this.router.navigate(["/app-login"]);
      return;
    }
      let apiUrl = environment.API_URI + ApiNameConstants.BS_JOB_GET_BY_JOBDEFAULT_ID + '?jobDefaultId=' + this.jobDefaultId;
      this.commonService
        .getData(apiUrl, 'checkExistedJob', '' )
        .subscribe(res => {
            if (res) {
              this.jobId = res;
              this.onRecruit();
            } else {
              this.showPopupInformCreatingPost();
            }
          },
          (error) => {
            console.error('API error:', error);
          });
    }

    onRecruit() {
      let body = {
        freelancerId: this.freelancerId,
        jobId: this.jobId,
        status: ConstantsApp.CHOSEN
      };
      const functionName = 'recruit';
      const messageLog = 'apply this job';
      let apiUrl = environment.API_URI + ApiNameConstants.BS_USER_SCHEDULE_SAVE;
      this.commonService
        .postDatas(body, apiUrl, functionName, messageLog)
        .subscribe(
          (res: any) => {
            if (res && res.status == ConstantsApp.CREATED) {
              this.router.navigate(['/'+ ConstantsApp.LINK_CANDIDATE_MANAGEMENT]);
            }
          },
          (error) => {
            console.error('API error:', error);
          }
        );
    }
    showPopupCreatingPost() {
      this.popupCreatingPost.openPopup();
    }
    showPopupInformCreatingPost() {
      this.popupInformCreatingPost.openPopup();
    }
    onDownloadCv(item: any) {
      if (!this.authenticated) {
        this.router.navigate(["/app-login"]);
        return;
      }
      this.commonService.downloadCv(item);
    }
    
    
}
